import {
    TableHead,
    TableRow,
    TableCell,
    Table,
    TableBody,
    TableContainer,
    TextField,
    Button,
    Select,
    MenuItem,
    Box,
    Typography,
    IconButton,
    Modal,
    InputLabel,
    FormHelperText
} from "@mui/material";
import React, { useState, useEffect } from 'react'
import MessageIcon from "@mui/icons-material/Message";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ImageViewer from "react-simple-image-viewer";
import { get_user_generated_id_for_uploaded_images, offline_add_images } from "../../dal/images";
import { insertQcQueueAtom, insertImagesQueueAtom } from "../../data/qcQueueAtom";
import { useAtom } from "jotai";
import qcChecklistAtom, { deleteQcImageUrlsAtom, fetchQcImageUrlsAtom } from "../../data/qualityControlAtom";

import produce from "immer";
import { useParams } from "react-router-dom";

const QCChecklistTable = ({ qcProgressData, pierTorqueTubeId, type, typeName, trackeRowId, trackerTypeId, blockDetails, imageUrls, visibility, mapType }) => {
    const [status, setStatus] = useState(0);
    const [commentValue, setCommentValue] = useState("")
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);
    const [imageView, setImageView] = useState([]);
    const [openCommentsPhotosModal, setOpenCommentsPhotosModal] = useState(false)
    const [currentRowId, setCurrentRowId] = useState(0)
    const [currentRowData, setCurrentRowData] = useState([])
    const [, insertQcQueue] = useAtom(insertQcQueueAtom)
    const [pierTorqueTubeKey, setPierTorqueTubeKey] = useState("")
    const [qcChecklistData, setQcChecklistData] = useAtom(qcChecklistAtom);
    const [, deleteQcImageUrls] = useAtom(deleteQcImageUrlsAtom)
    const [, fetchQcImageUrls] = useAtom(fetchQcImageUrlsAtom)
    const [, insertImagesQueue] = useAtom(insertImagesQueueAtom)
    const [photoIdsArray, setPhotoIdsArray] = useState([])
    const [imagesUrl, setImagesUrl] = useState({})
    const [openStatusFailedModal, setOpenStatusFailedModal] = useState(false)
    const [showCommentRequired, setShowCommentRequired] = useState(false)
    const [existingStatus, setExistingStatus] = useState(0)

    const params = useParams()

    useEffect(() => {
        getImages()
    }, [visibility])
    const getImages = async () => {
        const blockId = parseInt(params.id)
        try {
            await fetchQcImageUrls({ blockId, visibility })
        } catch (err) {
            console.log(err)
        }
    }
    // Previous Image is showed in Image Viewer
    const onHandleLeftArrow = (index, img) => {
        if (index === 0) {
            setCurrentImage(index + img.length - 1);
        } else {
            setCurrentImage(index - 1);
        }
    };
    // Next Image is showed in Image Viewer
    const onHandleRightArrow = (index, img) => {
        if (index === img.length - 1) {
            setCurrentImage(index - img.length + 1);
        } else {
            setCurrentImage(index + 1);
        }
    };
    useEffect(() => {
        if (parseInt(type) === 0) {
            setPierTorqueTubeKey("pier_id")
        } else {
            setPierTorqueTubeKey("tt_id")
        }
    }, [])


    const openImageViewer = (index, data) => {
        setCurrentRowData([data])
        let imagesList = []
        for (let i of data?.photos) {
            if (qcChecklistData?.qcImageUrls[i]) {
                imagesList.push(qcChecklistData?.qcImageUrls[i])
            }
        }
        setImageView(imagesList);
        setCurrentImage(index);
        setIsViewerOpen(true);
    };
    // Close image viewer
    const closeImageViewer = () => {
        setImageView(0);
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    const handleCancelCommentsModel = async (data) => {
        setStatus(0)
        let update_qc_data = []
        for (let i of qcProgressData?.checkpoints) {
            if (parseInt(i?.checkpoint_id) === parseInt(data?.checkpoint_id)) {
                update_qc_data.push({
                    checkpoint_id: i?.checkpoint_id,
                    acceptance_criteria: i?.acceptance_criteria,
                    status: existingStatus,
                    comments: data?.comments,
                    photos: data?.document,
                    checkpoint_name: i?.checkpoint_name,
                    inspection_type: i?.inspection_type
                })
            } else {
                update_qc_data.push(i)
            }
        }
        setQcChecklistData(
            produce((draft) => {
                draft.qcChecklistProgress["checkpoints"] = update_qc_data
            }))

        try {
            await insertQcQueue({
                checkpoint_id: data?.checkpoint_id,
                checkpoint_name: data?.checkpoint_name,
                acceptance_criteria: data?.acceptance_criteria,
                status: existingStatus,
                comments: data?.comments,
                visibility: visibility,
                photos: data?.document,
                punchlist_status: 0,
                type: type,
                scope: typeName,
                tracker_row_id: trackeRowId,
                tracker_type_id: trackerTypeId,
                row: blockDetails?.row,
                section: blockDetails?.section,
                block_name: blockDetails?.block,
                block_id: blockDetails?.block_id,
                created_at: Date.now() / 1000,
                map_type: mapType,
                qc_type: "qc",
                ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: pierTorqueTubeId } : null)

            })
            setOpenCommentsPhotosModal(false)

        } catch (err) {
            console.log(err)
        }
    }

    // Add Images
    const onImageChange = async (e, data) => {
        const blockId = parseInt(params.id)
        let file = [...e.target.files];
        let photoIds = []
        let existingPhotos = data?.photos
        let imageObj = await get_user_generated_id_for_uploaded_images(file)
        if (existingPhotos !== undefined && existingPhotos.length > 0) {
            for (let i of existingPhotos) {
                imageObj[i] = qcChecklistData?.qcImageUrls[i]
            }
        }
        for (let i in imageObj) {
            photoIds.push(i)
        }
        setPhotoIdsArray(photoIds)
        setImagesUrl(imageObj)
        let update_qc_data = []
        for (let i of qcProgressData?.checkpoints) {
            if (parseInt(i?.checkpoint_id) === parseInt(data?.checkpoint_id)) {
                update_qc_data.push({
                    checkpoint_id: i?.checkpoint_id,
                    acceptance_criteria: i?.acceptance_criteria,
                    status: data?.status,
                    comments: data?.comments,
                    photos: photoIds,
                    checkpoint_name: i?.checkpoint_name,
                    inspection_type: i?.inspection_type
                })
            } else {
                update_qc_data.push(i)
            }
        }
        setQcChecklistData(
            produce((draft) => {
                draft.qcChecklistProgress["checkpoints"] = update_qc_data
            }))
        try {

            await insertImagesQueue({ blockId, visibility, imageObj })
            await getImages()
            await insertQcQueue({
                checkpoint_id: data?.checkpoint_id,
                checkpoint_name: data?.checkpoint_name,
                acceptance_criteria: data?.acceptance_criteria,
                status: data?.status,
                comments: data?.comments,
                visibility: visibility,
                photos: photoIds,
                imageUrls: imageObj,
                punchlist_status: 0,
                type: type,
                scope: typeName,
                tracker_row_id: trackeRowId,
                tracker_type_id: trackerTypeId,
                row: blockDetails?.row,
                section: blockDetails?.section,
                block_name: blockDetails?.block,
                block_id: blockDetails?.block_id,
                created_at: Date.now() / 1000,
                map_type: mapType,
                qc_type: "qc",
                ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: pierTorqueTubeId } : null)

            })

        } catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        if (openCommentsPhotosModal === true) {
            for (let i of qcChecklistData?.qcChecklistProgress?.checkpoints) {
                if (parseInt(i?.checkpoint_id) === parseInt(currentRowData[0]?.checkpoint_id)) {
                    setCurrentRowData([i])
                }
            }

        }
    }, [qcChecklistData?.qcChecklistProgress])

    const handleImageDelete = async (currentImageIndex) => {
        const blockId = parseInt(params.id)
        let uploadId = 0
        let currentPhotosArray = []
        let currentImageUrls = {}
        if (currentRowData[0]?.photos?.length > 0) {
            currentPhotosArray = [...currentRowData[0]?.photos]
            currentPhotosArray = currentPhotosArray.filter((_, index) => {
                uploadId = currentPhotosArray[currentImageIndex]
                return index !== currentImageIndex
            });
        }
        for (let i of currentPhotosArray) {
            currentImageUrls[i] = qcChecklistData?.qcImageUrls[i]
        }
        let update_qc_data = []
        for (let i of qcProgressData?.checkpoints) {
            if (parseInt(i?.checkpoint_id) === parseInt(currentRowData[0]?.checkpoint_id)) {
                update_qc_data.push({
                    checkpoint_id: i?.checkpoint_id,
                    acceptance_criteria: i?.acceptance_criteria,
                    status: currentRowData[0]?.status,
                    comments: currentRowData[0]?.comments,
                    photos: currentPhotosArray,
                    checkpoint_name: i?.checkpoint_name,
                    inspection_type: i?.inspection_type
                })
            } else {
                update_qc_data.push(i)
            }
        }
        setQcChecklistData(
            produce((draft) => {
                draft.qcChecklistProgress["checkpoints"] = update_qc_data
            }))
        try {
            await deleteQcImageUrls({ blockId, visibility, uploadId })
            await getImages()
            await insertQcQueue({
                checkpoint_id: currentRowData[0]?.checkpoint_id,
                acceptance_criteria: currentRowData[0]?.acceptance_criteria,
                checkpoint_name: currentRowData[0]?.checkpoint_name,
                status: currentRowData[0]?.status,
                comments: currentRowData[0]?.comments,
                visibility: visibility,
                photos: currentPhotosArray,
                imageUrls: currentImageUrls,
                punchlist_status: 0,
                type: type,
                scope: typeName,
                tracker_row_id: trackeRowId,
                tracker_type_id: trackerTypeId,
                row: blockDetails?.row,
                section: blockDetails?.section,
                block_name: blockDetails?.block,
                block_id: blockDetails?.block_id,
                created_at: Date.now() / 1000,
                map_type: mapType,
                qc_type: "qc",
                ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: pierTorqueTubeId } : null)
            })
            setIsViewerOpen(false)
        } catch (err) {
            console.log(err)
        }

    }

    const handleCloseCommentsPhotosModal = () => {

        if (currentRowData[0]?.comments === "" || commentValue === "") {
            setShowCommentRequired(true)

        } else {
            setShowCommentRequired(false)
            setOpenCommentsPhotosModal(false)
        }


    }
    const handleOpenCommentsPhotosModal = (data) => {
        setCommentValue(data?.comments)
        setOpenCommentsPhotosModal(true)
        setCurrentRowId(data?.checkpoint_id)
        setCurrentRowData([data])
    };

    const handleCloseStatusFailedModal = () => {
        setOpenStatusFailedModal(false)
    }
    const handleOpenStatusFailedModal = () => {
        setOpenStatusFailedModal(true)
    }

    const handleSubmitCommentsPhotosData = async () => {
        try {
            let update_qc_data = []
            for (let i of qcProgressData?.checkpoints) {
                if (parseInt(i?.checkpoint_id) === parseInt(currentRowData[0]?.checkpoint_id)) {
                    update_qc_data.push({
                        checkpoint_id: i?.checkpoint_id,
                        acceptance_criteria: i?.acceptance_criteria,
                        status: status ? status : i?.status,
                        comments: commentValue ? commentValue : i?.comments,
                        photos: currentRowData[0]?.photos,
                        checkpoint_name: i?.checkpoint_name,
                        inspection_type: i?.inspection_type
                    })
                } else {
                    update_qc_data.push(i)
                }
            }

            setQcChecklistData(
                produce((draft) => {
                    draft.qcChecklistProgress["checkpoints"] = update_qc_data
                }))

            await insertQcQueue({
                checkpoint_id: currentRowData[0]?.checkpoint_id,
                acceptance_criteria: currentRowData[0]?.acceptance_criteria,
                checkpoint_name: currentRowData[0]?.checkpoint_name,
                status: status ? status : currentRowData[0]?.status,
                comments: commentValue ? commentValue : currentRowData?.comments,
                photos: photoIdsArray.length !== 0 ? photoIdsArray : currentRowData[0]?.photos,
                imageUrls: imagesUrl,
                visibility: visibility,
                punchlist_status: 0,
                type: type,
                scope: typeName,
                tracker_row_id: trackeRowId,
                tracker_type_id: trackerTypeId,
                row: blockDetails?.row,
                section: blockDetails?.section,
                block_name: blockDetails?.block,
                block_id: blockDetails?.block_id,
                created_at: Date.now() / 1000,
                map_type: mapType,
                qc_type: "qc",
                ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: pierTorqueTubeId } : null)
            })
            setImagesUrl({})
            setPhotoIdsArray([])
            setCommentValue("")
            setStatus(0)

        } catch (err) {
            console.log(err)
        }
        setOpenCommentsPhotosModal(false)
    }

    const handleStatusChange = async (e, data) => {
        setStatus(e.target.value)
        setExistingStatus(data?.status)
        if (parseInt(data?.status) === 2) {
            handleOpenStatusFailedModal()
            return
        }
        if (parseInt(e.target.value) === 2) {
            setCommentValue(data?.comments)
            setOpenCommentsPhotosModal(true)
            setCurrentRowData([data])
            setCurrentRowId(data?.checkpoint_id)
        }

        try {
            let update_qc_data = []
            for (let i of qcProgressData?.checkpoints) {
                if (parseInt(i?.checkpoint_id) === parseInt(data?.checkpoint_id)) {
                    update_qc_data.push({
                        checkpoint_id: i?.checkpoint_id,
                        acceptance_criteria: i?.acceptance_criteria,
                        status: e.target.value,
                        comments: i?.comments,
                        photos: i?.photos,
                        checkpoint_name: i?.checkpoint_name,
                        inspection_type: i?.inspection_type
                    }
                    )
                } else {
                    update_qc_data.push(i)
                }
            }
            setQcChecklistData(
                produce((draft) => {
                    draft.qcChecklistProgress["checkpoints"] = update_qc_data
                }))
            if (parseInt(e.target.value) !== 2) {
                await insertQcQueue({
                    checkpoint_id: data?.checkpoint_id,
                    acceptance_criteria: data?.acceptance_criteria,
                    checkpoint_name: data?.checkpoint_name,
                    status: e.target.value,
                    comments: data?.comments ? data?.comments : commentValue,
                    photos: data?.photos,
                    imageUrls: {},
                    visibility: visibility,
                    punchlist_status: 0,
                    type: type,
                    scope: typeName,
                    tracker_row_id: trackeRowId,
                    tracker_type_id: trackerTypeId,
                    row: blockDetails?.row,
                    section: blockDetails?.section,
                    block_name: blockDetails?.block,
                    block_id: blockDetails?.block_id,
                    created_at: Date.now() / 1000,
                    map_type: mapType,
                    qc_type: "qc",
                    ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: pierTorqueTubeId } : null)
                })
            
        }

        } catch (err) {
        console.log(err)
    }

}

return (
    <Box sx={{ height: "43vh", overflow: "auto", }}>
        <TableContainer sx={{ overflowX: "initial" }} >
            <Table size="small" stickyHeader >
                <TableHead>
                    <TableRow sx={{ position: "sticky", top: 0, zIndex: "20" }}>
                        <TableCell sx={{ padding: "0 10px", fontSize: "16px" }}>Checkpoint</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Acceptance Criteria</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Inspection Type</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Comments</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Photos</TableCell>
                        <TableCell sx={{ fontSize: "16px" }}>Status</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {qcProgressData?.checkpoints && qcProgressData?.checkpoints.length > 0 ? qcProgressData?.checkpoints?.map((i, index) => {
                        return (
                            <TableRow>
                                <TableCell sx={{ padding: "10px", fontSize: "16px" }}>{i?.checkpoint_name}</TableCell>
                                <TableCell sx={{ fontSize: "16px" }}>{i?.acceptance_criteria}</TableCell>
                                <TableCell sx={{ fontSize: "16px" }}>{i?.inspection_type}</TableCell>
                                <TableCell sx={{ fontSize: "16px" }}>
                                    {i?.comments &&
                                        <p className="nextline" style={{ margin: "5px 0px" }}>{i?.comments}</p>}
                                    <Button
                                        sx={{
                                            display: "flex",
                                            justifyContent: "start",
                                            alignItems: "center",
                                            textTransform: "none",
                                            padding: "0px"
                                        }}
                                        disabled={parseInt(i?.status) !== 2 ? true : false}
                                        onClick={() => handleOpenCommentsPhotosModal(i)}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize:
                                                    "14px",
                                                color:
                                                    "#696F79",
                                            }}
                                        >
                                            {i?.comments ? " Edit Comment" : "Add Comment"}
                                        </Typography>
                                        <MessageIcon
                                            sx={{
                                                paddingLeft: "7px",
                                                fontSize: "16px",
                                                color:

                                                    "#696F79",
                                            }}
                                        />
                                        <Typography
                                            sx={{ fontSize: "24px", color: "red" }}
                                        >
                                            {
                                                i?.status === 2 && (i?.comments === undefined || i?.comments === "")
                                                    ? "*"
                                                    : null}

                                        </Typography>

                                    </Button>
                                </TableCell>
                                <TableCell sx={{ fontSize: "16px" }}>
                                    {i?.photos?.map((imgId) => {
                                        return (
                                            < img
                                                src={qcChecklistData?.qcImageUrls[imgId]}
                                                onClick={() =>
                                                    openImageViewer(
                                                        index,
                                                        i
                                                    )
                                                }
                                                width="60"
                                                key={index}
                                                style={{ margin: "2px" }}
                                                alt=""
                                            />
                                        )
                                    })}
                                    {isViewerOpen && (
                                        <ImageViewer
                                            id={i.id}
                                            src={imageView}
                                            currentIndex={currentImage}
                                            disableScroll={true}
                                            closeOnClickOutside={true}
                                            leftArrowComponent={
                                                <IconButton
                                                    onClick={() => {
                                                        onHandleLeftArrow(
                                                            currentImage,
                                                            imageView
                                                        );
                                                    }}
                                                    sx={{ color: "white" }}
                                                >
                                                    <ArrowBackIosNewIcon
                                                        fontSize="large"
                                                        style={{ color: "white" }}
                                                    />
                                                </IconButton>
                                            }
                                            rightArrowComponent={
                                                <IconButton
                                                    onClick={() => {
                                                        onHandleRightArrow(
                                                            currentImage,
                                                            imageView
                                                        );
                                                    }}
                                                >
                                                    <ArrowForwardIosIcon
                                                        fontSize="large"
                                                        style={{ color: "white" }}
                                                    />
                                                </IconButton>
                                            }
                                            closeComponent={
                                                <Box sx={{ display: "flex" }}>
                                                    <IconButton
                                                        sx={{
                                                            display: "flex",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            fontSize="large"
                                                            onClick={() =>
                                                                handleImageDelete(currentImage)
                                                            }
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontSize: "24px",
                                                                paddingLeft: "7px",
                                                            }}
                                                        >
                                                            {" "}
                                                            Delete
                                                        </Typography>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={closeImageViewer}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="large" />
                                                    </IconButton>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "24px",
                                                            paddingTop: "7px",
                                                        }}
                                                    >
                                                        Close
                                                    </Typography>
                                                </Box>
                                            }
                                        />

                                    )}
                                    <form action="/form/sumbit" method="get">
                                        <label class="label">
                                            <input
                                                type="file"
                                                multiple
                                                required
                                                onChange={(e) => onImageChange(e, i)}
                                                disabled={parseInt(i?.status) !== 2 ? true : false}
                                            />
                                            <span
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        color: "#696F79",
                                                    }}

                                                >
                                                    Add Photo
                                                </Typography>
                                                <CameraAltIcon
                                                    sx={{
                                                        paddingLeft: "5px",
                                                        fontSize: "16px",
                                                        color: "#696F79",
                                                    }}
                                                />
                                            </span>
                                        </label>
                                    </form></TableCell>
                                <TableCell sx={{ fontSize: "16px" }}>
                                    <Select
                                        value={i?.status ? i.status : 0}
                                        sx={{
                                            width: "140px",
                                            height: "45px",
                                            boxShadow: "none",
                                            ".MuiOutlinedInput-notchedOutline": {
                                                border: 0,
                                            },
                                            fontSize: "14px",
                                        }}
                                        onChange={(e) => handleStatusChange(e, i)}

                                    >
                                        <MenuItem value={0}

                                        >Not Started</MenuItem>
                                        <MenuItem value={1}

                                        >Pass</MenuItem>
                                        <MenuItem value={2}>Failed</MenuItem>
                                    </Select>
                                </TableCell>
                            </TableRow>
                        )

                    }) : null}

                </TableBody>
            </Table>
        </TableContainer>
        <Modal
            open={openCommentsPhotosModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ visibility: isViewerOpen ? "hidden" : "visible", }}
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "45%",
                    // height:"30%",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                    borderRadius: "20px",
                    padding: "30px"
                }}
            >
                {/* <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            alignItems: "center",
                            padding: "15px 20px 0px 0px ",
                        }}
                    >
                        <CloseIcon onClick={handleCloseCommentsPhotosModal} />
                    </Box> */}
                <Box>
                    {currentRowData?.map((i, index) => {
                        return (
                            <>
                                <Box sx={{ padding: "10px 15px" }}>
                                    <Box sx={{ display: "flex" }}>
                                        <InputLabel>Enter Comment</InputLabel>
                                        <Typography
                                            sx={{ fontSize: "20px", color: "red", paddingLeft: "2px" }}
                                        >

                                            {
                                                showCommentRequired || commentValue === ""
                                                    ? "*"
                                                    : null}

                                        </Typography>
                                    </Box>

                                    <TextField
                                        key={index}
                                        defaultValue={i?.comments ? i?.comments : commentValue}
                                        multiline
                                        onChange={(e) => {
                                            setCommentValue(e.target.value);
                                        }}
                                        sx={{
                                            width: "350px",
                                            borderRadius: "6px",
                                            padding: "8px 10px 2px 0px",
                                        }}
                                    />
                                    {showCommentRequired ?
                                        <FormHelperText error sx={{ padding: "5px 0px", fontSize: "14px" }}>{commentValue === "" ? "Please enter the comment" : "Please submit the entered comment"}</FormHelperText> : null}

                                </Box>
                                <Box sx={{ padding: "15px 15px 10px 15px" }}>
                                    {i?.photos?.map((imgId) => {
                                        return (
                                            < img
                                                src={qcChecklistData?.qcImageUrls[imgId]}
                                                onClick={() =>
                                                    openImageViewer(
                                                        index,
                                                        i
                                                    )
                                                }
                                                width="60"
                                                key={index}
                                                style={{ margin: "2px" }}
                                                alt=""
                                            />
                                        )
                                    })}
                                    {isViewerOpen && (
                                        <ImageViewer
                                            id={i.id}
                                            src={imageView}
                                            currentIndex={currentImage}
                                            disableScroll={true}
                                            closeOnClickOutside={true}
                                            leftArrowComponent={
                                                <IconButton
                                                    onClick={() => {
                                                        onHandleLeftArrow(
                                                            currentImage,
                                                            imageView
                                                        );
                                                    }}
                                                    sx={{ color: "white" }}
                                                >
                                                    <ArrowBackIosNewIcon
                                                        fontSize="large"
                                                        style={{ color: "white" }}
                                                    />
                                                </IconButton>
                                            }
                                            rightArrowComponent={
                                                <IconButton
                                                    onClick={() => {
                                                        onHandleRightArrow(
                                                            currentImage,
                                                            imageView
                                                        );
                                                    }}
                                                >
                                                    <ArrowForwardIosIcon
                                                        fontSize="large"
                                                        style={{ color: "white" }}
                                                    />
                                                </IconButton>
                                            }
                                            closeComponent={
                                                <Box sx={{ display: "flex" }}>
                                                    <IconButton
                                                        sx={{
                                                            display: "flex",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <DeleteIcon
                                                            fontSize="large"
                                                            onClick={() =>
                                                                handleImageDelete(currentImage)
                                                            }
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontSize: "24px",
                                                                paddingLeft: "7px",
                                                            }}
                                                        >
                                                            {" "}
                                                            Delete
                                                        </Typography>
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={closeImageViewer}
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            color: "white",
                                                        }}
                                                    >
                                                        <CloseIcon fontSize="large" />
                                                    </IconButton>
                                                    <Typography
                                                        sx={{
                                                            fontSize: "24px",
                                                            paddingTop: "7px",
                                                        }}
                                                    >
                                                        Close
                                                    </Typography>
                                                </Box>
                                            }
                                        />

                                    )}
                                    <form action="/form/sumbit" method="get" >
                                        <label class="label">
                                            <input
                                                type="file"
                                                multiple
                                                required
                                                onChange={(e) => onImageChange(e, i)}
                                            />
                                            <span
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: "14px",
                                                        color: "#696F79",
                                                    }}

                                                >
                                                    Add Photo
                                                </Typography>
                                                <CameraAltIcon
                                                    sx={{
                                                        paddingLeft: "5px",
                                                        fontSize: "16px",
                                                        color: "#696F79",
                                                    }}
                                                />
                                            </span>
                                        </label>
                                    </form>

                                </Box>
                                <Box sx={{
                                    padding: "15px",
                                    display: "flex",
                                    justifyContent: "end",
                                    alignItems: "center",
                                }}>
                                    <Button onClick={() => { handleCancelCommentsModel(i) }} variant="contained" sx={{ marginRight: "5px" }}>Cancel</Button>
                                    <Button onClick={() => {
                                        handleSubmitCommentsPhotosData(i)
                                    }}
                                        variant="contained"
                                    >
                                        {i?.comments ? "Update" : "Submit"}
                                    </Button>
                                </Box>
                            </>
                        )
                    })}
                </Box>
            </Box>
        </Modal>


        <Modal
            open={openStatusFailedModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: "40%",
                    backgroundColor: "#FFFFFF",
                    color: "black",
                }}
            >
                <Box
                    sx={{
                        padding: "15px 20px",
                    }}
                >
                    <Typography sx={{ fontSize: "18px" }}>
                        Status of Failed checkpoints can be changed only through QC punchlist page
                    </Typography>
                </Box>

                <Box
                    sx={{
                        padding: "15px",
                        display: "flex",
                        justifyContent: "end",
                        alignItems: "center",
                    }}
                >
                    <Button variant="contained" onClick={handleCloseStatusFailedModal}>
                        Ok
                    </Button>
                </Box>
            </Box>
        </Modal>
    </Box>
)
}

export default QCChecklistTable

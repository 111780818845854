import { atom } from "jotai";
import { get_construction_progress_tracker_rowwise_data } from "../dal/constructionProgress";

const constructionProgressTrackerrowWiseAtom = atom([]);

export const fetchConstructionProgressTrackerrowWiseAtom = atom(
  null,
  async (get, set, args) => {
    let data = await get_construction_progress_tracker_rowwise_data(args)
    set(constructionProgressTrackerrowWiseAtom, data);
  }
);



export default constructionProgressTrackerrowWiseAtom;

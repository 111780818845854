import { Box, Typography } from "@mui/material";
import React from "react";
import { NavLink } from "react-router-dom";
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';

// checks the active link and applies background color
const navstyle = ({ isActive }) => {
  return {
    background: isActive && "#F16253",
    color: isActive ? "white" : "#1B1819",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    textDecoration: "none",
    height: "65px",
    width: "100%",
  };
};

const ProfileSettingsNavBar = () => {
  return (
    <Box sx={{ backgroundColor: "#F9F9FA", height: "80vh", paddingTop: "30px" }}>
      <NavLink to="profiledetails" style={navstyle}>
        < PersonOutlinedIcon
          sx={{ padding: "0 12px 0 20px", textAlign: "left" }}
        />
        <Typography>Profile</Typography>
      </NavLink>
    </Box>
  );
};

export default ProfileSettingsNavBar;

import { useAtom } from 'jotai'
import React, { useEffect } from 'react'
import authAtom from '../../data/authAtom'
import { useNavigate } from 'react-router-dom'

const Root = () => {
    const [user]= useAtom(authAtom)
    const navigate = useNavigate()

    useEffect(()=>{
        if(user.loggedIn){
            navigate("/home/dashboard")
        }
        else{
            navigate("/login")
        }

    },[])
  return 
    <></>
}

export default Root

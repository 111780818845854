import { Box } from '@mui/material'
import React from 'react'
import "./loginStyle.css"

const LoginContainer = () => {
  return (
    <Box>
      <img src="../../Vector.jpg" alt ="top-right" className='vectortopleft-image-style'/>
      <img src="../../VectorRight.jpg" alt ="top-left" className='vectortopright-image-style'/>
      <img src="../../VectorLeftBottom.jpg" alt ="bottom-left" className='vectorbottomleft-image-style'/>
      <img src="../../VectorRightBottom.jpg" alt ="bottom-right" className='vectorbottomright-image-style'/>
    </Box>
  )
}

export default LoginContainer

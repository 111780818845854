import * as d3 from "d3";
import { scaleLinear } from "d3-scale";
import React, { useRef, useState, useEffect } from "react";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import produce from "immer";
import { useAtom } from "jotai";
import { getZimmermanSelectedPiersDataAtom } from "../../data/dashboardAtom";

export default function ZimmerBlock(props) {
    const [selectedPiers, setSelectedPiers] = useState([])
    const containerRef = useRef()
    const selectedPiersRef = useRef([])
    const [, getZimmermanSelectedPiersData] = useAtom(getZimmermanSelectedPiersDataAtom)


    useEffect(() => {
        renderMap()
    }, [props.selectedItem,selectedPiers])
    
    useEffect(() => {
        getZimmermanCompletedPiers()
    }, [selectedPiers])

    useEffect(() => {
        if (props.selectedItem) {
            setSelectedPiers(props.selectedItem)
            selectedPiersRef.current = props.selectedItem
        }
    }, [])

    const getZimmermanCompletedPiers = async () => {
        try {
            await getZimmermanSelectedPiersData(selectedPiers)
        } catch (err) {
            console.log(err)
        }
    }


    const handleClickPier = (data) => {
        if (!selectedPiersRef.current.includes(data?.id)) {
            if (selectedPiers.length > 0) {
                setSelectedPiers(produce((draft) => {
                    draft.push(data?.id)
                }))
                selectedPiersRef.current = ([...selectedPiers, data?.id])
            } else {
                setSelectedPiers([data?.id])
                selectedPiersRef.current = [data?.id]
            }

        } else {
            let filteredData = selectedPiers.filter((id) => id !== data?.id)
            setSelectedPiers(filteredData)
            selectedPiersRef.current = filteredData
        }
    }

    const renderMap = () => {
        let xTableCoordinates = []
        let yTableCoordinates = []
        if (props?.tableCoordinates && props?.tableCoordinates?.table_coordinates.length !== 0) {
            let tableCoordinatesData = props?.tableCoordinates?.table_coordinates
            for (let i of tableCoordinatesData) {
                xTableCoordinates.push(i["x"])
                yTableCoordinates.push(i["y"])

            }
        }


        let xScale = scaleLinear()
            .domain([
                Math.min(...xTableCoordinates), Math.max(...xTableCoordinates)
            ])
            .range([0, 600]);


        let yScale = scaleLinear()
            .domain([
                Math.max(...yTableCoordinates), Math.min(...yTableCoordinates)
            ])
            .range([0, 400]);


        if (props.tableCoordinates && props.tableCoordinates?.table_coordinates && props.tableCoordinates?.table_coordinates.length === 4) {

            let tcData = props.tableCoordinates?.table_coordinates
            let tcColorCode = props.tableCoordinates["color_code"]
            d3.select(containerRef.current)
                .append("path")
                .attr("class", "testing-border cursor-pointer")
                .attr("d", `M${xScale(tcData[0].x)} ${yScale(tcData[0].y)} L${xScale(tcData[1].x)} ${yScale(tcData[1].y)} L${xScale(tcData[2].x)} ${yScale(tcData[2].y)} L${xScale(tcData[3].x)} ${yScale(tcData[3].y)}  Z`)
                .attr("stroke", `rgb(${tcColorCode.split("-")[0]}, ${tcColorCode.split("-")[1]}, ${tcColorCode.split("-")[2]})`)
                .attr("stroke-width", "1px")
                .attr("fill", "white")
        }

        if (props.data.length !== 0) {
            for (let data of props.data) {
                let tableId = parseInt(props.selectedTable[0].split("-")[1])
                if (tableId === data?.tracker_row_id) {
                    if (props?.selectedItem) {
                        d3.select(containerRef.current)
                            .append("circle")
                            .attr("class", "cursor-pointer")
                            .attr("cx", xScale(data?.latitude))
                            .attr("cy", yScale(data?.longitude))
                            .attr("r", 15)
                            .attr("fill", selectedPiers.includes(parseInt(data?.id)) ? "green" : "white")
                            .on("click", () => {
                                handleClickPier(data)
                            })
                    }


                    d3.select(containerRef.current)
                        .append("line")
                        .attr("class", "cursor-pointer")
                        .attr("x1", xScale(data?.latitude) - 5)
                        .attr("y1", yScale(data?.longitude) - 5)
                        .attr("x2", xScale(data?.latitude) + 5)
                        .attr("y2", yScale(data?.longitude) + 5)
                        .attr(
                            "transform", `translate(${xScale(data?.latitude)}, ${yScale(data?.longitude)}) rotate(130) translate(-${xScale(data?.latitude)}, -${yScale(data?.longitude)})`
                        )
                        .attr("stroke", props.pierLegend[data?.part_number]?.border !== undefined ? props.pierLegend[data?.part_number]?.border : "black")
                        .on("click", () => {
                            handleClickPier(data)
                        })
                    d3.select(containerRef.current)
                        .append("line")
                        .attr("class", "cursor-pointer")
                        .attr("x1", xScale(data?.latitude) - 5)
                        .attr("y1", yScale(data?.longitude) - 5)
                        .attr("x2", xScale(data?.latitude) + 5)
                        .attr("y2", yScale(data?.longitude) + 5)
                        .attr(
                            "transform", `translate(${xScale(data?.latitude)}, ${yScale(data?.longitude)}) rotate(220) translate(-${xScale(data?.latitude)}, -${yScale(data?.longitude)})`
                        )
                        .attr("stroke", props.pierLegend[data?.part_number]?.border !== undefined ? props.pierLegend[data?.part_number]?.border : "black")
                        .on("click", () => {
                            handleClickPier(data)
                        })
                }
            }
        }
    }
    return (
        <div id="wrapper" style={{ width: "600px", height: "600px", margin: "auto" }} >
            <svg ref={containerRef} style={{ height: "100%", width: "100%" }} />
        </div>
    )

}

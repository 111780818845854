import React, { useEffect, useState } from 'react'
import { Box, Button, Typography, CircularProgress, InputLabel, TextField } from "@mui/material";
import LoginContainer from '../../components/login/loginContainer';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom';
import { reset_password_details } from '../../dal/login';

const ResetPassword = () => {
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [password, setPassword] = useState()
    const [confirmPassword, setConfirmPassword] = useState()
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword)
    }
    const handleClickSave = async () => {
        try {
            if (password !== confirmPassword) {
                toast.error("Password mismatch")
            }
            setLoading(true)
            let loginDetails = JSON.parse(localStorage.getItem("login_details"))
            let data = {}
            data["email"] = loginDetails.email
            data["old_password"] = loginDetails.password
            data["new_password"] = password
            let resetdata = await reset_password_details(data)
            if (parseInt(resetdata.status) === 200) {
                setLoading(false)
                toast.success("Password Updated Successfully")
                localStorage.removeItem("login_details")
                navigate("/login")
            }else{
                setLoading(false)
                toast.success("Something Went Wrong")
            }

        } catch (err) {
            console.log(err)
        }
    }
    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "cloumn",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
            }}
        >
            <LoginContainer />
            <Box>
                {/* <img src="../../Logo.jpg" alt="logo" /> */}
                <Typography sx={{ fontWeight: "bold", fontSize: "20px", paddingLeft: "10px" }}> Change Password</Typography>
                <Box
                    component="form"
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "30ch" },
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "15px",

                    }}
                >
                    <InputLabel sx={{ paddingLeft: "10px" }}>New Password</InputLabel>
                    <Box sx={{ display: "flex", position: "relative" }}>
                        {showPassword ? <TextField
                            placeholder="New Password"
                            type="text"
                            required
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            name="password"
                        /> : <TextField
                            placeholder="New Password"
                            type="password"
                            required
                            onChange={(e) => {
                                setPassword(e.target.value);
                            }}
                            name="password"
                        />
                        }
                        {
                            showPassword ?
                                <AiOutlineEye fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowPassword} />
                                :
                                <AiOutlineEyeInvisible fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowPassword} />
                        }

                    </Box>
                    <InputLabel sx={{ paddingLeft: "10px" }}>Confirm Password</InputLabel>
                    <Box sx={{ display: "flex", position: "relative" }}>
                        {showConfirmPassword ? <TextField
                            placeholder="Confirm Password"
                            type="text"
                            required
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            name="confirm password"
                        /> : <TextField
                            placeholder="Confirm Password"
                            type="password"
                            required
                            onChange={(e) => {
                                setConfirmPassword(e.target.value);
                            }}
                            name="confirm password"
                        />
                        }
                        {
                            showConfirmPassword ?
                                <AiOutlineEye fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowConfirmPassword} />
                                :
                                <AiOutlineEyeInvisible fontSize={20} style={{ position: "absolute", top: 25, left: 270 }} onClick={handleShowConfirmPassword} />
                        }

                    </Box>
                    <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        sx={{ margin: "15px 10px 10px 10px" }}
                        onClick={handleClickSave}
                    >
                        {loading ? (
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "space-between",
                                }}
                            >
                                <CircularProgress size={20} style={{ color: "white" }} />
                                <Typography sx={{ marginX: "10px" }}>Saving</Typography>
                            </Box>
                        ) : (
                            "Save"
                        )}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}

export default ResetPassword

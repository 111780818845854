import { atom } from "jotai";
import produce from "immer";

const appBarAtom = atom({
  latestSyncMessage: false,
  blockUpdatedValue: false,
  syncImages: true,

})


export const updateLatestSyncMessage = atom(
  null,
  async (get, set, args) => {
    set(appBarAtom, produce((draft) => {
      draft.latestSyncMessage = args.value
    }));
  }
);
export const updateBlockUpdateValue = atom(
  null,
  async (get, set, args) => {
    set(appBarAtom, produce((draft) => {
      draft.blockUpdatedValue = args.value
    }));
  }
);

export const updateSyncImages = atom(
  null,
  async (get, set, args) => {
    set(appBarAtom, produce((draft) => {
      draft.syncImages = args.value
    }));
  }
);
export default appBarAtom

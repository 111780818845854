import React, { useState, useEffect, useRef } from "react";
import { Box, Grid, Button, Select, MenuItem, InputLabel, FormControl, ListItemText, Typography, Modal, Popover, TableContainer, Table, TableHead, TableCell, TableRow, TableBody, CircularProgress } from "@mui/material";
import Header from "../../components/appBar";
import BlockConstructionProgressHeader from "./pierListHeader";
import { useLocation, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import CloseIcon from "@mui/icons-material/Close";
import OutlinedInput from '@mui/material/OutlinedInput';
import { GrCircleInformation } from "react-icons/gr";
import Checkbox from '@mui/material/Checkbox';
import dashboardAtom, {
  fetchTrackerTypeNamesAtom,
  fetchIsAllTrackersSelectedAtom,
  fetchD3XscaleYscaleSizeAtom,
  fetchSelectedTrackerTypeIdsAtom,
  fetchPreviousSelectedTrackerTypeIdAtom,
  fetchHighLightSelectedTrackerTypeIdsAtom,
  fetchBulkPiersTorqueTubesBlockDataAtom,
  fetchPierLegendAtom,
  fetchZimmermanTableCoordinatesAtom,
  fetchBlockAtom,
  fetchZimmermanBulkPiersAtom,
} from "../../data/dashboardAtom";
import constructionProgressTrackerrowWiseAtom, {
  fetchConstructionProgressTrackerrowWiseAtom,
} from "../../data/constructionProgressAtom";
import { updateSelectedRowsAtom } from "../../data/selectedRowsAtom";
import syncStatusAtom from "../../data/syncStatusAtom";
import produce from "immer";
import projectAtom, { fetchProjectDetailsAtom } from "../../data/projectAtom";
import { BlockMap } from '@product-fusion/surya-fw';
import ScrollableTabsButtonForce from "../home/tabs";
import qcChecklistAtom, { fetchQCD3ColorAtom } from "../../data/qualityControlAtom";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../featureFlags/featureFlags";

const GpsLocation = () => {
  const [value, setValue] = useState(false);
  const [dashboard] = useAtom(dashboardAtom);
  const [constructionProgressTrackerrowWise] = useAtom(constructionProgressTrackerrowWiseAtom);
  const [, fetchConstructionProgressTrackerrowWise] = useAtom(fetchConstructionProgressTrackerrowWiseAtom);
  const [, fetchD3XscaleYscaleSize] = useAtom(fetchD3XscaleYscaleSizeAtom)
  const [, fetchTrackerTypeName] = useAtom(fetchTrackerTypeNamesAtom);
  const [selectAllTrackers, setSelectAllTrackers] = useState(false)
  const location = useLocation();
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, isAllTrackersSelected] = useAtom(fetchIsAllTrackersSelectedAtom)
  const [showSectionModal, setShowSectionModal] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null);
  const [clearSelection, setclearSelection] = useState(false)
  const [sectionSelect, setSectionSelect] = useState([])
  const [selectedD3View, setSelectedD3View] = useState("pier")
  const [previousSelectedSection, setPreviousSelectedSection] = useState(null)
  const [projects] = useAtom(projectAtom)
  const [, fetchProjects] = useAtom(fetchProjectDetailsAtom)
  const [sectionList, setSectionList] = useState([])
  const [selectedRow, setSelectedRow] = useState([])
  const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom);
  const [loading, setLoading] = useState(false)
  const [selectedTrackerNames, setSelectedTrackerNames] = useState({})
  const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
  const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
  const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [selectedTrckerTypeIds, setSelectedTrackerTypeIds] = useState([])
  const [prevoiusSelectedTrckerTypeId, setPrevoiusSelectedTrckerTypeId] = useState(null)
  const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
  const [, fetchBlocks] = useAtom(fetchBlockAtom)
  const [, fetchQCD3Color] = useAtom(fetchQCD3ColorAtom)
  const [, fetchZimmermanTableCoordinates] = useAtom(fetchZimmermanTableCoordinatesAtom)
  const [, fetchZimmermanBulkPiers] = useAtom(fetchZimmermanBulkPiersAtom)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [previousSelectedRow, setPreviousSelectedRow] = useState(null)
  const [d3xScaleyScale, setD3xScaleyScale] = useState({})

  const params = useParams()

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  useEffect(() => {
    init()
  }, [syncStatusData.syncing]);

  useEffect(() => {
    init()
  }, []);

  useEffect(() => {
    setSelectedTrackerTypeIds(dashboard?.selectedTrackerTypeIds)
    setPrevoiusSelectedTrckerTypeId(dashboard?.previousSelectedTrackerTypeId)
  }, [dashboard?.selectedTrackerTypeIds, dashboard?.previousSelectedTrackerTypeId])

  const init = async () => {
    localStorage.setItem("punchlist-visibility-index", 0)
    // localStorage.setItem("tab-index", 0)
    setLoading(true)
    try {
      await fetchTrackerTypeName()
      await fetchProjects()
      await fetchD3XscaleYscaleSize(parseInt(params?.id))
      await fetchBulkPiersTorqueTubesBlockData(parseInt(params.id))
      await fetchConstructionProgressTrackerrowWise(parseInt(params.id))
      await fetchBlocks()
      await fetchPierLegend()
      await fetchZimmermanTableCoordinates(parseInt(params.id))
      await fetchBlocks()
      await fetchZimmermanBulkPiers(parseInt(params.id))
      setLoading(false)
    } catch (err) {
      console.log(err);
    }

  };

  let projectId = JSON.parse(localStorage.getItem("project_id"))
  let projectDetails = {}
  for (let i of projects.projectDetailsDb) {
    if (i?.id === projectId) {
      projectDetails = i?.data
    }
  }

  function findMinMax(nestedData) {
    const allCoords = [];
    if (nestedData) {
      // Flattening the nested data
      Object.values(nestedData).forEach(coordsArray => {
        coordsArray.forEach(coord => {
          allCoords.push(coord);
        });
      });

      const latitudes = allCoords.map(coord => coord.latitude);
      const longitudes = allCoords.map(coord => coord.longitude);

      return {
        minimum_latitude: Math.min(...latitudes),
        maximum_latitude: Math.max(...latitudes),
        minimum_longitude: Math.min(...longitudes),
        maximum_longitude: Math.max(...longitudes)
      };
    }
  }
  function hasNullValues(data) {
    if (data) {
      const values = [
        data?.piers?.minimum_latitude,
        data?.piers?.maximum_latitude,
        data?.piers?.minimum_longitude,
        data?.piers?.maximum_longitude,
        data?.tt?.minimum_latitude,
        data?.tt?.maximum_latitude,
        data?.tt?.minimum_longitude,
        data?.tt?.maximum_longitude
      ];

      return values.some(value => value === null);
    }
  }

  useEffect(() => {
    if (dashboard?.d3XscaleYscaleSize) {
      const d3xScaleyScaleHasNullValue = hasNullValues(dashboard?.d3XscaleYscaleSize)
      if (d3xScaleyScaleHasNullValue === true) {
        if (dashboard?.bulkPiersTorqueTubesBlockData) {
          const piersMinMax = findMinMax(dashboard?.bulkPiersTorqueTubesBlockData?.piers);
          const ttMinMax = findMinMax(dashboard?.bulkPiersTorqueTubesBlockData?.tt);
          setD3xScaleyScale({ piers: piersMinMax, tt: ttMinMax })
        }
      }
      else {
        setD3xScaleyScale({ piers: dashboard?.d3XscaleYscaleSize?.piers, tt: dashboard?.d3XscaleYscaleSize?.tt })
      }
    }

  }, [dashboard?.d3XscaleYscaleSize, dashboard?.bulkPiersTorqueTubesBlockData])


  const handleShowLegendPopover = (event) => {
    setAnchorEl(event.currentTarget);
  }
  const handleCloseLegendPopover = () => {
    setAnchorEl(null);
  }
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  // section modal open
  const handleOpenSectionModal = () => {
    getTrackerWiseDatas()
    setShowSectionModal(true)
  }
  // section modal close
  const handleCloseSectionModal = () => {
    setShowSectionModal(false)
  }

  function getTrackerWiseDatas() {
    let temp = {}
    // let typeId = 0
    selectedRow.map((i) => {
      // if (isZimmerman === true) {
      //   typeId = parseInt(i.split("-")[1])
      // } else {
      //   typeId = parseInt(i.split("-")[2])
      // }
      let typeId = parseInt(i.split("-")[2])

      let obj = temp[findTrackerNames(typeId)]
      if (!obj) {
        temp[findTrackerNames(typeId)] = 1
      }
      if (obj) {
        temp[findTrackerNames(typeId)] = temp[findTrackerNames(typeId)] + 1
      }
    })
    setSelectedTrackerNames(temp)

  }

  const findTrackerNames = (data) => {
    let name = dashboard?.trackerTypeNames?.find((k) => k?.id === parseInt(data));
    return name?.tracker_type;
  };
  // clears the selected trackers
  const handleClearSelection = async () => {
    setclearSelection(!clearSelection)
    setSelectAllTrackers(false)
    setSelectedRow([]);
    setValue(!value);
    setSectionSelect([])
    setPreviousSelectedSection(null)
    await updateSelectedRows([])
    await isAllTrackersSelected(false)
  };


  useEffect(() => {
    handleClearSelection()
  }, [dashboard?.clearSelectedTrackerRows])
  
  useEffect(() => {
    if (clearSelection === true) {
      clearSelectedData()
    }
  }, [clearSelection])

  const clearSelectedData = async () => {
    try {
      await fetchSelectedTrackerTypeIds([])
      await fetchPreviousSelectedTrackerTypeId(null)
      await fetchHighLightSelectedTrackerTypeIds([])
    } catch (err) {
      console.log(err)
    }
  }
  // select all trackers
  const handleSelectAllTrackers = async () => {
    setSelectAllTrackers(!selectAllTrackers)
    if (sectionList) {
      for (let i of sectionList) {
        setSectionSelect(produce((draft) => { draft.push(i) }))
        setPreviousSelectedSection(null)
      }
    }
    await isAllTrackersSelected(true)
  }
  const handleSectionSelect = async (data) => {
    if (sectionSelect.includes(data)) {
      setSectionSelect(produce((draft) => { draft.splice(draft.findIndex(r => r === data), 1) }))
      setPreviousSelectedSection(data)
    }
    else {
      setSectionSelect(produce((draft) => { draft.push(data) }))
      setPreviousSelectedSection(null)
    }
  }
  const handleSelectedMap = (e) => {
    setSelectedD3View(e.target.value)
  }

  const updateSelectedRow = async (e) => {
    try {
      if (e.length !== 0) {
        await updateSelectedRows(e);
        setSelectedRow(e)
      }
      if (sectionSelect.length === 0 && previousSelectedSection !== null) {
        await updateSelectedRows([]);
        setSelectedRow([])
        setPreviousSelectedSection(null)
      }
      if (dashboard?.selectedTrackerTypeIds.length === 0 && dashboard?.previousSelectedTrackerTypeId !== null && dashboard?.isAllTrackersSelected === false) {
        await updateSelectedRows([]);
        setSelectedRow([])
      }
      if (sectionSelect.length === 0 && previousSelectedRow !== null) {
        await updateSelectedRows([]);
        setSelectedRow([])
        setPreviousSelectedRow(null)
      }
      if (dashboard?.selectedTrackerTypeIds.length === 0 && dashboard?.previousSelectedTrackerTypeId !== null && dashboard?.isAllTrackersSelected === false) {
        await updateSelectedRows([]);
        setSelectedRow([])
      }
    } catch (err) {
      console.log(err)
    }
  }
  // const handleChange = (event) => {
  //   const value = event.target.value;
  //   setSectionSelect(prevSelectedValues => {
  //     if (prevSelectedValues.includes(value)) {
  //       return prevSelectedValues.filter(item => item !== value);
  //     } else {
  //       return [...prevSelectedValues, value];
  //     }
  //   });
  // };

  const handleChangeSection = (event) => {
    const value = event.target.value;
    const unselectedItems = sectionSelect.filter(item => !value.includes(item));
    if (unselectedItems.length > 0) {
      setSectionSelect(value)
      setPreviousSelectedRow(unselectedItems[0]);
    }
    else {
      setSectionSelect(value)
      setPreviousSelectedRow(null)
    }
  };


  useEffect(() => {
    let temp = new Set()
    if (isZimmerman === true) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        for (let i of blockPierData) {
          temp.add(i?.section)
        }
      }
    } else {
      if (dashboard?.bulkPiersTorqueTubesBlockData && dashboard?.bulkPiersTorqueTubesBlockData?.piers) {
        let blockPierData = dashboard?.bulkPiersTorqueTubesBlockData?.piers
        for (let i in blockPierData) {
          for (let j of blockPierData[i]) {
            temp.add(j?.section)
          }

        }
      }
    }
    let sectionArray = Array.from(temp);
    sectionArray.sort((a, b) => a - b);
    setSectionList(sectionArray)
  }, [dashboard?.bulkPiersTorqueTubesBlockData, dashboard?.zimmermanBulkPiers])

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Header />
      <Grid container>
        <Grid item md={8.2}>
          <Box>
            <BlockConstructionProgressHeader blockid={location?.state?.id} />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              fontSize: "10px",
              marginX: "5px"
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start" }}>
              <Box sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "15px 10px 0px",
              }}
                aria-describedby={id}
                onClick={handleShowLegendPopover}
              >
                <GrCircleInformation fontSize={20} />
                <Typography sx={{ paddingX: "5px" }}>Legend</Typography>
              </Box>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleCloseLegendPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                sx={{ padding: "10px" }}
              >
                <Box sx={{ display: "flex", padding: "10px 5px 10px 10px " }}>
                  <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "20px",
                      backgroundColor: "#66f1ad"
                    }}
                  ></Box>
                  <Typography sx={{ padding: "0px 10px 0px 10px", fontSize: "13px" }} >
                    Completed
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", padding: "10px 5px 10px 10px" }}>
                  <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "20px",
                      backgroundColor: "#FC8EAC",
                    }}
                  ></Box>
                  <Typography sx={{ padding: "0px 10px 0px 10px", fontSize: "13px" }}>
                    In Progress
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", padding: "10px 5px 10px 10px" }}>
                  <Box
                    sx={{
                      height: "20px",
                      width: "20px",
                      borderRadius: "20px",
                      backgroundColor: "#F6BE00",

                    }}
                  ></Box>
                  <Typography sx={{ padding: "0px 10px 0px 10px", fontSize: "13px" }}>
                    Selected Trackers
                  </Typography>
                </Box>
              </Popover>
              <Box sx={{ padding: "10px 12px 0px", }}>
                {isZimmerman === true ?
                  <Box >

                    <FormControl sx={{ m: 1, width: 150 }}>
                      <InputLabel id="demo-multiple-checkbox-label">Select Row</InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={sectionSelect}
                        onChange={handleChangeSection}
                        input={<OutlinedInput label="Select Row" />}
                        renderValue={(selected) => selected.length === 0 ? "Select Rows" : selected.join(', ')}
                        // sx ={{height:50}}
                        MenuProps={{
                          PaperProps: {
                            sx: {
                              maxHeight: 250,
                              width: 150 // Adjust the height as needed
                            },
                          },
                        }}

                      >

                        {sectionList.map((id) => (
                          <MenuItem key={id} value={id}>
                            <Checkbox checked={sectionSelect.indexOf(id) > -1} />
                            <ListItemText primary={id} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                  </Box> :
                  <Box sx={{ width: "100px", textAlign: "center" }}>
                    <Typography >Section</Typography>
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                      {sectionList?.map((i) => {
                        return (
                          <Button sx={{
                            height: "25px",
                            minWidth: "25px",
                            border: sectionSelect.includes(i) ? "none" : "1px solid black",
                            margin: "2px",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            borderRadius: "0px",
                            padding: "2px",
                          }}
                            variant={sectionSelect.includes(i) ? "contained" : "outlined"}
                            onClick={() => handleSectionSelect(i)}
                          >{i}</Button>
                        )
                      })}
                    </Box>

                  </Box>
                }
              </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", padding: "15px 8px 0px", }}>
              <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", }}>
                <Box sx={{ width: "120px", marginX: "5px" }}>
                  <Button variant="contained"
                    sx={{ fontSize: "12px", padding: "5px", textTransform: "none" }}
                    disabled={selectedRow.length === 0 ? true : false}
                    onClick={handleOpenSectionModal}>
                    {isZimmerman === true ? "View Selected Tables" : "View Selected Trackers"}
                  </Button>
                </Box>
                <Box sx={{ marginX: "5px" }}>
                  <Button
                    variant="contained"
                    sx={{ textTransform: "none", fontSize: "12px" }}
                    onClick={handleSelectAllTrackers}
                  >
                    Select All
                  </Button>
                </Box>
                <Box sx={{ marginX: "5px" }}>
                  <Button
                    disabled={selectedRow.length > 0 ? false : true}
                    variant="contained"
                    sx={{ textTransform: "none", fontSize: "12px" }}
                    onClick={handleClearSelection}
                  >
                    Clear Selection
                  </Button>
                </Box>
                {isZimmerman === false ?
                  <Box>
                    <Select
                      value={selectedD3View}
                      onChange={(e) => handleSelectedMap(e)}
                      sx={{
                        fontSize: "14px",
                        fontWeight: 500,
                        paddingRight: "0px",
                        color: "#696F79",
                        width: "120px"
                      }}
                    >
                      <MenuItem value="pier">Layout</MenuItem>
                      <MenuItem value="part number">Pier Map</MenuItem>
                      <MenuItem value="torque tube">TT Map</MenuItem>
                      <MenuItem value="bha" >BHA & SG Map</MenuItem>
                    </Select>
                  </Box> : null}
              </Box>

            </Box>
          </Box>
          <Box sx={{ height: "70vh", width: "68vw" }}>
            {loading ?
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "70vh" }}>
                <CircularProgress size={28} />
                <Typography sx={{ paddingLeft: "5px", fontSize: "16px" }}> Fetching</Typography>
              </Box> :
              isZimmerman === true ?
                dashboard?.zimmermanTableCoordinates && dashboard?.zimmermanBulkPiers?.length !== 0 ?
                  <BlockMap
                    zimmerMan={true}
                    bulkPierData={dashboard?.zimmermanBulkPiers}
                    pierLegends={dashboard?.pierLegends}
                    bulkAllTTData={dashboard?.zimmermanTableCoordinates}
                    type="CP"
                    deviceType="tablet"
                    selectAll={selectAllTrackers}
                    selectedRow={selectedRow?.length !== 0 ? selectedRow : []}
                    setSelectedRow={e => updateSelectedRow(e)}
                    typeData={constructionProgressTrackerrowWise}
                    userSelection={{ showType: "pier", selection: localStorage.getItem("construction-act-id") === "All" ? localStorage.getItem("construction-act-id") : JSON.parse(localStorage.getItem("construction-act-id")) }}
                    prevRow={previousSelectedRow}
                    clearSelection={clearSelection}
                    section={sectionSelect}
                    mapSize={dashboard?.d3XscaleYscaleSize}
                    // currentBlock={dashboard?.block?.find((i) => i?.id === parseInt(params.id))}
                    currentProjectData={projectDetails}
                    enableGPSLocation={dashboard?.isGPSLocationEnabled}
                    trackerSelect={selectedTrckerTypeIds}
                    prevTracker={prevoiusSelectedTrckerTypeId}
                    controls={true}
                  />
                  : null
                :
                <BlockMap
                  bulkAllData={dashboard?.bulkPiersTorqueTubesBlockData}
                  pierLegends={dashboard?.pierLegends}
                  type="CP"
                  selectAll={selectAllTrackers}
                  selectedRow={selectedRow?.length !== 0 ? selectedRow : []}
                  setSelectedRow={e => updateSelectedRow(e)}
                  typeData={constructionProgressTrackerrowWise}
                  userSelection={{ showType: selectedD3View, selection: localStorage.getItem("construction-act-id") === "All" ? localStorage.getItem("construction-act-id") : JSON.parse(localStorage.getItem("construction-act-id")) }}
                  prevSection={previousSelectedSection}
                  clearSelection={clearSelection}
                  section={sectionSelect}
                  mapSize={d3xScaleyScale}
                  deviceType="tablet"
                  currentProjectData={projectDetails}
                  enableGPSLocation={dashboard?.isGPSLocationEnabled}
                  trackerSelect={selectedTrckerTypeIds}
                  prevTracker={prevoiusSelectedTrckerTypeId}
                  controls={true}
                />


            }
          </Box>
        </Grid>
        <Grid item md={3.8}>
          <Box sx={{ borderLeft: "1px solid rgba(105, 111, 121, 0.5)" }}>
            <ScrollableTabsButtonForce />
          </Box>
        </Grid>
      </Grid>
      <Modal
        open={showSectionModal}
        onClose={handleCloseSectionModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "70vh",
            width: "75vw",
            overflowY: "auto",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <TableContainer sx={{ overflowX: "initial" }}>
            <Table sx={{ padding: "10px" }} size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{ position: "sticky", top: 0, zIndex: "0", }}>
                  <TableCell colspan="10" style={{ borderBottom: "none" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{ fontWeight: "bold", textAlign: "center" }}
                      >
                        {isZimmerman ? "Selected Table(s)" : "Selected Tracker(s)"}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 5px",
                        }}
                      >
                        <CloseIcon
                          onClick={handleCloseSectionModal}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                {selectedTrackerNames && Object.keys(selectedTrackerNames).map((i) => {
                  return (
                    <TableRow sx={{ position: "sticky", top: 40, zIndex: "10", }}>
                      <TableCell sx={{ borderBottom: "none", fontWeight: 400 }}>{i}</TableCell>
                      <TableCell sx={{ borderBottom: "none", fontWeight: 400 }}> - {selectedTrackerNames[i]}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow sx={{ position: "sticky", top: 50, zIndex: "15" }}>
                  <TableCell sx={{ borderBottom: "none", fontWeight: "bold" }}>Total </TableCell>
                  <TableCell sx={{ borderBottom: "none", fontWeight: 400 }}> - {selectedRow.length} {isZimmerman === true ? "table(s)" : "tracker(s)"}</TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 55, zIndex: "20" }}>
                  <TableCell style={{ textAlign: "center", backgroundColor: "#F16253", color: "white", }}>{isZimmerman === true ? "Row" : "Section"}</TableCell>
                  <TableCell style={{ textAlign: "center", backgroundColor: "#F16253", color: "white", }}>{isZimmerman === true ? "Table" : "Tracker Row"}</TableCell>
                  <TableCell style={{ textAlign: "center", backgroundColor: "#F16253", color: "white" }}>{isZimmerman === true ? "Table Type" : "Tracker Type"}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedRow.map((i) => {
                  return (
                    <>
                      <TableRow>
                        <TableCell style={{ textAlign: "center" }}>
                          {/* {isZimmerman === true ? i?.split("-")[2] : i?.split("-")[4]} */}
                          {i?.split("-")[4]}
                        </TableCell>
                        <TableCell style={{ textAlign: "center" }}>{i?.split("-")[3]}</TableCell>
                        <TableCell style={{ textAlign: "center", width: "320px" }}>
                          {/* {isZimmerman === true ? findTrackerNames(i?.split("-")[1]) : findTrackerNames(i?.split("-")[2])} */}
                          {findTrackerNames(i?.split("-")[2])}
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Modal>
    </Box>
  );
};

export default GpsLocation;




import {
  Box,
  Grid,
  Typography,
  InputLabel,
} from "@mui/material";
import React, { useEffect } from "react";
import { HiBuildingOffice } from "react-icons/hi2";
import { FaUserTie } from "react-icons/fa";
import dashboardAtom, { fetchProfileDetailsAtom } from "../../data/dashboardAtom";
import { useAtom } from "jotai";

const ProfileDetails = () => {
  const [listDetails] = useAtom(dashboardAtom)
  const [, fetchProfileData] = useAtom(fetchProfileDetailsAtom)

  
  
  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    await fetchProfileData()
  }

  // checks the category type
  const handleCheckCategory = (data) => {
    let temp
    if (data === 0) {
      temp = "EPC"
    }
    else if (data === 1) {
      temp = "Contractor"
    }
    else if (temp === 2) {
      temp = "Project Developer"
    }
    else {
      temp = "Supplier"
    }
    return temp
  }
  return (
    <Box>
      <Grid container sx={{ paddingBottom: "6px" }}>
        <Grid item sm={6}>
          <Box sx={{ margin: "30px 30px 0 30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <HiBuildingOffice size="26" />
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  paddingLeft: "14px",
                }}
              >
                Company Details
              </Typography>
            </Box>
            <Box sx={{ paddingTop: "20px" }}>
              {listDetails?.profileDetails.user?.organizations.map((i) => {
                return (
                  <Box>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Company Name
                    </InputLabel>
                    <Typography sx={{ padding: "10px 7px" }}>{i?.name}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Website Link
                    </InputLabel>
                    <Typography sx={{ padding: "10px 7px" }}>{i?.website === null ? "-" : i?.website}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Address
                    </InputLabel>
                    <Typography sx={{ padding: "10px 7px" }}>{i?.address === null ? "-" : i?.address}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Category
                    </InputLabel>
                    <Typography sx={{ padding: "10px 7px" }}>{handleCheckCategory(i?.type)}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      About
                    </InputLabel>
                    <Typography sx={{ padding: "10px 7px" }}>{i?.about === null ? "-" : i?.about}</Typography>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Grid>
        <Grid item sm={6}>
          <Box sx={{ margin: "30px 30px 0 30px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <FaUserTie size="24" />
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  paddingLeft: "14px",
                 
                }}
              >
                Key Account Manager
              </Typography>
            </Box>
            <Box sx={{ paddingTop: "20px" }}>
              {listDetails?.profileDetails.user?.organizations.map((i) => {
                return (
                  <Box>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Name
                    </InputLabel>
                    <Typography sx ={{padding: "10px 7px"}}>{i?.kam.name === null?"-":i?.kam.name}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Email Id
                    </InputLabel>
                    <Typography sx ={{padding: "10px 7px"}}>{i?.kam?.email === null?"-":i?.kam?.email}</Typography>
                    <InputLabel sx={{ color: "#696F79", padding: "5px 7px" }}>
                      Phone
                    </InputLabel>
                   <Typography sx ={{padding: "10px 7px"}}>{i?.kam?.profile_data?.number === null||i?.kam?.profile_data?.number === undefined?"-":i?.kam?.profile_data?.number}</Typography>

                    
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Grid>
      </Grid>
      
    </Box>
  );
};

export default ProfileDetails;

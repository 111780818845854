import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  LinearProgress,
  Modal,
  Divider,
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useAtom } from "jotai";
import dashboardAtom, {
  fetchTrackerTypesForProjectAtom,
  fetchTrackerTypesForBlockAtom,
  fetchActivityMilestoneForBlockAtom,
  fetchActivityMilestoneForProjectAtom,
  fetchPierLegendAtom,
  fetchBlockAtom,
  fetchTrackerTypeNamesAtom,
  fetchSelectedTrackerBomAtom,
  fetchConstructionProgressForProjectAtom,
  fetchConstructionProgressForBlockAtom,
  fetchSelectedTrackerTypeIdsAtom,
  fetchPreviousSelectedTrackerTypeIdAtom,
  fetchHighLightSelectedTrackerTypeIdsAtom,
  fetchBomForSelectedBlocksAtom
} from "../../data/dashboardAtom";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import activitiesAtom, { fetchActivitiesAtom } from "../../data/activitiesAtom";
import selectedRowsAtom from "../../data/selectedRowsAtom";
import syncStatusAtom from "../../data/syncStatusAtom";
import lodash from "lodash";
import { constructionActivityProgressForBlock, constructionOverallProgressForBlock, constructionOverallProgressForProject, get_construction_progress_for_parent_sub_activity } from "../../components/functions/constructionProgress";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../featureFlags/featureFlags";

const Dashboard = () => {
  const params = useParams();
  const [activities] = useAtom(activitiesAtom);
  const [dashboard] = useAtom(dashboardAtom);
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [, fetchBlockData] = useAtom(fetchBlockAtom);
  const [, fetchTrackerTypesForProject] = useAtom(fetchTrackerTypesForProjectAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, fetchTrackerTypesForBlock] = useAtom(fetchTrackerTypesForBlockAtom);
  const [, fetchActivityMilestoneForBlock] = useAtom(fetchActivityMilestoneForBlockAtom);
  const [, fetchActivityMilestoneForProject] = useAtom(fetchActivityMilestoneForProjectAtom);
  const [, fetchPierLegendData] = useAtom(fetchPierLegendAtom);
  const [, fetchTrackerTypeName] = useAtom(fetchTrackerTypeNamesAtom);
  const [, fetchSelectedTrackersBOM] = useAtom(fetchSelectedTrackerBomAtom);
  const [, fetchConstructionProgressForProject] = useAtom(fetchConstructionProgressForProjectAtom)
  const [, fetchConstructionProgressForBlock] = useAtom(fetchConstructionProgressForBlockAtom)
  const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
  const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
  const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)

  const [openModal, setOpenModal] = useState(false);
  const [openTrackerModal, setOpenTrackerModal] = useState(false);
  const [openOverallBomModal, setOpenOverallBomModal] = useState(false);
  const [openBomforSelectedBlocksModal, setOpenBomforSelectedBlocksModal] = useState(false);
  const [online, setOnline] = useState(false);
  const [showText, setShowText] = useState(false)
  const [partDescriptionData, setPartDescriptionData] = useState("")
  const [bomId, setBomId] = useState(0)
  const [selectedRowBomId, setSelectedRowBomID] = useState(false)
  const [sortedBillOfMaterialsForProject, setSortedBillOfMaterialsForProject] = useState([])
  const [sortedBillOfMaterialsForBlock, setSortedBillOfMaterialsForBlock] = useState([])
  const [sortedTrackerTypeNamesForProject, setSortedTrackerTypeNamesForProject] = useState([])
  const [sortedTrackerTypeNamesForBlock, setSortedTrackerTypeNamesForBlock] = useState([])
  const [loading, setLoading] = useState(false)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [isBomforProjectEnabled, setIsBomforProjectEnabled] = useState(true)
  const [, fetchBomForSelectedBlocks] = useAtom(fetchBomForSelectedBlocksAtom)
  const [constructionProgressActivityWiseForBlock, setConstructionProgressActivityWiseForBlock] = useState({})

  const handleOpen = () => {
    setOpenModal(true);
    setLoading(true)
    billOfMaterialsForBlock()
    setLoading(false)
  }

  const handleClose = () => {
    setOpenModal(false)
    setSelectedRowBomID(0)
  };
  useEffect(() => {
    let cpActivitywiseProgressBlock = {}
    let constructionProgressForBlock = dashboard?.constructionProgressForBlock[params.id]
    if (constructionProgressForBlock) {
      constructionProgressForBlock?.map((item) => {
        let activityId = item?.activity_id
        cpActivitywiseProgressBlock[activityId] = item?.progress
      })
    setConstructionProgressActivityWiseForBlock(cpActivitywiseProgressBlock)

    }
  }, [dashboard?.constructionProgressForBlock])

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])
  const handleTrackerOpen = async () => {
    setOpenTrackerModal(true)

    try {
      let tSelectedRow = selectedRows.map((row) => {
        return parseInt(row.split("-")[1]);
      });
      let tSelectedRowNo = selectedRows.map((row) => {
        return parseInt(row.split("-")[3]);
      });
      let tSelectedSec = selectedRows.map((row) => {
        return parseInt(row.split("-")[4]);
      });
      if (online) {
        setLoading(true)
        await fetchSelectedTrackersBOM({
          block_id: parseInt(params.id),
          selected_rows: tSelectedRow,
          tSelectedSec: tSelectedSec,
          selected_row_no: tSelectedRowNo
        });
      }
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  };
  const handleTrackerClose = () => {
    setOpenTrackerModal(false)
    setSelectedRowBomID(0)
  };
  const handleOverallBomOpen = () => {
    setOpenOverallBomModal(true)
    billOfMaterialsForProject()
  };
  const handleOverallBomClose = () => {
    setOpenOverallBomModal(false)
    setSelectedRowBomID(0)
  }
  const handleCloseBomforSelectedBlocks = () => {
    setOpenBomforSelectedBlocksModal(false)
  }

  const getBlockNameForSelectedBlocks = (blockId) => {
    let blockName = ""
    for (let i of dashboard?.block) {
      if (parseInt(i?.id) === parseInt(blockId)) {
        blockName = i?.data?.name
      }
    }
    return blockName
  }
  useEffect(() => {
    trackerSubmit()
  }, [selectedRows])

  const trackerSubmit = async () => {
    let hightlightData = new Set()
    // if (isZimmerman === true) {
    //   for (let i of selectedRows) {
    //     hightlightData.add(parseInt(i.split("-")[1]))
    //   }
    // } else {
    //   for (let i of selectedRows) {
    //     hightlightData.add(parseInt(i.split("-")[2]))
    //   }
    // }
    for (let i of selectedRows) {
      hightlightData.add(parseInt(i?.split("-")[2]))
    }
    try {
      if (selectedRows.length !== 0) {
        await fetchHighLightSelectedTrackerTypeIds(Array.from(hightlightData))
      } else {
        await fetchHighLightSelectedTrackerTypeIds([])
        await fetchSelectedTrackerTypeIds([])
        await fetchPreviousSelectedTrackerTypeId(null)
      }
    }
    catch (err) {
      console.log(err)
    }

  }

  useEffect(() => {
    init();
  }, [syncStatusData.syncing]);

  const init = async () => {
    try {
      await fetchTrackerTypeName();
      await fetchActivities();
      await fetchConstructionProgressForProject()
      await fetchConstructionProgressForBlock()
      await fetchTrackerTypesForProject();
      await fetchTrackerTypesForBlock(params?.id);
      await fetchActivityMilestoneForBlock();
      await fetchActivityMilestoneForProject();
      await fetchPierLegendData();
      await fetchBlockData();
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSelectedRowBillOfMaterials()
  }, [selectedRows]);

  const getSelectedRowBillOfMaterials = async () => {
    if (navigator.onLine) {
      setOnline(true);
    } else {
      setOnline(false);
    }

  }

  const handleSelectTrackerTypeNames = async (data) => {
    let selectedTrackerTypeIds = dashboard.selectedTrackerTypeIds
    let previousSelectedTrackerTypeId = dashboard?.previousSelectedTrackerTypeId
    if (selectedTrackerTypeIds.includes(data.id)) {
      selectedTrackerTypeIds = selectedTrackerTypeIds.filter(r => r !== data.id)
      previousSelectedTrackerTypeId = data.id
    }
    else {
      selectedTrackerTypeIds = [...selectedTrackerTypeIds, data.id]
      previousSelectedTrackerTypeId = null
    }
    try {
      await fetchSelectedTrackerTypeIds(selectedTrackerTypeIds)
      await fetchPreviousSelectedTrackerTypeId(previousSelectedTrackerTypeId)
    } catch (err) {
      console.log(err)
    }
  }


  //sorting for block

  const billOfMaterialsForBlock = () => {
    let sortedBOMForBlock;
    for (let i of dashboard?.activityMilestoneForBlock) {
      if (parseInt(i?.id) === parseInt(params.id)) {
        let filteredData = i?.data?.filter((data) => data?.project_quantity_calc !== 0)
        if (isZimmerman === true) {
          sortedBOMForBlock = filteredData
        } else {
          sortedBOMForBlock = lodash.sortBy(filteredData, [
            function (o) {
              return o.part_name;
            },
          ])
        }
      }
    };
    setSortedBillOfMaterialsForBlock(sortedBOMForBlock)
  }


  //sorting for overall project
  const billOfMaterialsForProject = () => {
    let sortedBOM
    if (dashboard?.activityMilestoneForProject.length !== 0) {
      let filteredData = dashboard?.activityMilestoneForProject?.filter((data) => data?.project_quantity_calc !== 0)
      if (isZimmerman === true) {
        sortedBOM = filteredData
      } else {
        sortedBOM = lodash.sortBy(filteredData, [
          function (o) {
            return o.part_name;
          },
        ]);
      }
      setSortedBillOfMaterialsForProject(sortedBOM)
    }
  }

  // shows part description when clicked
  const showPartDescription = (e, data) => {
    setBomId(data?.bom_id)
    setShowText(!showText)
    setPartDescriptionData(e.target.textContent)
  }
  // Returns block name
  let blockDetails = dashboard?.block?.find((y) => y.id === parseInt(params.id));

  // Returns Selected Tracker Type
  const findTrackerName = (data) => {
    if (dashboard?.trackerTypeNames.length !== 0) {
      let name = dashboard?.trackerTypeNames?.find((k) => parseInt(k?.id) === parseInt(data));
      return name?.tracker_type;
    }
  };

  // sorting tracker type for overall project
  useEffect(() => {
    if (dashboard?.trackerTypesForProject && dashboard?.trackerTypeNames.length !== 0) {
      let trackerSort = [];
      let prt = [];
      dashboard?.trackerTypesForProject?.map((i) => {
        prt.push(
          `${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]
          }`
        );
      });

      prt = [...new Set(prt)]

      prt.map((i) => {
        let trName = {};
        trName.name = i;
        let tcount = 0;
        dashboard?.trackerTypesForProject?.map((j) => {
          if (
            `${i}` ===
            `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]
            }`
          ) {

            tcount += j?.count;
          }
        });
        trName.count = tcount;
        trackerSort.push(trName);
        dashboard?.trackerTypesForProject?.map((j) => {
          if (
            `${i}` ===
            `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]
            }`
          ) {
            let newtrName = {};
            newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
            newtrName.count = j?.count;
            trackerSort.push(newtrName);
          }
        });
      });
      setSortedTrackerTypeNamesForProject(trackerSort)
    }
  }, [dashboard?.trackerTypesForProject, dashboard?.trackerTypeName])

  //sorting tracker type for block
  useEffect(() => {
    if (dashboard?.trackerTypesForBlock && dashboard?.trackerTypeNames.length !== 0) {
      let trackerSortBlk = [];
      let prtBlk = [];
      dashboard?.trackerTypesForBlock?.map((i) => {
        prtBlk.push(
          `${findTrackerName(i?.tracker_type_id)?.split("_")[0]}_${findTrackerName(i?.tracker_type_id)?.split("_")[1]
          }`
        );
      });

      prtBlk = [...new Set(prtBlk)]

      prtBlk.map((i) => {
        let trName = {};
        trName.name = i;
        let tcount = 0;
        dashboard?.trackerTypesForBlock?.map((j) => {
          if (
            `${i}` ===
            `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]
            }`
          ) {

            tcount += j?.count;
          }
        });
        trName.count = tcount;
        trackerSortBlk.push(trName);
        dashboard?.trackerTypesForBlock?.map((j) => {
          if (
            `${i}` ===
            `${findTrackerName(j?.tracker_type_id)?.split("_")[0]}_${findTrackerName(j?.tracker_type_id)?.split("_")[1]
            }`
          ) {
            let newtrName = {};
            newtrName.name = `${findTrackerName(j?.tracker_type_id)}+_+sub`
            newtrName.count = j?.count;
            newtrName.id = j.tracker_type_id
            trackerSortBlk.push(newtrName);
          }
        });
      });
      setSortedTrackerTypeNamesForBlock(trackerSortBlk)
    }
  }, [dashboard?.trackerTypesForBlock, dashboard?.trackerTypeName])

  // Activity alignment

  let CPparActIds = []
  let QCparActIds = [];
  let finalCPArr = []
  let finalQCArr = [];

  if (dashboard?.constructionProgressForProject && activities?.construction) {
    for (let cp in dashboard.constructionProgressForProject) {
      if (!activities?.construction[cp]?.parent_activity_id) {
        CPparActIds.push(cp)
      }
    }
  }


  CPparActIds.map((pt) => {
    finalCPArr.push(parseInt(pt))
    Object.values(activities?.construction)?.map((ij) => {
      if (ij.parent_activity_id === parseInt(pt)) {
        finalCPArr.push(ij.id)
      }
    })
  })



  if (dashboard.overallQc && activities?.qc) {
    dashboard.overallQc.map((qc) => {
      if (!activities.qc[qc.activity_id]?.parent_activity_id) {
        QCparActIds.push(qc.activity_id);
      }
    });
  }



  // QC parent Activity for OverAll Project 
  QCparActIds.map((pt) => {
    finalQCArr.push(parseInt(pt));
    if (activities.qc) {
      Object.values(activities.qc)?.map((ij) => {
        if (ij.parent_activity_id === parseInt(pt)) {
          finalQCArr.push(ij.id);
        }
      });
    }
  });


  // QC progress for overall project
  const findQCProgress = (data) => {
    let temp = 0;
    dashboard?.overallQc?.map((i) => {
      if (i?.activity_id === data) {
        temp = i?.progress;
      }
    });
    if (isNaN(temp)) {
      temp = 0
    }
    return temp;
  };


  // QC progress for block
  const findQCProgressBlk = (data) => {
    let temp = 0;
    Object.keys(dashboard?.qcPerBlock).map((i) => {
      if (i === params.id) {
        dashboard?.qcPerBlock[i]?.map((j) => {
          if (j?.activity_id === data) {
            temp = j?.progress;
          }
        });
      }
    });
    if (isNaN(temp)) {
      temp = 0
    }
    return temp;
  };

  // Overall percentage for QC

  const ttlOverAllPercentQc = () => {
    let subList = [];
    let subWight = [];
    if (activities?.qc) {
      Object?.keys(activities?.qc)?.map((i) => {
        if (activities?.qc[i]?.parent_activity_id) {
          subWight.push(activities?.qc[i]?.weight);
          subList.push(activities?.qc[i]?.weight * findQCProgress(parseInt(i)));
        }
      });
    }
    let temp = Math.floor(
      subList.reduce((a, b) => a + b, 0) / subWight.reduce((a, b) => a + b, 0)
    );
    if (isNaN(temp)) {
      temp = 0
    }
    return temp
  };

  // Overall percentage for QC block
  const ttlOverAllPercentQcBlk = () => {
    let subList = [];
    let subWight = [];
    if (activities?.qc) {
      Object?.keys(activities?.qc)?.map((i) => {
        if (activities?.qc[i]?.parent_activity_id) {
          subWight.push(activities?.qc[i]?.weight);
          subList.push(activities?.qc[i]?.weight * findQCProgressBlk(parseInt(i)));
        }
      });
    }

    let temp = Math.floor(
      subList.reduce((a, b) => a + b, 0) / subWight.reduce((a, b) => a + b, 0)
    );
    if (isNaN(temp)) {
      temp = 0
    }
    return temp
  };
  const handleBillOfMaterialsRowCicked = (data) => {
    setSelectedRowBomID(data?.bom_id)
  }
  useEffect(() => {
    getIsBomforProjectEnabled()
  }, [dashboard?.selectedBlocksInSVG])

  const getIsBomforProjectEnabled = () => {
    if (dashboard?.selectedBlocksInSVG.length === dashboard?.block.length || dashboard?.selectedBlocksInSVG.length === 0) {
      setIsBomforProjectEnabled(true)
    } else {
      setIsBomforProjectEnabled(false)
    }
  }

  const handleBomforSelectedBlocks = async () => {
    setOpenBomforSelectedBlocksModal(true)
    try {
      setLoading(true)
      await fetchBomForSelectedBlocks(dashboard?.selectedBlocksInSVG)
      setLoading(false)
    } catch (err) {
      console.log(err)
    }

  }

  return (
    <Box
      sx={{
        height: "calc(100vh - 20vh)",
        overflowX: "none",
        overflowY: "auto",
      }}
    >
      {!params?.id ? isBomforProjectEnabled === true ? (
        <Box
          sx={{ display: "flex", padding: "10px" }}
          onClick={handleOverallBomOpen}
        >
          <Typography sx={{ color: "#186DEC" }}>
            BOM Status for Project
          </Typography>
          <OpenInNewOutlinedIcon sx={{ paddingLeft: "10px" }} />
        </Box>
      ) : (<Box
        sx={{ display: "flex", padding: "10px" }}
        onClick={handleBomforSelectedBlocks}
      >
        <Typography sx={{ color: "#186DEC" }}>
          BOM Status for Selected Blocks
        </Typography>
        <OpenInNewOutlinedIcon sx={{ paddingLeft: "10px" }} />
      </Box>) : (
        <Box sx={{ display: "flex", padding: "10px" }}>
          {dashboard?.isAllTrackersSelected === true ?
            <Box
              sx={{ display: "flex", paddingRight: "25px" }}
              onClick={handleOpen}
            >
              <Typography sx={{ color: "#186DEC" }}>
                BOM Status for {blockDetails?.data?.name}
              </Typography>
              <OpenInNewOutlinedIcon sx={{ color: "#696F79" }} />
            </Box> :
            <Box>
              {online && selectedRows?.length > 0 ? (
                <Box
                  sx={{ display: "flex", paddingRight: "25px", color: "#186DEC" }}
                  onClick={handleTrackerOpen}
                >
                  <Typography>{isZimmerman === true ? "BOM Status for Selected tables" : "BOM Status for Selected trackers"}</Typography>
                  <OpenInNewOutlinedIcon sx={{ color: "#696F79" }} />
                </Box>
              ) : (
                <Box
                  sx={{ display: "flex", paddingRight: "25px", color: "#696F79" }}
                >
                  <Typography>{isZimmerman === true ? "BOM Status for Selected tables" : "BOM Status for Selected trackers"}</Typography>
                  <OpenInNewOutlinedIcon sx={{ color: "#696F79" }} />
                </Box>
              )}
            </Box>}
        </Box>

      )}
      {!params?.id ? (
        <Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "0px 10px",
              padding: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Construction Progress
              </Typography>
              <Typography variant="h6" sx={{ color: "#F16253" }}>
                {activities?.construction && dashboard?.constructionProgressForProject ? constructionOverallProgressForProject(activities?.construction, dashboard?.constructionProgressForProject) : null}%
              </Typography>
            </Box>
            {finalCPArr?.map((i, ti) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box sx={{ fontSize: "10px" }}>
                        <Typography sx={{ padding: "2px 0px" }}>
                          {activities?.construction[i]?.parent_activity_id ? (
                            <Typography sx={{ fontSize: "11px", paddingLeft: "4px" }}>
                              {activities?.construction
                                ? activities?.construction[i]?.name
                                : i}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {activities?.construction
                                ? activities?.construction[i]?.name
                                : i}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>

                    <Box>
                      <Box
                        key={`tcp-${ti}`}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2px 0px",
                          width: "130px",
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={activities?.construction && dashboard?.constructionProgressForProject && get_construction_progress_for_parent_sub_activity(activities?.construction, dashboard?.constructionProgressForProject, i)}
                          sx={{
                            width: "85px",
                            height: "8px",
                            borderRadius: "8px",
                          }}
                          color={
                            activities?.construction && dashboard?.constructionProgressForProject && get_construction_progress_for_parent_sub_activity(activities?.construction, dashboard?.constructionProgressForProject, i) === 100 ? "success" : "error"
                          }
                        />
                        <Typography
                          sx={{ padding: "0px 5px", fontSize: "14px" }}
                        >
                          {/* {Math.floor(dashboard?.constructionProgressForProject[i])}% */}
                          {activities?.construction && dashboard?.constructionProgressForProject && get_construction_progress_for_parent_sub_activity(activities?.construction, dashboard?.constructionProgressForProject, i)}%

                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              // height: 222,
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "6px 10px",
              padding: "8px",
              backgroundColor:
                process.env["REACT_APP_MVP"] === "true" ? "#DADBDD" : null,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Quality Check Progress
              </Typography>
              <Typography variant="h6" sx={{ color: "#F16253" }}>

                {ttlOverAllPercentQc()}%
              </Typography>
            </Box>
            {finalQCArr?.map((i, index) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box key={`tqc-${index}`} sx={{ fontSize: "12px" }}>
                        <Typography sx={{ padding: "2px 0px" }}>
                          {activities?.qc[i]?.parent_activity_id ? (
                            <Typography sx={{ fontSize: "11px", paddingLeft: "4px" }}>

                              {activities?.qc ? activities?.qc[i]?.name : i}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {activities?.qc ? activities?.qc[i]?.name : i}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        key={`tqc-${index}`}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2px 0px",
                          width: "130px",
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={findQCProgress(i)}
                          sx={{
                            width: "85px",
                            height: "8px",
                            borderRadius: "8px",
                          }}
                          color={Math.floor(findQCProgress(i)) >= 100 ? "success" : "error"}
                        />
                        <Typography
                          sx={{ padding: "0px 5px", fontSize: "14px" }}
                        >
                          {Math.floor(findQCProgress(i))}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              // height: 160,
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "6px 10px ",
              padding: "8px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    width: "85%",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                >
                  {isZimmerman ? "Table Type" : "Tracker Type"}
                </Box>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "15%",
                    borderBottom: "none",
                    padding: "0px",
                    margin: "0 25px 0 0",
                  }}
                >
                  Quantities
                </Box>
              </Box>
              <Box sx={{ borderBottom: "none", padding: "2px 0px" }}>
                {sortedTrackerTypeNamesForProject && sortedTrackerTypeNamesForProject?.map((i, index) => {
                  return (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {i?.name.split("+_+").length === 2 ? (
                        <Typography
                          key={`lbo-${index}`}
                          sx={{
                            fontSize: "11.2px",
                            margin: "5px 0",
                            paddingLeft: "10px",
                          }}
                        >
                          {i?.name.split("+_+")[0]}
                        </Typography>
                      ) : (

                        <Typography
                          key={`lbo-${index}`}
                          sx={{
                            fontSize: "11.2px",
                            margin: "5px 0",
                            fontWeight: "bold",
                          }}
                        >
                          {isZimmerman === true ? i?.name.split("_")[0] : i?.name}

                        </Typography>
                      )
                      }
                      <Typography
                        key={`lbot-${index}`}
                        sx={{
                          fontSize: "12px",
                          padding: "0 20px 0 0",
                          margin: "5px ",
                        }}
                      >
                        {i?.count}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>

            </Box>

          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              // height: 157,
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "8px 10px",
              padding: "8px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Construction Progress
              </Typography>
              <Typography variant="h6" sx={{ color: "#F16253" }}>
                {constructionOverallProgressForBlock(activities?.construction, dashboard?.constructionProgressForBlock[params?.id])}%
              </Typography>
            </Box>

            {finalCPArr?.map((i, index) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box key={`tcpp-${index}`} sx={{ fontSize: "12px" }}>
                        <Typography sx={{ padding: "2px 0px" }}>
                          {activities?.construction[i]?.parent_activity_id ? (
                            <Typography sx={{ fontSize: "11px", paddingLeft: "4px" }}>

                              {activities?.construction
                                ? activities?.construction[i]?.name
                                : i}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {activities?.construction
                                ? activities?.construction[i]?.name
                                : i}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        key={`tcppb-${index}`}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2px 0px",
                          width: "130px",
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={ constructionProgressActivityWiseForBlock && activities?.construction && get_construction_progress_for_parent_sub_activity(activities?.construction, constructionProgressActivityWiseForBlock, i)}
                          sx={{
                            width: "85px",
                            height: "8px",
                            borderRadius: "8px",
                          }}
                          color={
                            constructionProgressActivityWiseForBlock && activities?.construction && get_construction_progress_for_parent_sub_activity(activities?.construction, constructionProgressActivityWiseForBlock, i) === 100 ? "success" : "error"
                          }
                        />
                        <Typography
                          sx={{ padding: "0px 5px", fontSize: "14px" }}
                        >
                          {/* {constructionActivityProgressForBlock(constructionActivityProgressForBlock, i)}% */}
                          {constructionProgressActivityWiseForBlock && activities?.construction && get_construction_progress_for_parent_sub_activity(activities?.construction, constructionProgressActivityWiseForBlock, i)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              // height: 222,
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "8px 10px",
              padding: "8px",
              backgroundColor:
                process.env["REACT_APP_MVP"] === "true" ? "#DADBDD" : null,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "18px", fontWeight: "700" }}>
                Quality Check Progress
              </Typography>
              <Typography variant="h6" sx={{ color: "#F16253" }}>

                {ttlOverAllPercentQcBlk()}%
              </Typography>
            </Box>
            {finalQCArr?.map((i, index) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <Box key={`tqcpb-${index}`} sx={{ fontSize: "12px" }}>
                        <Typography sx={{ padding: "2px 0px" }}>
                          {activities?.qc[i]?.parent_activity_id ? (
                            <Typography sx={{ fontSize: "11px", paddingLeft: "4px" }}>

                              {activities?.qc ? activities?.qc[i]?.name : i}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{ fontWeight: "bold", fontSize: "13px" }}
                            >
                              {activities?.qc ? activities?.qc[i]?.name : i}
                            </Typography>
                          )}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        key={`tqcpb-${index}`}
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "2px 0px",
                          width: "130px",
                        }}
                      >
                        <LinearProgress
                          variant="determinate"
                          value={findQCProgressBlk(i)}
                          sx={{
                            width: "85px",
                            height: "8px",
                            borderRadius: "8px",
                          }}
                          color={
                            findQCProgressBlk(i) >= 100 ? "success" : "error"
                          }
                        />
                        <Typography
                          sx={{ padding: "0px 5px", fontSize: "14px" }}
                        >
                          {Math.floor(findQCProgressBlk(i))}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "calc(100%-810px)",
              // height: 160,
              backgroundColor: "white",
              border: "1px solid black",
              borderRadius: "10px",
              margin: "8px 10px",
              padding: "8px",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box
                  sx={{
                    fontSize: "16px",
                    fontWeight: "700",
                    width: "80%",
                    borderBottom: "none",
                    padding: "0px",
                  }}
                >
                  {isZimmerman ? "Table Type" : "Tracker Type"}
                </Box>
                <Box
                  sx={{
                    fontSize: "14px",
                    fontWeight: "700",
                    width: "20%",
                    borderBottom: "none",
                    padding: "0px",
                    margin: "0 25px 0 0",
                  }}
                >
                  Quantities
                </Box>
              </Box>
              <Box sx={{ borderBottom: "none", padding: "2px 0px" }}>

                {sortedTrackerTypeNamesForBlock && sortedTrackerTypeNamesForBlock?.map((i, index) => {

                  return (
                    <Box
                      sx={{ display: "flex", justifyContent: "space-between" }}
                    >
                      {i?.name.split("+_+").length === 2 ? (
                        <Typography
                          key={`lbo-${index}`}
                          sx={{
                            fontSize: "11.2px",
                            margin: "5px 5px 5px 10px",
                            padding: "5px 6px",
                            border: dashboard?.highLightSelectedTrackerTypeIds.includes(i?.id) ? "2px solid #F16253" : "1px solid grey",
                            backgroundColor: dashboard?.selectedTrackerTypeIds.includes(i?.id) ? "#F16253" : null,
                            color: dashboard?.selectedTrackerTypeIds.includes(i?.id) ? "white" : "black",
                          }}
                          onClick={() => handleSelectTrackerTypeNames(i)}
                        >
                          {i?.name.split("+_+")[0]}
                        </Typography>
                      ) : (
                        <Typography
                          key={`lbo-${index}`}
                          sx={{
                            fontSize: "11.2px",
                            margin: "5px 0",
                            fontWeight: "bold",
                          }}
                        >
                          {isZimmerman === true ? i?.name.split("_")[0] : i?.name}
                        </Typography>
                      )}
                      <Typography
                        key={`lbot-${index}`}
                        sx={{
                          fontSize: "12px",
                          padding: "0 20px 0 0",
                          margin: "5px ",
                        }}
                      >
                        {i?.count}
                      </Typography>
                    </Box>
                  );
                })}
              </Box>
            </Box>

          </Box>
        </Box>
      )
      }
      {/* modal for bom per block */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "90vh",
            width: "95vw",
            overflowY: "auto",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Divider />
          <TableContainer sx={{ overflowX: "initial" }}>
            {/* {isZimmerman === true ?  */}
            {/* <Table sx={{ padding: "10px" }} size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{ position: "sticky", top: 0, zIndex: "20" }}>
                  <TableCell colSpan="10">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "10px",
                        position: "sticky",
                        top: 0,
                        zIndex: 20,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {blockDetails?.data?.name} - Bill of Materials
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 20px",
                        }}
                      >
                        <CloseIcon
                          sx={{ padding: "10px 0  10px 15px" }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 76.2, zIndex: 20 }}>
                  <TableCell rowSpan="2">Part Number</TableCell>
                  <TableCell rowSpan="2">Block Qty</TableCell>
                </TableRow>

              </TableHead>
              <TableBody>
                {sortedBillOfMaterialsForBlock?.map((j) => {
                  return (
                    <>
                      <TableRow onClick={() => {
                        handleBillOfMaterialsRowCicked(j)
                      }}
                        sx={{ backgroundColor: selectedRowBomId === j?.bom_id ? "rgb(229 231 235)" : "white" }}>
                        <TableCell sortDirection="asc">
                          {j?.part_number}
                        </TableCell>
                        <TableCell>{j?.project_quantity}</TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table> : */}
            <Table sx={{ padding: "10px" }} size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{ position: "sticky", top: 0, zIndex: "20" }}>
                  <TableCell colSpan="10">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "10px",
                        position: "sticky",
                        top: 0,
                        zIndex: 20,
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        {blockDetails?.data?.name} - Bill of Materials
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "10px 20px",
                        }}
                      >
                        <CloseIcon
                          sx={{ padding: "10px 0  10px 15px" }}
                          onClick={handleClose}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 76.2, zIndex: 20 }}>
                  <TableCell rowSpan="2">Part Number</TableCell>
                  <TableCell rowSpan="2">Part Description</TableCell>
                  <TableCell rowSpan="2">Block Qty</TableCell>
                  <TableCell colspan="2">Distributed Quantities</TableCell>
                  <TableCell colspan="2">Installed Quantities</TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 113, zIndex: 20 }}>
                  <TableCell>%</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>%</TableCell>
                  <TableCell>Qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBillOfMaterialsForBlock?.map((j) => {
                  return (
                    <>
                      <TableRow onClick={() => {
                        handleBillOfMaterialsRowCicked(j)
                      }}
                        sx={{ backgroundColor: selectedRowBomId === j?.bom_id ? "rgb(229 231 235)" : "white" }}>
                        <TableCell sortDirection="asc">
                          {j?.part_name}
                        </TableCell>
                        <TableCell onClick={e => showPartDescription(e, j)} sx={{ position: "relative" }}>
                          {showText && j?.bom_id === bomId ?
                            <Box>
                              <Typography sx={{ backgroundColor: "white", width: "450px", padding: "4px", boxShadow: "0 5px 12px rgb(0 0 0 / 0.4)", position: "absolute", zIndex: 10, top: 0 }}>{partDescriptionData}</Typography>
                            </Box>
                            :
                            <div className="textContainer">{j?.description}</div>}
                        </TableCell>
                        <TableCell>{j?.project_quantity_calc}</TableCell>
                        <TableCell>
                          {Math.round(j?.distribution_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {j?.distribution_progress !== null ? Math.round(j?.distribution_progress) : "0.00"}%
                            </Typography>
                            :
                            Math.round(j?.distribution_progress * 100) / 100 > 0 && Math.round(j?.distribution_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{j?.distribution_progress !== null ? Math.round(j?.distribution_progress * 100) / 100 : "0.00"}%</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{j?.distribution_progress !== null ? Math.round(j?.distribution_progress * 100) / 100 : "0.00"}%</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(j?.distribution_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {Math.round(j?.distributed_quantity)}
                            </Typography>
                            :
                            Math.round(j?.distribution_progress * 100) / 100 > 0 && Math.round(j?.distribution_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{Math.round(j?.distributed_quantity * 100) / 100}</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{Math.round(j?.distributed_quantity * 100) / 100}</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(j?.installed_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {j?.installed_progress !== null ? Math.round(j?.installed_progress) : "0.00"}%
                            </Typography>
                            :
                            Math.round(j?.installed_progress * 100) / 100 > 0 && Math.round(j?.installed_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{j?.installed_progress !== null ? Math.round(j?.installed_progress * 100) / 100 : "0.00"}%</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{j?.installed_progress !== null ? Math.round(j?.installed_progress * 100) / 100 : "0.00"}%</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(j?.installed_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {Math.round(j?.installed_quantity)}
                            </Typography>
                            :
                            Math.round(j?.installed_progress * 100) / 100 > 0 && Math.round(j?.installed_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{Math.round(j?.installed_quantity * 100) / 100}</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{Math.round(j?.installed_quantity * 100) / 100}</Typography>
                          }
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {/* } */}
          </TableContainer>
          <Box sx={{ marginTop: "73px" }}></Box>
        </Box>
      </Modal>
      {/* modal for selected trackers */}
      <Modal
        open={openTrackerModal}
        onClose={() => setOpenTrackerModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "90vh",
            width: "90vw",
            overflowY: "auto",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Divider />
          <TableContainer sx={{ overflowX: "initial" }}>
            {loading ?
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    marginY: "10px"
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    {blockDetails?.data?.name} - {isZimmerman === true ? "Selected Tables " : "Selected Trackers"} - Bill of
                    Materials
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "10px 20px",
                    }}
                  >
                    {/* <Box
                sx={{
                  paddingRight: "10px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                onClick={handleDownloadCSV}
              >
                <RiFolderDownloadFill fontSize={20} />
                <Typography sx={{ paddingX: "5px" }}>Download</Typography>
              </Box> */}
                    <CloseIcon
                      sx={{ padding: "10px 0  10px 15px" }}
                      onClick={handleTrackerClose}
                    />
                  </Box>
                </Box>
                <Box sx={{
                  height: "70vh",
                  width: "90vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <CircularProgress fontSize={26} />
                  <Typography sx={{ paddingY: "10px", fontSize: "18px" }}>Fetching Data</Typography></Box>
              </Box>
              // : isZimmerman === true ?
              //   <Table sx={{ padding: "10px" }} size="small" stickyHeader>
              //     <TableHead>
              //       <TableRow sx={{ position: "sticky", top: 0, zIndex: "20" }}>
              //         <TableCell colspan="12">
              //           <Box
              //             sx={{
              //               display: "flex",
              //               justifyContent: "space-between",
              //               alignItems: "center",
              //               paddingLeft: "10px",
              //             }}
              //           >
              //             <Typography
              //               id="modal-modal-title"
              //               variant="h6"
              //               component="h2"
              //             >
              //               {blockDetails?.data?.name} - Selected Tables - Bill of
              //               Materials
              //             </Typography>
              //             <Box
              //               sx={{
              //                 display: "flex",
              //                 justifyContent: "space-between",
              //                 alignItems: "center",
              //                 padding: "10px 20px",
              //               }}
              //             >
              //               {/* <Box
              //   sx={{
              //     paddingRight: "10px",
              //     display: "flex",
              //     justifyContent: "space-between",
              //     alignItems: "center",
              //   }}
              //   onClick={handleDownloadCSV}
              // >
              //   <RiFolderDownloadFill fontSize={20} />
              //   <Typography sx={{ paddingX: "5px" }}>Download</Typography>
              // </Box> */}
              //               <CloseIcon
              //                 sx={{ padding: "10px 0  10px 15px" }}
              //                 onClick={handleTrackerClose}
              //               />
              //             </Box>
              //           </Box>
              //         </TableCell>
              //       </TableRow>
              //       <TableRow sx={{ position: "sticky", top: 76, zIndex: "20" }}>
              //         <TableCell colspan="2">Selected Tables</TableCell>
              //         <TableCell rowSpan="2">Part Number</TableCell>
              //         <TableCell rowSpan="2">Required Qty</TableCell>
              //       </TableRow>
              //       <TableRow sx={{ position: "sticky", top: isZimmerman === true ? 124 : 137, zIndex: "20" }}>
              //         <TableCell> Row</TableCell>
              //         <TableCell> Table</TableCell>
              //       </TableRow>
              //     </TableHead>
              //     <TableBody>
              //       {dashboard?.selectedTrackersBom?.map((j) => {
              //         return (
              //           <>
              //             <TableRow onClick={() => {
              //               handleBillOfMaterialsRowCicked(j)
              //             }}
              //               sx={{ backgroundColor: selectedRowBomId === j?.bom_id ? "rgb(229 231 235)" : "white" }}>
              //               <TableCell>{j?.section}</TableCell>
              //               <TableCell>{j?.row_no}</TableCell>
              //               <TableCell>
              //                 {j?.part_number}
              //               </TableCell>
              //               <TableCell>{j?.project_quantity}</TableCell>

              //             </TableRow>
              //           </>
              //         );
              //       })}
              //     </TableBody>

              //   </Table> 
              :
              <Table sx={{ padding: "10px" }} size="small" stickyHeader>
                <TableHead>
                  <TableRow sx={{ position: "sticky", top: 0, zIndex: "20" }}>
                    <TableCell colspan="12">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "10px",
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          {isZimmerman === true ? `${blockDetails?.data?.name} - Selected Tables - Bill of
                            Materials`:
                            `${blockDetails?.data?.name} - Selected Trackers - Bill of
                            Materials`
                          }
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "10px 20px",
                          }}
                        >

                          <CloseIcon
                            sx={{ padding: "10px 0  10px 15px" }}
                            onClick={handleTrackerClose}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ position: "sticky", top: 76, zIndex: "20" }}>
                    <TableCell colspan="2">{isZimmerman === true ? "Selected Tables" : "Selected Trackers"}</TableCell>
                    <TableCell rowSpan="2">Part Number</TableCell>
                    <TableCell rowSpan="2">Part Description</TableCell>
                    <TableCell rowSpan="2">Required Qty</TableCell>
                    <TableCell colspan="2" sx={{ width: 100 }}>Distributed Quantities</TableCell>
                    <TableCell colspan="2" sx={{ width: 100 }}>Installed Quantities</TableCell>
                  </TableRow>
                  <TableRow sx={{ position: "sticky", top: 137, zIndex: "20" }}>
                    <TableCell>{isZimmerman === true ? "Row" : "Section"}</TableCell>
                    <TableCell>{isZimmerman === true ? "Table" : "Tracker Row"}</TableCell>
                    <TableCell>%</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>%</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboard?.selectedTrackersBom?.map((j) => {
                    return (
                      <>
                        <TableRow onClick={() => {
                          handleBillOfMaterialsRowCicked(j)
                        }}
                          sx={{ backgroundColor: selectedRowBomId === j?.bom_id ? "rgb(229 231 235)" : "white" }}>
                          <TableCell>{j?.section}</TableCell>
                          <TableCell>{j?.row_no}</TableCell>
                          <TableCell sx={{ width: isZimmerman === true ? 120 : 90 }}>
                            {j?.part_name}
                          </TableCell>
                          <TableCell onClick={e => showPartDescription(e, j)} sx={{ position: "relative" }}>
                            {showText && j?.bom_id === bomId ?
                              <Box>
                                <Typography sx={{ backgroundColor: "white", width: "450px", padding: "4px", boxShadow: "0 5px 12px rgb(0 0 0 / 0.4)", position: "absolute", zIndex: 10, top: 0 }}>{partDescriptionData}</Typography>
                              </Box>
                              :
                              <div className="textContainer">{j?.description}</div>}
                          </TableCell>
                          <TableCell>{j?.project_quantity_calc}</TableCell>
                          <TableCell>
                            {(Math.round(j?.distribution_progress * 100) / 100) > 0 && (Math.round(j?.distribution_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress * 100) / 100}%`}
                              </Typography>
                              : Math.round(j?.distribution_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress)}%`}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress * 100) / 100}%`}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.distribution_progress * 100) / 100) > 0 && (Math.round(j?.distribution_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity * 100) / 100}
                              </Typography>
                              : Math.round(j?.distribution_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity)}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity * 100) / 100}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.installed_progress * 100) / 100) > 0 && (Math.round(j?.installed_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress * 100) / 100}%`}
                              </Typography>
                              : Math.round(j?.installed_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress)}%`}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress * 100) / 100}%`}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.installed_progress * 100) / 100) > 0 && (Math.round(j?.installed_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity * 100) / 100}
                              </Typography>
                              : Math.round(j?.installed_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity)}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity * 100) / 100}
                                </Typography>}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>

              </Table>
            }
          </TableContainer>
          <Box sx={{ marginTop: "73px" }}></Box>
        </Box>
      </Modal>
      {/* modal for overall bom */}
      <Modal
        open={openOverallBomModal}
        onClose={() => setOpenOverallBomModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "95vh",
            width: "95vw",
            overflowY: "auto",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Divider />
          <TableContainer sx={{ overflowX: "initial" }}>

            <Table size="small" stickyHeader>
              <TableHead>
                <TableRow sx={{ position: "sticky", top: 0, zIndex: 20 }}>
                  <TableCell colSpan="12">
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingLeft: "10px",
                        backgroundColor: "white",
                      }}
                    >
                      <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                      >
                        Bill of Materials
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          padding: "5px 20px",
                        }}
                      >
                        <CloseIcon
                          sx={{ padding: "10px 0  10px 15px" }}
                          onClick={handleOverallBomClose}
                        />
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 66, zIndex: 20 }}>
                  <TableCell rowSpan="2" sx={{ width: 150, paddingX: "30px" }}>
                    Part Number
                  </TableCell>
                  <TableCell rowSpan="2">Part Description</TableCell>
                  <TableCell rowSpan="2">Project Qty</TableCell>
                  <TableCell rowSpan="2">Construction Spares</TableCell>
                  <TableCell rowSpan="2" sx={{ width: 125 }}>Addl Qty Incl. O&M Spares</TableCell>
                  <TableCell rowSpan="2">
                    Total <br /> Quantity
                  </TableCell>
                  <TableCell rowSpan="2">On-Site Qty</TableCell>
                  <TableCell colspan="2">Distributed Quantities</TableCell>
                  <TableCell colspan="2">Installed Quantities</TableCell>
                </TableRow>
                <TableRow sx={{ position: "sticky", top: 127, zIndex: 20 }}>
                  <TableCell>%</TableCell>
                  <TableCell>Qty</TableCell>
                  <TableCell>%</TableCell>
                  <TableCell>Qty</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedBillOfMaterialsForProject?.map((i) => {
                  return (
                    <>
                      <TableRow onClick={() => {
                        handleBillOfMaterialsRowCicked(i)
                      }}
                        sx={{ backgroundColor: selectedRowBomId === i?.bom_id ? "rgb(229 231 235)" : "white" }}>
                        <TableCell sx={{ width: 200 }}>
                          {i?.part_name}
                        </TableCell>
                        <TableCell onClick={e => showPartDescription(e, i)} sx={{ position: "relative" }}>
                          {showText && i?.bom_id === bomId ?
                            <Box>
                              <Typography sx={{ backgroundColor: "white", width: "450px", padding: "4px", boxShadow: "0 5px 12px rgb(0 0 0 / 0.4)", position: "absolute", zIndex: 10, top: 0 }}>{partDescriptionData}</Typography>
                            </Box>
                            :
                            <div className="textContainer">{i?.description}</div>}
                        </TableCell>
                        <TableCell>{i?.default_project_quantity}</TableCell>
                        <TableCell>{i?.spare_count}</TableCell>
                        <TableCell>
                          {i?.additional_count === null
                            ? 0
                            : i?.additional_count}
                        </TableCell>
                        <TableCell>{i?.project_quantity}</TableCell>
                        <TableCell>{i?.onsite_quantity}</TableCell>
                        <TableCell>
                          {Math.round(i?.distribution_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {i?.distribution_progress !== null ? Math.round(i?.distribution_progress) : "0.00"}%
                            </Typography>
                            :
                            Math.round(i?.distribution_progress * 100) / 100 > 0 && Math.round(i?.distribution_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{i?.distribution_progress !== null ? Math.round(i?.distribution_progress * 100) / 100 : "0.00"}%</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{i?.distribution_progress !== null ? Math.round(i?.distribution_progress * 100) / 100 : "0.00"}%</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(i?.distribution_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {Math.round(i?.distributed_quantity)}
                            </Typography>
                            :
                            Math.round(i?.distribution_progress * 100) / 100 > 0 && Math.round(i?.distribution_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{Math.round(i?.distributed_quantity * 100) / 100}</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{Math.round(i?.distributed_quantity * 100) / 100}</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(i?.installed_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {i?.installed_progress !== null ? Math.round(i?.installed_progress) : "0.00"}%
                            </Typography>
                            :
                            Math.round(i?.installed_progress * 100) / 100 > 0 && Math.round(i?.installed_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{i?.installed_progress !== null ? Math.round(i?.installed_progress * 100) / 100 : "0.00"}%</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{i?.installed_progress !== null ? Math.round(i?.installed_progress * 100) / 100 : "0.00"}%</Typography>
                          }
                        </TableCell>
                        <TableCell>
                          {Math.round(i?.installed_progress) === 100 ?
                            <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                              {Math.round(i?.installed_quantity)}
                            </Typography>
                            :
                            Math.round(i?.installed_progress * 100) / 100 > 0 && Math.round(i?.installed_progress * 100) / 100 < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>{Math.round(i?.installed_quantity * 100) / 100}</Typography>
                              :
                              <Typography sx={{ color: "black" }}>{Math.round(i?.installed_quantity * 100) / 100}</Typography>
                          }
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })}
              </TableBody>
            </Table>
            {/* } */}
          </TableContainer>
          <Box sx={{ marginTop: "73px" }}></Box>
        </Box>
      </Modal>

      {/* modal  for selected blocks */}

      <Modal
        open={openBomforSelectedBlocksModal}
        onClose={handleCloseBomforSelectedBlocks}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "95vh",
            width: "95vw",
            overflowY: "auto",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Divider />
          <TableContainer sx={{ overflowX: "initial" }}>
            {loading ?
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    paddingLeft: "10px",
                    backgroundColor: "white",
                  }}
                >
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                  >
                    Bill of Materials - Selected Blocks
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "5px 20px",
                    }}
                  >
                    <CloseIcon
                      sx={{ padding: "10px 0  10px 15px" }}
                      onClick={handleCloseBomforSelectedBlocks}
                    />
                  </Box>
                </Box>
                <Box sx={{
                  height: "70vh",
                  width: "90vw",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}>
                  <CircularProgress fontSize={26} />
                  <Typography sx={{ paddingY: "10px", fontSize: "18px" }}>Fetching Data</Typography></Box>
              </Box> :
              <Table size="small" stickyHeader>
                <TableHead>
                  <TableRow sx={{ position: "sticky", top: 0, zIndex: 20 }}>
                    <TableCell colSpan="12">
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          paddingLeft: "10px",
                          backgroundColor: "white",
                        }}
                      >
                        <Typography
                          id="modal-modal-title"
                          variant="h6"
                          component="h2"
                        >
                          Bill of Materials - Selected Blocks
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            padding: "5px 20px",
                          }}
                        >
                          <CloseIcon
                            sx={{ padding: "10px 0  10px 15px" }}
                            onClick={handleCloseBomforSelectedBlocks}
                          />
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ position: "sticky", top: 66, zIndex: 20 }}>
                    <TableCell rowSpan="2">Selected Blocks</TableCell>
                    <TableCell rowSpan="2">Part Number</TableCell>
                    <TableCell rowSpan="2">Part Description</TableCell>
                    <TableCell rowSpan="2">Required Qty</TableCell>
                    <TableCell colspan="2" sx={{ width: 100 }}>Distributed Quantities</TableCell>
                    <TableCell colspan="2" sx={{ width: 100 }}>Installed Quantities</TableCell>
                  </TableRow>
                  <TableRow sx={{ position: "sticky", top: 103, zIndex: 20 }}>
                    <TableCell>%</TableCell>
                    <TableCell>Qty</TableCell>
                    <TableCell>%</TableCell>
                    <TableCell>Qty</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dashboard?.bomForSelectedBlocks && dashboard?.bomForSelectedBlocks?.map((j) => {
                    return (
                      <>
                        <TableRow onClick={() => {
                          handleBillOfMaterialsRowCicked(j)
                        }}
                          sx={{ backgroundColor: selectedRowBomId === j?.bom_id ? "rgb(229 231 235)" : "white" }}>
                          <TableCell>{getBlockNameForSelectedBlocks(j?.block_id)}</TableCell>

                          <TableCell sx={{ width: isZimmerman === true ? 120 : 90 }}>
                            {j?.part_name}
                          </TableCell>
                          <TableCell onClick={e => showPartDescription(e, j)} sx={{ position: "relative" }}>
                            {showText && j?.bom_id === bomId ?
                              <Box>
                                <Typography sx={{ backgroundColor: "white", width: "450px", padding: "4px", boxShadow: "0 5px 12px rgb(0 0 0 / 0.4)", position: "absolute", zIndex: 10, top: 0 }}>{partDescriptionData}</Typography>
                              </Box>
                              :
                              <div className="textContainer">{j?.description}</div>}
                          </TableCell>
                          <TableCell>{j?.project_quantity_calc}</TableCell>
                          <TableCell>
                            {(Math.round(j?.distribution_progress * 100) / 100) > 0 && (Math.round(j?.distribution_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress * 100) / 100}%`}
                              </Typography>
                              : Math.round(j?.distribution_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress)}%`}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.distribution_progress)) ? " " : `${Math.round(j?.distribution_progress * 100) / 100}%`}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.distribution_progress * 100) / 100) > 0 && (Math.round(j?.distribution_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity * 100) / 100}
                              </Typography>
                              : Math.round(j?.distribution_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity)}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.distributed_quantity)) ? " " : Math.round(j?.distributed_quantity * 100) / 100}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.installed_progress * 100) / 100) > 0 && (Math.round(j?.installed_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress * 100) / 100}%`}
                              </Typography>
                              : Math.round(j?.installed_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress)}%`}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.installed_progress)) ? " " : `${Math.round(j?.installed_progress * 100) / 100}%`}
                                </Typography>}
                          </TableCell>
                          <TableCell>
                            {(Math.round(j?.installed_progress * 100) / 100) > 0 && (Math.round(j?.installed_progress * 100) / 100) < 100 ?
                              <Typography sx={{ backgroundColor: "#de7eb1" }}>
                                {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity * 100) / 100}
                              </Typography>
                              : Math.round(j?.installed_progress) === 100 ?
                                <Typography sx={{ backgroundColor: "#B4F8D7" }}>
                                  {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity)}
                                </Typography> :
                                <Typography >
                                  {isNaN(Math.round(j?.installed_quantity)) ? " " : Math.round(j?.installed_quantity * 100) / 100}
                                </Typography>}
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })}
                </TableBody>
              </Table>
            }

          </TableContainer>
          <Box sx={{ marginTop: "73px" }}></Box>
        </Box>
      </Modal>
    </Box >
  );
};

export default Dashboard;



import suryadb from "../indexedDB/module"

export const IS_DEBUG_MODE_ON = true

export const IS_QC_ENABLED_FLAG = async () => {
    let is_qc_enabled = false
    let is_project_type_zimmerman = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let data = await suryadb().project_details.get(projectId)
    data = data?.data
    if ( is_project_type_zimmerman === false && data?.project_settings && data?.project_settings?.qc === true) {
        is_qc_enabled = true
    }
    return is_qc_enabled
}
export const IS_PROJECT_TYPE_ZIMMERMAN_FLAG = async () => {
    let is_zimmeran = false
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let data = await suryadb().project_details.get(projectId)
    data = data?.data
    if (data?.type && parseInt(data?.type) === 1) {
        is_zimmeran = true
    }
    return is_zimmeran 
}


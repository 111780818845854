import { useAtom } from 'jotai'
import React from 'react'
import authAtom from '../../data/authAtom'
import { useNavigate } from 'react-router-dom'
import { Button } from '@mui/material'

const NotFound = () => {
    const [user] = useAtom(authAtom)
    const navigate = useNavigate()

  return (
    
    <div>
       { user.loggedIn ?<Button onClick={()=>navigate("/home/dashboard")}> Go Back to Dashboard!!!</Button>:<Button onClick={()=>navigate("/login")}>Go Back to Login!!!</Button>}
    </div>
  )
}

export default NotFound

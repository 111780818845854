import React, { useState, useEffect } from "react";
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Dashboard from './dashboard';
import { Typography } from '@mui/material';
import ConstructionProgress from './constructionProgress/constructionProgress';
import QcData from './qualityControl/qualityControl';
import { Link, useLocation, useParams } from "react-router-dom";
import GridViewOutlinedIcon from "@mui/icons-material/GridViewOutlined";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import GppGoodOutlinedIcon from "@mui/icons-material/GppGoodOutlined";
import { IS_QC_ENABLED_FLAG } from "../../featureFlags/featureFlags";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function ScrollableTabsButtonForce() {
    const [value, setValue] = useState(JSON.parse(localStorage.getItem("tab-index")));
    const [isQCEnabled, setIsQCEnabled] = useState(false)

    useEffect(() => {
        async function fetchQCEnabledFlag() {
            let is_qc_enabled = await IS_QC_ENABLED_FLAG()
            setIsQCEnabled(is_qc_enabled)
        }
        fetchQCEnabledFlag();
    }, [])


    const location = useLocation()
    const params = useParams()

    useEffect(() => {
        if (location.pathname === "/home/dashboard" || location.pathname === `/home/gpslocation/${params.id}/dashboard`) {
            localStorage.setItem("tab-index", 0)
            setValue(0)
        }

    }, [])
    const handleChange = (event, newValue) => {
        localStorage.setItem("tab-index", newValue)
        setValue(newValue);
    }
    return (
        <Box sx={{ maxWidth: { xs: 320, sm: 480 }, bgcolor: 'background.paper', }}>
            <Tabs
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                aria-label="scrollable force tabs example"
                indicatorColor={""}
                sx={{
                    height: "84px",
                    backgroundColor: "rgba(105, 111, 121, 0.04)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    textTransform: "none",

                }}

            >
                <Tab index={0} label={<div style={{ display: "flex", alignItems: "center" }}><GridViewOutlinedIcon sx={{ paddingRight: "10px", }} />Dashboard</div>} component={Link} to="dashboard"
                    style={{ color: value === 0 ? "white" : "#1B1819", backgroundColor: value === 0 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", marginRight: "2px" }} />
                <Tab label={<div style={{ display: "flex", alignItems: "center", textAlign: "start" }}><BarChartRoundedIcon sx={{ paddingRight: "10px", }} />Construction <br />Progress</div>} index={1}
                    // disabled={isQCEnabled === false ? false : true}
                    component={Link} to="constructionprogress"
                    style={{ color: value === 1 ? "white" : "#1B1819", backgroundColor: value === 1 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", }} />
                <Tab label={<div style={{ display: "flex", alignItems: "center", textAlign: "start", paddingRight: "10px" }}> <GppGoodOutlinedIcon sx={{ paddingRight: "10px", }} />Quality <br />Control</div>} index={2} component={Link} to="qcdata"
                    disabled={isQCEnabled === true ? false : true}
                    style={{ color: isQCEnabled !== true ? "#C7D0DD" : value === 2 ? "white" : "#1B1819", backgroundColor: value === 2 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", }} />

                {/* <Tab index={0} label={<div style={{ display: "flex", alignItems: "center" }}><GridViewOutlinedIcon sx={{ paddingRight: "10px", }} />Dashboard</div>} component={Link} to="dashboard" 
                style={{ color: value === 0 ? "white" : "#1B1819", backgroundColor: value === 0 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", marginRight: "2px" }} />
                <Tab label={<div style={{ display: "flex", alignItems: "center", textAlign: "start" }}><BarChartRoundedIcon sx={{ paddingRight: "10px", }} />Construction <br />Progress</div>} index={1} disabled={location.pathname === "/home/dashboard" ? true : false} component={Link} to="constructionprogress" 
                style={{ color: value === 1 ? "white" : location.pathname === "/home/dashboard" ? "#C7D0DD" : "#1B1819", backgroundColor: value === 1 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", }} />
                <Tab label={<div style={{ display: "flex", alignItems: "center", textAlign: "start", paddingRight: "10px" }}> <GppGoodOutlinedIcon sx={{ paddingRight: "10px", }} />Quality <br />Control</div>} index={2} component={Link} to="qcdata" disabled={location.pathname === "/home/dashboard" ? true : false} style={{ color: value === 2 ? "white" : location.pathname === "/home/dashboard" ? "#C7D0DD" : "#1B1819", backgroundColor: value === 2 && "#F16253", textTransform: "none", paddingX: "5px", fontSize: "16px", }} /> */}
            </Tabs>
            <CustomTabPanel value={value} index={0}  >
                <Dashboard />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <ConstructionProgress />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <QcData />
            </CustomTabPanel>

        </Box>
    );
}


import Dexie from "dexie";

const surya_commondb = () => {
    const db = new Dexie("surya_commondb");
    db.version(1.2).stores({
        images: `id,data`,
        imageids_queue:`id,data`,
    });

    return db;
};


export default surya_commondb;

import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Header from "../../components/appBar"
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Outlet, useNavigate } from "react-router-dom";
import ProfileSettingsNavBar from "./profileSettingsNavBar";

const ProfileSettings = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Header />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          backgroundColor: "#262223",
          color: "white",
        }}
      >
        <ArrowBackIcon
          sx={{ padding: " 0px 20px" }}
          onClick={() => {
            navigate("/home/dashboard");
          }}
        />
        <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
          Profile 
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={3}>
          <ProfileSettingsNavBar />
        </Grid>
        <Grid item xs={9}>
          <Outlet />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProfileSettings;

import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  Box,
  FormControlLabel,
  Typography,
} from "@mui/material";
import Checkbox, { checkboxClasses } from "@mui/material/Checkbox";
import React, { useEffect, useState } from 'react'
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import dashboardAtom, { fetchZimmermanTableCoordinatesAtom, getListOfPiersTorqueTubeIdsWithStatusAtom, getListOfZimmermanPierIdsWithStatusAtom, getSelectedPiersTorqueTubesDataAtom } from "../data/dashboardAtom";
import { useAtom } from "jotai";
import IndividualTrackerRow from "../pages/pierList/individualTrackerRow";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../featureFlags/featureFlags";
import ZimmerBlock from "../pages/pierList/individualTableZimmerman";
import { useParams } from "react-router-dom";

const ConstructionProgressPierTTSelection = ({ toggleValue, pierTorqueTubeData, selectedTrackerrow, activityId, constructionProgressTrackerrowWise, clearState }) => {

  const [dashboard] = useAtom(dashboardAtom)
  const [, getListOfPiersTorqueTubeIdsWithStatus] = useAtom(getListOfPiersTorqueTubeIdsWithStatusAtom)
  const [selectedPiersTorqueTubes, setSelectedPiersTorqueTubes] = useState({ piers: [], torque_tubes: [] })
  const [piersTorqueTubeIdsWithStatus, setPiersTorqueTubeIdsWithStatus] = useState({})
  const [checkPreviousStatus, setCheckPreviousStatus] = useState(false)
  const [checkPreviousStatusZimmerman, setCheckPreviousStatusZimmerman] = useState(false)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [selectedZimmermanPiers, setSelectedZimmermanPiers] = useState([])
  const [, fetchZimmermanTableCoordinates] = useAtom(fetchZimmermanTableCoordinatesAtom)
  const [, getListOfZimmermanPierIdsWithStatus] = useAtom(getListOfZimmermanPierIdsWithStatusAtom)
  const [selectedTableZimmermanTableCoordinates, setSelectedTableZimmermanTableCoordinates] = useState({})
  const [selectedZimmermanPiersWithStatus, setSelectedZimmermanPiersWithStatus] = useState([])
  const params = useParams()


  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])
  useEffect(() => {
    if (isZimmerman === true) {
      getZimmermanTableCoordinates()
    }
  }, [isZimmerman])

  const getZimmermanTableCoordinates = async () => {
    try {
      await fetchZimmermanTableCoordinates(parseInt(params.id))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (dashboard?.zimmermanTableCoordinates) {
      let tableId = selectedTrackerrow[0]?.split("-")[1]
      let selectedTableCoordinates = dashboard?.zimmermanTableCoordinates[tableId]
      setSelectedTableZimmermanTableCoordinates(selectedTableCoordinates)
    }

  }, [dashboard?.zimmermanTableCoordinates])

  useEffect(() => {
    getCompletedPiersAndTorqueTubes()
  }, [piersTorqueTubeIdsWithStatus])

  useEffect(() => {
    getZimmermanCompletedPiersWithStatus()
  }, [selectedZimmermanPiersWithStatus])

  const getZimmermanCompletedPiersWithStatus = async () => {
    try {
      await getListOfZimmermanPierIdsWithStatus(selectedZimmermanPiersWithStatus)
    } catch (err) {
      console.log(err)
    }
  }
  const getCompletedPiersAndTorqueTubes = async () => {
    try {
      await getListOfPiersTorqueTubeIdsWithStatus(piersTorqueTubeIdsWithStatus)
    } catch (err) {
      console.log(err)
    }
  }
  useEffect(() => {
    if (isZimmerman === false) {
      let PiersTorqueTubes = {}
      let piers = []
      let torqueTubes = []
      let pierIds = []
      let torqueTubeIds = []
      let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
        let pierData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]
        PiersTorqueTubes["piers"] = pierData
        for (let i of pierData) {
          if (i?.status === 1) {
            pierIds.push(i?.pier_id)
          }
        }
      }
      else {

        if (pierTorqueTubeData["piers"]) {
          for (let i of pierTorqueTubeData["piers"]) {
            piers.push({ pier_id: i?.id, status: 0 })

          }
          PiersTorqueTubes["piers"] = piers
        }
      }
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["torque_tubes"]?.length > 0) {
        let ttData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["torque_tubes"]
        PiersTorqueTubes["torque_tubes"] = ttData
        for (let j of ttData) {
          if (j?.status === 1) {
            torqueTubeIds.push(j?.torque_tube_id)
          }
        }
      } else {
        if (pierTorqueTubeData["torque_tube"]) {
          for (let i of pierTorqueTubeData["torque_tube"]) {
            torqueTubes.push({ torque_tube_id: i?.id, status: 0 })
          }
          PiersTorqueTubes["torque_tubes"] = torqueTubes
        }
      }
      setPiersTorqueTubeIdsWithStatus(PiersTorqueTubes)
      setSelectedPiersTorqueTubes({ piers: pierIds, torque_tubes: torqueTubeIds })
      setCheckPreviousStatus(true)
    }
  }, [isZimmerman])

  useEffect(() => {
    if (isZimmerman === true) {
      let piersWithStatus = []
      let pierIds = []
      let trackerrowId_activityId_key = `${selectedTrackerrow[0]?.split("-")[1]}_${parseInt(activityId)}`
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key] && constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]?.length > 0) {
        let pierData = constructionProgressTrackerrowWise[trackerrowId_activityId_key]["piers"]
        piersWithStatus = pierData
        for (let i of pierData) {
          if (i?.status === 1) {
            pierIds.push(i?.pier_id)
           
          }
        }
      }
      else {
        if (pierTorqueTubeData && pierTorqueTubeData.length !== 0) {
          for (let i of pierTorqueTubeData) {
            let tableId = parseInt(selectedTrackerrow[0].split("-")[1])
            if (i?.tracker_row_id === tableId) {
              piersWithStatus.push({ pier_id: i?.id, status: 0 })
            }
          }
        }
      }
      setSelectedZimmermanPiersWithStatus(piersWithStatus)
      setSelectedZimmermanPiers(pierIds)
      setCheckPreviousStatusZimmerman(true)
    }
  }, [isZimmerman])

  useEffect(() => {
    if (isZimmerman === false) {
      if (Object.keys(dashboard?.selectedPiersTorqueTubesData).length > 0 && (dashboard?.selectedPiersTorqueTubesData["piers"]|| dashboard?.selectedPiersTorqueTubesData["torque_tubes"])) {
        if ((piersTorqueTubeIdsWithStatus["piers"] && piersTorqueTubeIdsWithStatus["torque_tubes"]) && (piersTorqueTubeIdsWithStatus["piers"].length > 0 && piersTorqueTubeIdsWithStatus["torque_tubes"].length > 0)) {
          setSelectedPiersTorqueTubes(dashboard?.selectedPiersTorqueTubesData)
          handleSelectPierAndTorqueTubeItem()
          setCheckPreviousStatus(true)
        }
      }
    }
  }, [dashboard?.selectedPiersTorqueTubesData, isZimmerman])

  useEffect(() => {
    if (isZimmerman === true) {
      if (dashboard?.zimmermanSelectedPiersData) {
        if (selectedZimmermanPiersWithStatus && selectedZimmermanPiersWithStatus.length !== 0) {
          setSelectedZimmermanPiers(dashboard?.zimmermanSelectedPiersData)
          handleZimmermanSelectPierItem()
          setCheckPreviousStatusZimmerman(true)
        }
      }
    }
  }, [dashboard?.zimmermanSelectedPiersData, isZimmerman])

  const handleZimmermanSelectPierItem = async () => {
    let temp = []
    if (dashboard?.zimmermanSelectedPiersData) {
      let piersDataWithStatus = selectedZimmermanPiersWithStatus
      let selectedPierData = dashboard?.zimmermanSelectedPiersData
      let updatedPiers = []
      for (let i of piersDataWithStatus) {
        if (selectedPierData.includes(i?.pier_id)) {
          updatedPiers.push({ ...i, status: 1 })
        } else {
          updatedPiers.push({ ...i, status: 0 })
        }
      }
      temp = updatedPiers
    }

    setSelectedZimmermanPiersWithStatus(temp)

  }
  const handleSelectPierAndTorqueTubeItem = async () => {
    let temp = {}
    if (dashboard?.selectedPiersTorqueTubesData["piers"]) {
      let piersDataWithStatus = piersTorqueTubeIdsWithStatus["piers"]
      let selectedPierData = dashboard?.selectedPiersTorqueTubesData["piers"]
      let updatedPiers = []
      for (let i of piersDataWithStatus) {
        if (selectedPierData.includes(i?.pier_id)) {
          updatedPiers.push({ ...i, status: 1 })
        } else {
          updatedPiers.push({ ...i, status: 0 })
        }
      }
      temp["piers"] = updatedPiers
    }

    if (dashboard?.selectedPiersTorqueTubesData["torque_tubes"]) {
      let torqueTubesDataWithStatus = piersTorqueTubeIdsWithStatus["torque_tubes"]

      let selectedTorqueTubeData = dashboard?.selectedPiersTorqueTubesData["torque_tubes"]
      let updatedTorqueTubes = []
      for (let i of torqueTubesDataWithStatus) {
        if (selectedTorqueTubeData.includes(i?.torque_tube_id)) {
          updatedTorqueTubes.push({ ...i, status: 1 })
        } else {
          updatedTorqueTubes.push({ ...i, status: 0 })
        }
      }

      temp["torque_tubes"] = updatedTorqueTubes

    }
    setPiersTorqueTubeIdsWithStatus(temp)

  }

  return (
    <Box sx={{ width: "100vw", height: "500px" }}>
      {isZimmerman === false ?
        <Box>
          {isZimmerman === false && Object.keys(pierTorqueTubeData).length > 0 && dashboard?.pierLegends && selectedPiersTorqueTubes["piers"] && selectedPiersTorqueTubes["torque_tubes"] && checkPreviousStatus === true && pierTorqueTubeData?.torque_tube !== undefined && pierTorqueTubeData?.piers !== undefined ?
            <Box sx={{ marginTop: "50px" }} >
              <IndividualTrackerRow
                type="cp"
                data={pierTorqueTubeData}
                pierData={pierTorqueTubeData?.piers}
                torqueTubeData={pierTorqueTubeData?.torque_tube}
                pierLegend={dashboard?.pierLegends}
                selectedItem={selectedPiersTorqueTubes}
                // selectedTab={toggleValue === 1 ? "piers" : "torque_tube"}
                clearState={clearState}
              />
            </Box>
            : null}
        </Box>
        :
        <Box>
          {isZimmerman === true && pierTorqueTubeData.length !== 0 && dashboard?.pierLegends && selectedZimmermanPiers && checkPreviousStatusZimmerman === true && Object.keys(selectedTableZimmermanTableCoordinates).length !== 0 &&
            < Box sx={{ display: "flex", justifyContent: "center", }} >
              <ZimmerBlock
                data={pierTorqueTubeData}
                pierLegend={dashboard?.pierLegends}
                tableCoordinates={selectedTableZimmermanTableCoordinates}
                selectedItem={selectedZimmermanPiers}
                clearState={clearState}
                selectedTable={selectedTrackerrow}
                type="CP"
              />
            </Box>}
        </Box>}
    </Box >
  )
}

export default ConstructionProgressPierTTSelection

import suryadb from "../indexedDB/module";


export async function insert_activities(activity) {
        for (let i in activity) {
            await suryadb().get_all_activities.put({ id: i, data: activity[i] });
        }
}
export async function get_all_activities() {
    let activities = await suryadb().get_all_activities.toArray()
    let temp = {}
    if (activities) {
        for (let i of activities) {
            temp[i?.id] = i?.data
        }
    }
    return temp
}

const handleTimeFormater = (data) => {
    if (data) {
        // let temp = data?.split(",")[1]
        let date = data?.split(",")[0]
        let dateFormat = `${date.split("/")[1]}/${date.split("/")[0]}/${date.split("/")[2]}`
        let time = data?.split(",")[1]
        return `${dateFormat} ${time?.split(":")[0]}:${time?.split(":")[1]}`
    }
}
const handleTimeFormaterLastSync = (data) => {
    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    if (data) {
        let date = data?.split(",")[0]
        let dateFormat = `${date.split("/")[1]}-${months[(date.split("/")[0]) - 1]}-${date.split("/")[2]}`
        let time = data?.split(",")[1]
        return `${dateFormat} ${time?.split(":")[0]}:${time?.split(":")[1]} ${data.split(" ")[2]} `
    }
}


function handleGetTimeStamp(epochMillis, timeZone, format) {
    let date = new Date(epochMillis);
    if (timeZone) {
        let formatter = new Intl.DateTimeFormat('en-US', {
            timeZone: timeZone,
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            timeZoneName: 'short'
        });
        let formattedDate = formatter.format(date)
        if (format === "qc") {
             formattedDate = handleTimeFormater(formattedDate)
            //  formattedDate = handleTimeFormaterLastSync(formattedDate)
            return formattedDate;
        }
        if (format === "lastsync") {
            formattedDate = handleTimeFormaterLastSync(formattedDate)
            return formattedDate;
        }
    }
}




export default handleGetTimeStamp

import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import LoginContainer from "../../components/login/loginContainer";
import { HiOutlineArrowUpOnSquare } from "react-icons/hi2";
import { AiOutlineApple } from "react-icons/ai";
import { FcAndroidOs } from "react-icons/fc";
import {BsThreeDotsVertical} from  "react-icons/bs";

export default function Install() {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  const install = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        height: "100vh",
        alignItems: "start",
        justifyContent: "start",
        marginTop: "50px"
      }}
    >
      {/* <LoginContainer /> */}
      {/* <Typography variant="h6" sx={{ marginBottom: "10px" }}>{supportsPWA ? "Install the app" : "Device not supported"}</Typography>
            <Button disabled={!supportsPWA} variant="contained" onClick={install}>Install</Button> */}
      {/* <Typography sx={{ fontSize: "24px", fontWeight: "bold" }}>
        Instructions
      </Typography> */}
      <Grid container>
        <Grid item sm={6}>
          <div style={{ fontSize: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <AiOutlineApple size={60} color="grey" />
              <Typography sx={{ fontSize: "40px", }}>iOS</Typography>
            </Box>
            <ul style={{ margin: "20px 10px 20px 50px ", }}>
              <li style={{ padding: "10px 0px" }}>
                Open Safari
              </li>
              <li style={{ padding: "10px 0px" }}>
                Tap the share icon located in the top right corner <HiOutlineArrowUpOnSquare fontSize={28} color="black" style={{ marginBottom: "-4px" }} />
              </li>
              <li style={{ padding: "10px 0px" }}>
                Scroll to find <span style={{ fontWeight: "bold" }}>"Add to Home Screen." </span>{" "}, tap Add
              </li>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding:"10px 0px"
                }}
              >
              <img
                src="/addhomescreen.jpeg"
                alt="addhomescreen"
                width="250"
                height="400"
              />
            </Box>
          </ul>
        </div>
      </Grid>
      <Grid item sm={6}>
        
        <div style={{ fontSize: "20px" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
              <FcAndroidOs size={60} color="grey" />
              <Typography sx={{ fontSize: "40px", }}>Android</Typography>
            </Box>
            <ul style={{ margin: "20px 50px", }}>
              <li style={{ padding: "10px 0px" }}>
                Open Google Chrome
              </li>
              <li style={{ padding: "10px 0px" }}>
                Tap the options button - three vertical dots icon located in the top right corner  <BsThreeDotsVertical fontSize={28} color="black" style={{ marginBottom: "-4px" }} />
              </li>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding:"10px 0px"
                }}
              >
              <img
                src="/threedotsimg.jpg"
                alt="threedotsimg"
                width="300"
                height="50"
              />
            </Box>
              <li style={{ padding: "10px 0px" }}>
                Scroll to find  <span style={{ fontWeight: "bold" }}>"Install App" </span>{" "}, tap to Add to home screen.
              </li>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  // padding:"10px 0px"
                }}
              >
              <img
                src="/installappimg.jpg"
                alt="installappimg"
                width="200"
                height="450"
              />
            </Box>
          </ul>
        </div>
      </Grid>
    </Grid>

    </Box >
  );
}

import axios from "axios";
import produce from "immer";
import { insert_activity_milestone_for_block, insert_activity_milestone_for_project, insert_construction_progress_activitywise_for_blocks, insert_construction_progress_activitywise_for_project, insert_construction_progress_tracker_row_wise, insert_deployed_part_for_activity, insert_deployed_part_for_overall_project_block, insert_checklist_activity_construction, insert_distributed_and_installed_bom_for_project, insert_existing_distributed_installed_quantity_for_trackerrow, insert_part_per_tracker_type, insert_parts_name, insert_total_installed_distributed_quantity, update_construction_progress, get_table_wise_pier_part_bom, insert_table_wise_pier_part_bom } from "../dal/constructionProgress";
import { insert_activities } from "../dal/activities";
import { insert_blocks, insert_pier_legend_details, insert_svg_block_progress_activity_wise, insert_svg_map, insert_bulk_piers_torquetubes, insert_d3_xscale_yscale_blockwise, insert_zimmerman_table_coordinates, insert_zimmerman_bulk_piers } from "../dal/layout";
import { insert_tracker_types_for_block, insert_tracker_types_for_project, insert_tracker_types_name } from "../dal/trackerTypes";
import { insert_qc_bulk_checklist_data, insert_qc_progress_qc_punchlist, update_quality_control } from "../dal/qualityCheckProgress";
import { fetchImages } from "../dal/images";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG, IS_QC_ENABLED_FLAG } from "../featureFlags/featureFlags";


export const getData = async (updateSyncStatus) => {
  let is_qc_enabled = await IS_QC_ENABLED_FLAG()
  let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
  let startTime = Date.now()
  //fetching data
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 1 }));

  const bulk_data_req = await axios.get("/tablet/get_all_data")
  const bulk_data = bulk_data_req.data
  // construction
  let cpdata = bulk_data.get_project_overall_progress.construction
  await insert_construction_progress_activitywise_for_project(cpdata)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 2 }));

  if (is_zimmeran === true) {
    await insert_zimmerman_bulk_piers()

  } else {
    await insert_bulk_piers_torquetubes()
  }
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 3 }));

  //tracker type id
  let trackerTypeData = bulk_data.get_count_tracker_type_wise_row_for_project
  await insert_tracker_types_for_project(trackerTypeData)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 4 }));

  // dashboard construction and qc progress names
  let activities = bulk_data.fetch_all_activities_group_act_id_for_project
  await insert_activities(activities)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 5 }));

  let blockids = bulk_data.tracker_row_blocks;
  await insert_blocks(blockids)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 6 }));


  let cpblock = bulk_data.get_progress_activity_wise.construction
  await insert_construction_progress_activitywise_for_blocks(cpblock)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 7 }));


  await insert_d3_xscale_yscale_blockwise(blockids)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 8 }));


  let trackerTypeBlockData = bulk_data.get_count_tracker_type_wise_row_for_block
  await insert_tracker_types_for_block(trackerTypeBlockData)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 9 }));

  //overall linked part for project and block

  let overall_deployed_part_project = bulk_data.get_deployed_part_for_project_overall
  let overall_deployed_part_block = bulk_data.get_deployed_part_for_project_for_block
  await insert_deployed_part_for_overall_project_block(overall_deployed_part_project, overall_deployed_part_block, blockids)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 10 }));

  let activityMilestoneBlock = bulk_data.get_all_activity_milestone_for_project
  await insert_activity_milestone_for_block(activityMilestoneBlock)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 11 }));

  let activityMilestoneProject = bulk_data.get_project_wide_activity_milestone
  await insert_activity_milestone_for_project(activityMilestoneProject)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 12 }));

  // parts details
  await insert_parts_name()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 13 }));

  //svg file
  await insert_svg_map()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 14 }));

  // cp distributed_and_installed_bom_for_project
  await insert_distributed_and_installed_bom_for_project()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 15 }));

  //cp total installed or distributed per bom id and activity id

  await insert_total_installed_distributed_quantity()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 16 }));

  // existing_distributed_installed_quantity_for_trackerrow
  await insert_existing_distributed_installed_quantity_for_trackerrow()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 17 }));

  // part per tracker type
  await insert_part_per_tracker_type()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 18 }));

  // pier legend details
  await insert_pier_legend_details()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 19 }));

  let cp_progress_tracker_row_wise = bulk_data?.get_construction_progress_tracker_row_wise_block.construction
  await insert_construction_progress_tracker_row_wise(cp_progress_tracker_row_wise)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 20 }));

  if (is_qc_enabled === true) {
    await insert_qc_progress_qc_punchlist(bulk_data?.get_qc_pier_and_tt_revamp_progress)
  }
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 21 }));

  if (is_qc_enabled === true) {
    await fetchImages(updateSyncStatus)
  }
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 22 }));


  //tracker type names
  if (is_zimmeran === true) {
    await insert_zimmerman_table_coordinates(blockids)
  }
  await insert_tracker_types_name()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 23 }));

  // checklist activity construction

  await insert_checklist_activity_construction()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 24 }));


  //deployed part for activity

  let getDeployedPartForActivity = bulk_data?.get_deployed_part_for_activity_in_block
  await insert_deployed_part_for_activity(getDeployedPartForActivity)
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 25 }));

  // svg block progress activity wise
  await insert_svg_block_progress_activity_wise()
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 26 }));

  if (is_qc_enabled === true) {
    await insert_qc_bulk_checklist_data()
  }
  updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 27 }));

  if (is_zimmeran === true) {
    await insert_table_wise_pier_part_bom()
    updateSyncStatus(produce((draft) => { draft.status = "Getting data"; draft.value = 28 }));
  }


  let endTime = Date.now()
  let totalTime = endTime - startTime
  console.log("initial get data", (totalTime / 1000), "s")
  localStorage.setItem("last-sync", Date.now())
  localStorage.setItem("get-data-initially", true)
  localStorage.setItem("updating_getting_data", false)
};

let pidsWithSyncInitiateValue = {}

export const updateCpData = async () => {
  let updatecp = await update_construction_progress()
  for (let i of updatecp) {
    pidsWithSyncInitiateValue[i] = 0
  }
  return updatecp
};

export const updateQcData = async () => {
  let updateqc = await update_quality_control()
  for (let i of updateqc) {
    pidsWithSyncInitiateValue[i] = 0
  }
  return updateqc
}
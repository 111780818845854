import suryadb from "../indexedDB/module";
import axios from "axios";

export async function insert_project_details() {
    if (!localStorage.getItem("get-data-initially")) {
        let projectList = await axios.get(`/project/get_projects`);
        let projectListData = projectList?.data
        for (let i of projectListData) {
            await suryadb().project_details.put({ id: i?.id, data: i })
        }
        return projectListData
    }
}
export async function get_project_details() {
    let projectList = await suryadb().project_details.toArray();
    return projectList
}
import { Box, CircularProgress, TextField, Typography, Button } from "@mui/material";
import { useAtom } from "jotai";
import React, { useState, useRef, useEffect } from "react";
import projectAtom, { fetchProjectAtom } from "../data/projectAtom";
import authAtom from "../data/authAtom";
import LoginContainer from "../components/login/loginContainer";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";


function ProjectProvider(props) {
  const [loading, setLoading] = useState(false);
  const [projectId, setProjectId] = useState(null);
  const [projects] = useAtom(projectAtom);
  const [user] = useAtom(authAtom);
  const [, fetchProject] = useAtom(fetchProjectAtom);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");
  const [filterProject, setFilterProject] = useState();
  const dropdownRef = useRef(null);
  const [refresh, setRefresh] = useState(false)
  // const [showLoader, setShowLoader] = useState(false)

  useEffect(() => {
    init();
  }, [refresh]);

  const init = async () => {
    try {
      setLoading(true);
      if (navigator.onLine && user?.loggedIn) {
        await fetchProject();
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };
  const handleClick = async () => {
    localStorage.setItem("project_id", selectedItem);
    try {
      // To be removed cache reload api for temporary purpose
      // setShowLoader(true)
      // let org_id = JSON.parse(localStorage.getItem("org-id"))
      // let cache_reload_res = await axios.get(`/admin/cold_reload_project_cache?project_id=${selectedItem}&org_id=${org_id}`)
      window.location.reload();
    } catch (err) {
      console.log(err);
      // setShowLoader(false)
    }
  };


  useEffect(() => {
    if (localStorage.getItem("project_id")) {
      setProjectId(localStorage.getItem("project_id"));
    }
  }, [projects.projectDetails]);

  useEffect(() => {
    setFilterProject(projects.projectDetails);
  }, [projects.projectDetails]);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleFocus = () => {
    setIsOpen(!isOpen);
  };
  const formateValue = () => {

    let temp = "Select a Project";
    if (localStorage.getItem("project_id")) {
      temp = `${projects?.projectDetails?.find(
        (i) => i?.id === parseInt(localStorage.getItem("project_id"))
      )?.name
        }`;
    }
    projects?.projectDetails?.map((i) => {
      if (i?.id === selectedItem) {
        temp = `${i?.name}`;
      }
    });
    return temp;
  };
  const handleOnClick = (id) => {
    setSelectedItem(id);
    setIsOpen(false);
  };

  const handleSearch = (e) => {
    let quaryFilter = e.target.value;
    var updateList = [...projects.projectDetails];
    updateList = [...projects.projectDetails].filter(
      (item) =>
        `${item.project_number}_${item.name}`
          .toLowerCase()
          .indexOf(quaryFilter.toLowerCase()) !== -1
    );
    setFilterProject(updateList);
  };
  const handleRefresh = () => {
    setRefresh(!refresh)
  }
  const renderValue = (i) => {
    const { id, name } = i;
    const isActive = selectedItem;

    return (
      <li
        key={id}
        onClick={() => {
          handleOnClick(id);
        }}
        style={{
          textTransform: "capitalize",
          listStyleType: "none",
          backgroundColor: isActive === i?.id ? "#F48276" : "white",
          color: isActive === i?.id ? "white" : "black",
          padding: "3px",
          cursor: "pointer",
          "&:hover": { backgroundColor: "#F48276" },
        }}
      >
        <span>
          <p style={{ padding: "0px 10px", }}>
            {i?.project_number}_{name}
          </p>
        </span>
      </li>
    );
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100vw",
          height: "100vh",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="h6" sx={{ marginBottom: "10px" }}>
          Fetching Projects
        </Typography>
        <CircularProgress />
      </Box>
    );
  }

  if (!projectId && user.loggedIn) {
    return (
      <Box
        sx={{
          height: "100vh",
          display: "flex",
          flexDirection: "cloumn",
          alignItems: "center",
          justifyContent: "center",
          position: "relative"
        }}
      >
        <LoginContainer />

        <Box>
          <img src="../../Logo.jpg" alt="logo" />
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "30ch" },
              display: "flex",
              flexDirection: "column",
              paddingTop: "15px",
            }}
          >
            <Box
              sx={{ position: "relative", display: "flex" }}
              ref={dropdownRef}
            >
              <Box>
                <Box sx={{ cursor: "pointer" }}>
                  <TextField
                    type="text"
                    style={{
                      width: "250px",
                      paddingLeft: "10px",
                      margin: "1.5px",
                    }}
                    placeholder="Select a Project"
                    onClick={handleFocus}
                    value={formateValue()}
                    readOnly
                  />

                </Box>

                {isOpen && (
                  <div
                    style={{
                      position: "absolute",
                      zIndex: 10,
                      backgroundColor: "white",
                      border: "1px solid grey",
                      width: "250px",
                      marginLeft: "10px",
                    }}
                  >
                    <ul
                      style={{
                        overflowY: "auto",
                        maxHeight: "250px",
                        padding: "0px",
                      }}
                    >
                      <TextField
                        type="search"
                        placeholder="Search Project"
                        style={{ width: "200px", paddingLeft: "14px" }}
                        onChange={handleSearch}
                      />
                      {filterProject.map((i, index) => renderValue(i, index))}
                    </ul>
                  </div>
                )}
              </Box>
              {isOpen ? (
                <BsChevronUp
                  onClick={handleFocus}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: 20,
                    right: 20,
                  }}

                />
              ) : (
                <BsChevronDown
                  onClick={handleFocus}
                  style={{
                    cursor: "pointer",
                    position: "absolute",
                    top: 20,
                    right: 20,
                  }}

                />
              )}
            </Box>
            <Button
              variant="contained"
              sx={{ margin: "15px 10px 10px 10px", textTransform: "none" }}
              onClick={handleClick}
              disabled={selectedItem !== "" ? false : true}
            >
              Select
            </Button>
            {/* <Button
              variant="contained"
              sx={{ margin: "30px 10px 10px 10px", textTransform: "none" }}
              onClick={handleClick}
            >
              {showLoader ?
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <CircularProgress size={20} style={{ color: "white" }} />
                  <Typography sx={{ marginX: "10px" }}>Select</Typography>
                </Box>
                : "Select"}
            </Button> */}
          </Box>
          <Box sx={{ textAlign: "center", paddingY: "5px", cursor: "pointer" }}>
            <Typography sx={{ textDecoration: "underline", color: "blue", fontSize: "14px" }} onClick={handleRefresh} >Refresh</Typography>
          </Box>
        </Box>
      </Box>

    );
  }
  if (navigator.onLine) {
    if (projects.projectDetails?.length < 1 && user.loggedIn) {
      return (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100vw",
            height: "100vh",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h6">No Projects Available</Typography>
        </Box>
      );
    }
  }

  return <div>{props.children}</div>;
}

export default ProjectProvider;


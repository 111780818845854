import { atom } from "jotai";
import { get_user_details, reset_password_details } from "../dal/login";

const authAtom = atom({
    loggedIn: false,
});

export const loginAtom = atom(null, (get, set, args) => {
    set(authAtom, { loggedIn: args })
})

export default authAtom;
import React, { useEffect, useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import {
  Button,
  Divider,
  Drawer,
  Modal,
  Box,
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { NavLink } from "react-router-dom";
import Cookies from "js-cookie";
import syncStatusAtom from "../data/syncStatusAtom";
import { useAtom } from "jotai";
import projectAtom, { fetchProjectDetailsAtom } from "../data/projectAtom";
import PersonIcon from "@mui/icons-material/Person";
import { ToastContainer, toast } from "react-toastify";
import appBarAtom, { updateBlockUpdateValue, updateLatestSyncMessage, updateSyncImages } from "../data/appBarAtom";
import suryadb from "../indexedDB/module";
import handleGetTimeStamp from "../utils/epocTimeConvertor";
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import dashboardAtom, { fetchGPSLocationToggleStatusAtom } from "../data/dashboardAtom";
import { backgroundFetchImages } from "../dal/images";
import { FaSyncAlt } from "react-icons/fa";
import { insert_qc_bulk_checklist_data, insert_qc_d3_color } from "../dal/qualityCheckProgress";
import { insert_checklist_activity_construction } from "../dal/constructionProgress";
import { isOnline } from "../dal/checkIsOnline";
import { IoEyeSharp } from "react-icons/io5";
import { IS_QC_ENABLED_FLAG } from "../featureFlags/featureFlags";
import axios from "axios";


const navstyle = ({ isActive }) => {
  return {
    background: isActive && "#F16253",
    color: isActive ? "white" : "#1B1819",
    display: "flex",
    justifyContent: "start",
    alignItems: "center",
    textDecoration: "none",
    height: "100px",
  };
};

const Header = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [openlogoutModal, setOpenlogoutModal] = useState(false);
  const [, updateSync] = useAtom(syncStatusAtom);
  const [projectDetails] = useAtom(projectAtom);
  const [, fetchProjectDetails] = useAtom(fetchProjectDetailsAtom);
  const [openSyncModal, setOpenSyncModal] = useState(false);
  const [appBarValue] = useAtom(appBarAtom)
  const [, latestSyncMessage] = useAtom(updateLatestSyncMessage)
  const [, blockUpdated] = useAtom(updateBlockUpdateValue)
  const [, syncImages] = useAtom(updateSyncImages)
  const [dashboard] = useAtom(dashboardAtom)
  const [, fetchGPSLocationToggleStatus] = useAtom(fetchGPSLocationToggleStatusAtom)
  const [OpenImagesDownloadModal, setOpenImagesDownloadModal] = useState(false)
  const [qcLoading, setQCLoading] = useState(false)
  const [qcD3Loading, setQCD3Loading] = useState(false)
  const [cpLoading, setCPLoading] = useState(false)
  const [isQCEnabled, setIsQCEnabled] = useState(false)


  // const [totalImagesCount, setTotalImagesCount] = useState(0)
  // const [downloadedImagesCount, setDownloadedImagesCount] = useState(0)

  const handleLogoutOpenModal = () => setOpenlogoutModal(true);
  const handleLogoutCloseModal = () => setOpenlogoutModal(false);
  const handleSyncCloseModal = () => {
    setOpenSyncModal(false)
  }

  const handleOpenImagesDownloadModal = () => {
    setOpenImagesDownloadModal(true)
  }
  const handleCloseImagesDownloadModal = () => {
    setOpenImagesDownloadModal(false)
  }

  useEffect(() => {
    async function fetchQCEnabledFlag() {
      let is_qc_enabled = await IS_QC_ENABLED_FLAG()
      setIsQCEnabled(is_qc_enabled)
    }
    fetchQCEnabledFlag();
  }, [])

  const handleLogout = () => {
    localStorage.clear()
    Cookies.remove("access_token");
    Cookies.remove("refresh_token");
    indexedDB.deleteDatabase("suryadb");
    window.location.href = "/login";
  };

  useEffect(() => {
    init();

  }, []);
  const init = async () => {
    await fetchProjectDetails();

  };

  const projectName = () => {
    let temp = "";
    let pro_id = localStorage.getItem("project_id");
    if (projectDetails?.projectDetailsDb) {
      for (let i of projectDetails.projectDetailsDb) {
        if (i?.id === parseInt(pro_id)) {
          temp = i?.data
        }
      }
    }
    return temp;
  };



  const handleChangeGPSLocation = async () => {
    try {
      await fetchGPSLocationToggleStatus(!dashboard?.isGPSLocationEnabled)
    } catch (err) {
      console.log(err)
    }
  }


  const handleSyncNow = async () => {
    isOnline(
      function () {
        setOpenSyncModal(true)
      },
      async function () {
        let updatecp = await suryadb().update_construction_progress.toArray()
        let updateqc = await suryadb().update_qc.toArray()

        if (appBarValue?.blockUpdatedValue === false && updatecp.length === 0 && updateqc.length === 0) {
          updateSync({
            syncing: true,
            status: "Syncing",
            block_name: "All",
            value: 0,
            syncInitiated: false,
            sync_mode: "Both",
            is_background: false,
            sync_text: "Getting Data",
            totalImages: 0,
            downloadedImages: 0
          });

          await latestSyncMessage({ value: false })
          await blockUpdated({ value: false })
          await syncImages({ value: true })
          localStorage.setItem("last-sync", Date.now())
          localStorage.removeItem("updated-blocks")
        }

        let updateType = ""

        if (updatecp.length > 0) {
          updateType = "construction"
        }

        if (updateqc.length > 0) {

          if (updateType === "construction") {
            updateType = "updateBoth"
          } else {
            updateType = "qc"
          }
        }
        if (updateType) {
          localStorage.setItem("updating_getting_data", true)
          updateSync({
            syncing: true,
            status: "Syncing",
            block_name: "All",
            value: 0,
            syncInitiated: true,
            sync_mode: updateType,
            is_background: false,
            sync_text: "Syncing",
            totalImages: 0,
            downloadedImages: 0
          });
          await latestSyncMessage({ value: true })
          // await blockUpdated({ value: true })
          await syncImages({ value: false })

        }
      }
    );

    // if (navigator.onLine) {
    //   let updatecp = await suryadb().update_construction_progress.toArray()
    //   let updateqc = await suryadb().update_qc.toArray()

    //   if (appBarValue?.blockUpdatedValue === false && updatecp.length === 0 && updateqc.length === 0) {
    //     updateSync({
    //       syncing: true,
    //       status: "Syncing",
    //       block_name: "All",
    //       value: 0,
    //       syncInitiated: false,
    //       sync_mode: "Both",
    //       is_background: false,
    //       sync_text: "Getting Data"
    // totalImages:0,
    // downloadedImages:0
    //     });

    //     await latestSyncMessage({ value: false })
    //     await blockUpdated({ value: false })
    //     await syncImages({ value: true })
    //     localStorage.setItem("last-sync", Date.now())
    //     localStorage.removeItem("updated-blocks")
    //   }

    //   let updateType = ""

    //   if (updatecp.length > 0) {
    //     updateType = "construction"
    //   }

    //   if (updateqc.length > 0) {

    //     if (updateType === "construction") {
    //       updateType = "updateBoth"
    //     } else {
    //       updateType = "qc"
    //     }
    //   }
    //   if (updateType) {
    //     localStorage.setItem("updating_getting_data", true)
    //     updateSync({
    //       syncing: true,
    //       status: "Syncing",
    //       block_name: "All",
    //       value: 0,
    //       syncInitiated: true,
    //       sync_mode: updateType,
    //       is_background: false,
    //       sync_text: "Syncing",
    // totalImages:0,
    // downloadedImages:0
    //     });
    //     await latestSyncMessage({ value: true })
    //     // await blockUpdated({ value: true })
    //     await syncImages({ value: false })

    //   }

    // } else {
    //   setOpenSyncModal(true)
    // }
  }
  const handleRefresh = async () => {
    let project_id = JSON.parse(localStorage.getItem("project_id"))
    let reloadCache = await axios.get(`/project/cold_cache_reload_for_block_id?project_id=${ project_id}`)
    console.log(reloadCache)

    updateSync({
      syncing: true,
      status: "Syncing",
      block_name: "All",
      value: 0,
      sync_mode: "Both",
      is_background: false,
      sync_text: "Getting Data",
      totalImages: 0,
      downloadedImages: 0
    });
    await latestSyncMessage({ value: false })
    await blockUpdated({ value: false })
    await syncImages({ value: true })
    localStorage.setItem("last-sync", Date.now())
    localStorage.removeItem("updated-blocks")

  }

  const handleBackgroundDownloadImages = async () => {
    let backgroundFetch = await backgroundFetchImages()
  };

  const handleFetchQCChecklist = async () => {
    setQCLoading(true)
    localStorage.removeItem("get-data-initially")
    try {
      await insert_qc_bulk_checklist_data()
      setQCLoading(false)
      toast.success("Fetched QC Checklist Successfully")
    } catch (err) {
      console.log(err)
    }
  }

  const handleFetchCPChecklist = async () => {
    setCPLoading(true)
    localStorage.removeItem("get-data-initially")
    try {
      await insert_checklist_activity_construction()
      setCPLoading(false)
      toast.success("Fetched CP Checklist Successfully")
    } catch (err) {
      console.log(err)
    }
  }

  const handleFetchQCD3Color = async () => {
    setQCD3Loading(true)
    localStorage.removeItem("get-data-initially")
    try {
      await insert_qc_d3_color("all")
      setQCD3Loading(false)
      toast.success("Fetched QC D3 Color Successfully")
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        position="static"
        sx={{ backgroundColor: "black", color: "white", width: "100vw" }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <MenuIcon
              onClick={() => {
                props.menuDisable === true
                  ? setIsDrawerOpen(false)
                  : setIsDrawerOpen(true);
              }}
              sx={{ color: props.menuDisable === true ? "#696F79" : "white" }}
            />
          </IconButton>
          <Drawer
            anchor="left"
            open={isDrawerOpen}
            onClose={(_, reason) =>
              reason === "backdropClick" && setIsDrawerOpen(false)
            }
          >
            <Box p={2} width="200px">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  paddingBottom: "20px",
                }}
              >
                <Typography variant="h6" component="div">
                  Menu
                </Typography>
                <CloseIcon onClick={() => setIsDrawerOpen(false)} />
              </Box>
              <NavLink to="profile/profiledetails" style={navstyle}>
                <Box
                  sx={{
                    display: "flex",
                    padding: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}
                >
                  <PersonIcon sx={{ fontSize: "26px" }} />
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: "10px", fontSize: "16px" }}
                  >
                    Profile
                  </Typography>
                </Box>
              </NavLink>

              <Divider />
             
                <Box
                  sx={{
                    display: "flex",
                    padding: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}

                  onClick={handleFetchCPChecklist}
                >{cpLoading === true ?
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                  : <FaSyncAlt fontSize={20} />}
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: "10px", fontSize: "16px" }}
                  >{
                      cpLoading === true ? "Fetching CP Checklist" : "Fetch CP Checklist"
                    }
                  </Typography>
                </Box>
                <Divider />
                {isQCEnabled === true && <Box>
                <Box
                  sx={{
                    display: "flex",
                    padding: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer",

                  }}

                  onClick={handleFetchQCChecklist}
                >{qcLoading === true ?
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                  : <FaSyncAlt fontSize={20} />}
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: "10px", fontSize: "16px" }}
                  >{
                      qcLoading === true ? "Fetching QC Checklist" : "Fetch QC Checklist"
                    }
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    padding: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}

                  onClick={handleFetchQCD3Color}
                >{qcD3Loading === true ?
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "25px",
                      height: "25px",
                    }}
                  >
                    <CircularProgress size={24} />
                  </Box>
                  : <FaSyncAlt fontSize={20} />}
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: "10px", fontSize: "16px" }}
                  >{
                      qcD3Loading === true ? "Fetching QC Punchlist Map" : "Fetch QC Punchlist Map"
                    }
                  </Typography>
                </Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    padding: "14px",
                    alignItems: "center",
                    justifyContent: "center",
                    cursor: "pointer"
                  }}

                // onClick={handleOpenImagesDownloadModal}
                >
                  <IoEyeSharp fontSize={26} />
                  <Typography
                    variant="h6"
                    sx={{ paddingLeft: "10px", fontSize: "16px" }}
                  >
                    Image Sync Status
                  </Typography>
                </Box>
                <Divider />
              </Box>}
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "10px 0px 10px 16px" }}>
                <Typography sx={{ fontWeight: "500" }}>Enable <br />GPS</Typography>
                <FormControlLabel control={<Switch checked={dashboard?.isGPSLocationEnabled} onChange={handleChangeGPSLocation} />} />
              </Box>
              {/* <Box
                sx={{ padding: "10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}
              >

                <Button onClick={handleBackgroundDownloadImages} sx={{ textTransform: "none", textAlign: "left", fontSize: "16px" }}>Download Images</Button>
                
              </Box> */}

              <Box
                sx={{ padding: "10px", display: "flex", items: "center" }}
              >
                <Button
                  onClick={handleLogoutOpenModal}
                  sx={{ textTransform: "none", fontSize: "18px" }}
                >
                  Log out
                </Button>
              </Box>
            </Box>
          </Drawer>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100vw",
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>

                {`${projectName().name}${"  "}-${"  "} ${projectName().project_size}`} MW
              </Typography>
              <Box
                sx={{ paddingLeft: "40px", textAlign: "left", display: "flex", alignItems: "center" }}
              >
                <Box >
                  <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                    Last Sync
                  </Typography>
                  <Typography sx={{ fontSize: "15px" }}>

                    {handleGetTimeStamp(parseInt(localStorage.getItem("last-sync")), projectName().timezone, "lastsync")}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "15px",
                  }}
                >
                  <img src="/syncbutton.svg" alt="btn" height="30" width="30" />

                  <Button
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      cursor: "pointer"
                    }}
                    onClick={handleSyncNow}

                  >
                    <Typography>Sync Now</Typography>
                  </Button>
                </Box>
                <Box>
                  <Button
                    sx={{
                      textTransform: "none",
                      fontSize: "16px",
                      cursor: "pointer"
                    }}
                    onClick={handleRefresh}
                  >
                    <Typography> Refresh</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box>
              <img src="/suryalogo.png" alt="logo" />
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      {/* modal */}
      <Modal
        open={openlogoutModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "30%",
            backgroundColor: "#FFFFFF",
            color: "black",
            borderRadius: "20px",
            padding: "20px 10px 10px 10px"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
            }}
          >
            <Typography sx={{ fontSize: "18px" }}>
              Are you sure you want to logout
            </Typography>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CloseIcon
                sx={{ padding: "10px 0  10px 15px" }}
                onClick={handleLogoutCloseModal}
              />
            </Box> */}
          </Box>

          <Box
            sx={{
              padding: "15px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button variant="contained" sx={{ textTransform: "capitalize" }} onClick={handleLogout}>
              Logout
            </Button>
            <Button
              sx={{ marginLeft: "10px", textTransform: "capitalize" }}
              onClick={handleLogoutCloseModal}
              variant="contained"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={openSyncModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              paddingLeft: "10px",
            }}
          >

            <CloseIcon
              sx={{ padding: "10px 10px" }}
              onClick={handleSyncCloseModal}
            />
          </Box>
          <Box sx={{ padding: "5px 20px" }}>
            <Typography sx={{ fontSize: "18px" }}>
              Please connect the device to the internet and try again
            </Typography>
          </Box>


          <Box
            sx={{
              padding: "15px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >

            <Button
              sx={{ border: "none", marginLeft: "10px", color: "white" }}
              onClick={handleSyncCloseModal}
              variant="contained"
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>

      <Modal
        open={OpenImagesDownloadModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "40%",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "end",
              paddingLeft: "10px",
            }}
          >

            <CloseIcon
              sx={{ padding: "10px 10px" }}
              onClick={handleCloseImagesDownloadModal}
            />
          </Box>
          <Box sx={{ padding: "5px 20px" }}>
            <Typography sx={{ fontSize: "18px" }}>
              Images download pop up
            </Typography>
          </Box>
          <Box
            sx={{
              padding: "15px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >

            <Button
              sx={{ border: "none", marginLeft: "10px", color: "white" }}
              onClick={handleSyncCloseModal}
              variant="contained"
            >
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "440px" }}
      />
    </Box>
  );
};

export default Header;
import { atom } from "jotai";
import { delete_qc_punchlist, update_qc } from "../dal/qualityCheckProgress";
import { offline_add_images } from "../dal/images";


const qcQueueAtom = atom();

export const insertQcQueueAtom = atom(null, async (get, set, args) => {
  // if (!args?.comments && parseInt(args?.status) === 2) return
  await update_qc(args)
});
export const insertImagesQueueAtom = atom(null, async (get, set, args) => {
  let data = await offline_add_images(args?.blockId,args.visibility,args?.imageObj)
})
export const deletePunchlistAtom = atom(null, async (get, set, args) => {
  let data = await delete_qc_punchlist(args?.visibility, args?.punchlistKey, args?.status,args?.qc_type)

});

export default qcQueueAtom;

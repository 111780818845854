import { atom } from "jotai";
import produce from "immer";
import { get_deployed_part_for_activitywise_project_block, get_deployed_part_for_overall_project_block } from "../dal/constructionProgress";

const deployedPartAtom = atom({
    deployedPartForTrackersOverall :[],
    deployedPartForTrackersActBlock: [],
    getDeployedPartForActivity: [],
    getDeployedPartForOverall: [],
});

// export const fetchDeployedPartForTrackersOverallAtom = atom(null, async (get, set, args) => {
//   let res = await axios.post(`/progress/get_deployed_part_for_project?block_id=${args?.blockid}`, args.tracker);
//   set(
//     deployedPartAtom,
//     produce((draft) => {
//       draft.deployedPartForTrackersOverall = res?.data;
//     })
//   );
// });

// export const fetchDeployedPartForTrackersActBlockAtom = atom(null, async (get, set, args) => {
  
//  let res =
//   await axios.post(`/progress/get_deployed_part_for_activity_in_block?block_id=${args?.blockid}&activity_id=${args?.activityid}&part_id=${args?.partid}`, args.tracker);
// set(
//   deployedPartAtom,
//   produce((draft) => {
//     draft.deployedPartForTrackersActBlock = res?.data;
//   })
// );
// });

export const fetchDeployedPartActivityWiseAtom = atom(
  null,
  async (get, set, args) => {
    let data = await get_deployed_part_for_activitywise_project_block(args)
    set(
      deployedPartAtom,
      produce((draft) => {
        draft.getDeployedPartForActivity = data
      })
    );
  }
);

export const fetchDeployedPartOverallProjectBlockAtom = atom(null, async (get, set, args) => {
  let data = await get_deployed_part_for_overall_project_block()
  set(
    deployedPartAtom,
    produce((draft) => {
      draft.getDeployedPartForOverall = data;
    })
  );
});

export default deployedPartAtom;

import React, { useEffect, useState } from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import mapAtom, { fetchSvgMapAtom } from '../../../data/svgMapAtom';
import projectAtom, { fetchProjectDetailsAtom } from '../../../data/projectAtom';
import { useAtom } from 'jotai';
import {
    Box,
    Button,
    Grid,
    Typography,
    TableContainer,
    TableHead,
    TableCell,
    Table,
    TableBody,
    TableRow,
} from "@mui/material";
import dashboardAtom, { fetchBlockAtom, fetchBulkPiersTorqueTubesBlockDataAtom, fetchD3XscaleYscaleSizeAtom, fetchPierLegendAtom } from '../../../data/dashboardAtom';
import { useNavigate } from 'react-router-dom';
import {BlockMap} from '@product-fusion/surya-fw';
import qcChecklistAtom, { fetchQCBlockIdAtom, fetchQCD3ColorAtom, fetchQCPunchlistFilterConditionsAtom, fetchQCViewAtom } from '../../../data/qualityControlAtom';
import { updateSelectedRowsAtom } from '../../../data/selectedRowsAtom';
import { get_qc_punchlist_counts } from '../../../dal/qualityCheckProgress';
import { GrCircleInformation } from "react-icons/gr";

const QCMapView = (props) => {
    const [mapdata] = useAtom(mapAtom);
    const [dashboard] = useAtom(dashboardAtom);
    const [projects] = useAtom(projectAtom);
    const [, fetchProjects] = useAtom(fetchProjectDetailsAtom);
    const [, fetchmapdataData] = useAtom(fetchSvgMapAtom);
    const [, fetchBlockData] = useAtom(fetchBlockAtom);
    const [qcChecklist] = useAtom(qcChecklistAtom)
    const [, fetchQCD3Color] = useAtom(fetchQCD3ColorAtom)
    const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
    const [, fetchD3XscaleYscaleSize] = useAtom(fetchD3XscaleYscaleSizeAtom)
    const [, fetchQCPunchlistFilterConditions] = useAtom(fetchQCPunchlistFilterConditionsAtom)
    const [, fetchQCBlockId] = useAtom(fetchQCBlockIdAtom)
    const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
    const [selectedRow, setSelectedRow] = useState([])
    const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom);
    const [showD3, setShowD3] = useState(false)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [selectedBlockId, setSelectedBlockId] = useState("All")
    const [statusWiseCounts, setStatusWiseCounts] = useState([])
    const [, fetchQCView] = useAtom(fetchQCViewAtom)
    const [blockName, setBlockName] = useState(null)
    const [selectedBlock, setSelectedBlock] = useState([])


    useEffect(() => {
        init();
        setSelectedBlockId(qcChecklist?.qcBlockId)
    }, []);

    const init = async () => {
        try {
            await fetchBlockData();
            await fetchmapdataData();
            await fetchProjects()
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        svgInit();
    }, [dashboard?.block,selectedBlock]);

    const svgInit = () => {
        if (dashboard?.block?.length > 0) {
            let block_svg_id;
            let block_progress_id;
            for (let i of dashboard?.block) {
                block_svg_id = document.getElementById(i?.data?.block_svg_id);
                block_progress_id = document.getElementById(i?.data?.progress_svg_id);
                if (block_svg_id) {
                    block_svg_id.addEventListener("click", () => {
                        setSelectedBlockId(parseInt(i?.id))
                        setBlockName(i?.data?.name)
                        if (selectedBlock[0] === i?.id) {
                            setSelectedBlock([])
                        }
                        else {
                            setSelectedBlock([i?.id])
                        }

                    });
                    block_svg_id.addEventListener("dblclick", () => {
                        setBlockName(i?.data?.name)
                        getD3Data(parseInt(i?.id))
                        updateSelectedQCBlockId(parseInt(i?.id))
                        setSelectedBlockId(parseInt(i?.id))
                    });
                }
                if (block_progress_id) {
                    block_progress_id.querySelector("tspan").innerHTML = null
                    if (selectedBlock.includes(i?.id)) {
                        block_svg_id.style.fill = "#F6BE00"
                    }
                    else {
                        block_svg_id.style.fill = "white"
                    }
                }

            }
        }
    };

    const updateSelectedQCBlockId = async (blockId) => {
        await fetchQCBlockId(blockId)
    }
    let projectId = JSON.parse(localStorage.getItem("project_id"))
    let projectDetails = {}
    for (let i of projects.projectDetailsDb) {
        if (i?.id === projectId) {
            projectDetails = i?.data
        }
    }

    const getD3Data = async (blockId) => {
        setLoading(true)
        try {
            await fetchD3XscaleYscaleSize(blockId)
            await fetchBulkPiersTorqueTubesBlockData(blockId)
            await fetchPierLegend()
            setLoading(false)
            setShowD3(true)
        } catch (err) {
            console.log(err)
        }

    }

    useEffect(() => {
        if (selectedRow.length === 1) {
            getFilteredListView()
        }
    }, [selectedRow])

    const getFilteredListView = async () => {
        // let trackerrowId = parseInt(selectedRow[0].split("-")[1])
        // let rowId = parseInt(selectedRow[0].split("-")[3])
        // let sectionId = parseInt(selectedRow[0].split("-")[4])
        // let filterConditionObj = { visibility: props.visibility, block_id: selectedBlockId, tracker_row_id: trackerrowId, section: sectionId, row: rowId }
        await fetchQCView(1)
        // await fetchQCPunchlistFilterConditions(filterConditionObj)
    }

    const updateSelectedRow = async (e) => {
        try {
            if (e.length !== 0) {
                await updateSelectedRows(e);
                setSelectedRow(e)
            }
            //   if (sectionSelect.length === 0 && previousSelectedSection !== null) {
            //     await updateSelectedRows([]);
            //     setSelectedRow([])
            //     setPreviousSelectedSection(null)
            //   }
            //   if (dashboard?.selectedTrackerTypeIds.length === 0 && dashboard?.previousSelectedTrackerTypeId !== null && dashboard?.isAllTrackersSelected === false) {
            //     await updateSelectedRows([]);
            //     setSelectedRow([])
            //   }
        } catch (err) {
            console.log(err)
        }
    }
    const getDimension = () => {
        let projectId = JSON.parse(localStorage.getItem("project_id"))
        let temp = {}
        for (let i of projects?.projectDetailsDb) {
            if (i?.id === projectId) {
                temp = i?.data?.svg_dimension?.tablet
            }
        }
        return temp
    }

    useEffect(() => {
        if (props.showSVG === true) {
            setShowD3(false)
            setSelectedBlockId("All")
            init()
        }
    }, [props.showSVG])

    useEffect(() => {
        getCountsStatusWise()
    }, [selectedBlockId, props.scope, props.visibility])

    const getCountsStatusWise = async () => {
        let filteredConditions = {}
        if (selectedBlockId !== "All" && props.scope !== "All") {
            filteredConditions = { block_id: selectedBlockId, visibility: props.visibility, scope: props.scope }
        } else if (selectedBlockId === "All" && props.scope === "All") {
            filteredConditions = { visibility: props.visibility }
        } else if (selectedBlockId !== "All" && props.scope === "All") {
            filteredConditions = { visibility: props.visibility, block_id: selectedBlockId }
        }
        else {
            filteredConditions = { visibility: props.visibility, scope: props.scope }
        }
        let data = await get_qc_punchlist_counts(filteredConditions)
        setStatusWiseCounts(data)
    }
    return (
        <Box>
            <Grid container>
                <Grid item md={8.5}>

                    {showD3 === false && qcChecklist?.qcBlockId === "All" ?
                        <Box
                            sx={{
                                height: "700px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <TransformWrapper
                                minScale={0.3}
                                initialScale={0.3}
                                initialPositionX={((window.innerWidth - 450) - (parseFloat(getDimension().tab_svg_initial_scale) * getDimension().tab_svg_height)) / 2}
                                initialPositionY={((window.innerHeight - 300) - (parseFloat(getDimension().tab_svg_initial_scale) * getDimension().tab_svg_height)) / 2}>
                                <TransformComponent
                                    contentStyle={{
                                        height: `${getDimension().tab_svg_height}px`,
                                        width: `${getDimension().tab_svg_width}px`,
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",

                                    }}
                                    wrapperStyle={{ height: "100%", width: "100%" }}
                                >
                                    <div
                                        className="svgmap"
                                        dangerouslySetInnerHTML={{ __html: mapdata }}
                                    ></div>
                                </TransformComponent>
                            </TransformWrapper>

                            <Box sx={{
                                display: "flex",
                                alignItems: "center",
                                position: "absolute",
                                bottom: "50px",
                                left: "25%",
                            }}>
                                <GrCircleInformation style={{ padding: "0px 8px" }} fontSize={18} />
                                <Typography sx={{ color: "rgb(156 163 175)", fontSize: "13px" }}>Double click on any block to dive in</Typography>
                            </Box>
                        </Box>
                        : dashboard?.bulkPiersTorqueTubesBlockData !== undefined ? <Box sx={{ height: "75vh", width: "70vw" }}>
                            {/* <Box sx={{ padding: "15px" }}>
                                <Button variant='contained' onClick={handleChangeSVG}>Back to SVG</Button>
                            </Box> */}
                            <BlockMap
                                bulkAllData={dashboard?.bulkPiersTorqueTubesBlockData}
                                pierLegends={dashboard?.pierLegends}
                                type="QC"
                                selectedRow={selectedRow}
                                setSelectedRow={e => updateSelectedRow(e)}
                                typeData={props.qcD3ColorData && props.qcD3ColorData}
                                userSelection={{ showType: "QC", selection: props?.scope }}
                                mapSize={dashboard?.d3XscaleYscaleSize}
                                deviceType="tablet"
                                currentProjectData={projectDetails}
                            /></Box> : null}
                </Grid>
                <Grid item md={3.5} >
                    <Box sx={{ borderLeft: "1px solid rgba(105, 111, 121, 0.5)", height: "82vh" }}>
                        <Box sx={{ padding: "10px" }}>
                            <Typography sx={{ paddingTop: "15px", paddingBottom: "2px", fontWeight: 500, fontSize: "16px" }}>{blockName !== null ? `${blockName} - Punchlist` : "Punchlist"}</Typography>
                            <Box sx={{ paddingY: "10px" }}>
                                <TableContainer>
                                    <Table sx={{ border: "1px solid rgba(224, 224, 224, 1);" }}>
                                        <TableHead sx={{ backgroundColor: "rgba(105, 111, 121, 0.08)", }}>
                                            <TableRow>
                                                <TableCell sx={{ fontWeight: "normal", textAlign: "center", padding: "10px 16px" }}>Status</TableCell>
                                                <TableCell sx={{ fontWeight: "normal", textAlign: "center", padding: "10px 16px" }}>Counts</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {statusWiseCounts?.map((i, rowIndex) => (
                                                <TableRow>
                                                    <TableCell sx={{ paddingLeft: "30px", textAlign: "left" }} key={rowIndex} >
                                                        {i?.punchlist_status === 0 ? "Open" : i?.punchlist_status === 1 ? "Ready For Inspection" : "Resolved"}
                                                    </TableCell>
                                                    <TableCell sx={{ paddingLeft: "30px", textAlign: "left" }} key={rowIndex}>
                                                        {i?.counts}</TableCell>
                                                </TableRow>
                                            )
                                            )}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default QCMapView

// import { useRef, useState, useEffect } from "react";
// import "./style.css"

// const SegmentedControl = ({
//   name,
//   segments,
//   callback,
//   controlRef,
//   // disabled
//    defaultIndex 
// }) => {
//   const [activeIndex, setActiveIndex] = useState(defaultIndex);
//   const componentReady = useRef();

//   useEffect(() => {
//     componentReady.current = true;
//   }, []);

//   useEffect(() => {
//     const activeSegmentRef = segments[activeIndex].ref;
//     const { offsetWidth, offsetLeft } = activeSegmentRef.current;
//     const { style } = controlRef.current;
    

//     style.setProperty("--highlight-width", `${offsetWidth}px`);
//     style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
//   }, [activeIndex, callback, controlRef, segments]);

//   const onInputChange = (value, index) => {
//     setActiveIndex(index);
//     callback(value, index);
//   };

//   return (
//     <div className="controls-container" ref={controlRef}>
//       <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
//         {segments?.map((item, i) => (
//           <div
//             key={item.value}
//             className={`segment ${i === activeIndex ? "active" : "inactive"}`}
//             ref={item.ref}
//           >
//             <input
//               // disabled={disabled}
//               type="radio"
//               value={item.value}
//               id={item.label}
//               name={name}
//               onChange={() => onInputChange(item.value, i)}
//               checked={i === activeIndex}

//             />
//             <label htmlFor={item.label}>{item.label}</label>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default SegmentedControl;




import { useRef, useState, useEffect } from "react";
import "./style.css";

const SegmentedControl = ({
  name,
  segments,
  callback,
  controlRef,
  // disabled
  defaultIndex,
}) => {
  const [activeIndex, setActiveIndex] = useState(defaultIndex);
  const componentReady = useRef(false);

  useEffect(() => {
    setActiveIndex(defaultIndex);
  }, [defaultIndex]);

  useEffect(() => {
    componentReady.current = true;
  }, []);

  useEffect(() => {
    const activeSegmentRef = segments[activeIndex]?.ref;
    if (activeSegmentRef?.current) {
      const { offsetWidth, offsetLeft } = activeSegmentRef.current;
      const { style } = controlRef.current;

      style.setProperty("--highlight-width", `${offsetWidth}px`);
      style.setProperty("--highlight-x-pos", `${offsetLeft}px`);
    }
  }, [activeIndex, callback, controlRef, segments]);

  const onInputChange = (value, index) => {
    setActiveIndex(index);
    callback(value, index);
  };

  return (
    <div className="controls-container" ref={controlRef}>
      <div className={`controls ${componentReady.current ? "ready" : "idle"}`}>
        {segments?.map((item, i) => (
          <div
            key={item.value}
            className={`segment ${i === activeIndex ? "active" : "inactive"}`}
            ref={item.ref}
          >
            <input
              // disabled={disabled}
              type="radio"
              value={item.value}
              id={item.label}
              name={name}
              onChange={() => onInputChange(item.value, i)}
              checked={i === activeIndex}
            />
            <label htmlFor={item.label}>{item.label}</label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SegmentedControl;

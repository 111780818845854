import Cookies from "js-cookie";
import { decodeJWT } from "./jwt";

export async function getToken() {
  let accessToken = Cookies.get("access_token");
  let refreshToken = Cookies.get("refresh_token");

  if (!refreshToken) return null;

  if (!accessToken && refreshToken) {
    let res = await fetch(`${process.env["REACT_APP_AUTH_API"]}/auth/refresh`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ refresh_token: refreshToken }),
    });

    let tokens = await res.json();

    if (!tokens) return null;

    let accessClaims = decodeJWT(tokens["access_token"]);
    Cookies.set("access_token", tokens["access_token"], {
      expires: new Date(accessClaims.exp * 1000),
    });
    accessToken = tokens["access_token"];
  }

  return accessToken;
}

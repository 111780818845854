import { atom } from "jotai";
import { get_svg_map_data } from "../dal/layout";

const mapAtom = atom();

export const fetchSvgMapAtom = atom(null, async (get, set, args) => {
  let data = await get_svg_map_data()
  set(mapAtom, data);
});

export default mapAtom;

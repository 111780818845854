import { atom } from "jotai";
import suryadb from "../indexedDB/module";
import produce from "immer";
import { get_qc_checklist_custom_inputs, get_qc_checklist_custom_response, get_qc_checklist_progress, get_qc_image_urls, get_qc_progress_for_d3_color, get_qc_punchlist } from "../dal/qualityCheckProgress";
import { delete_images } from "../dal/images";

const qcChecklistAtom = atom({
    qcChecklistProgress: [],
    qcImageUrls: [],
    qcPunchlist: [],
    qcPunchlistFilterConditions: {},
    qcCustomResponse: [],
    qcD3Color:[],
    qcBlockId:"All",
    qcView:null
});

export const fetchQcChecklistAtom = atom(null, async (get, set, args) => {

    let data = await get_qc_checklist_progress(args?.trackertypeid, args?.trackerrowid, args?.typeName, args?.visibility, args?.block_id, args?.mapType, args?.selectedPierOrTorqueTubeTypeId, args?.selectedPierOrTorqueTubeId,)
    set(qcChecklistAtom, produce((draft) => {
        draft.qcChecklistProgress = data
    }));
})
export const fetchQcCustomResponseAtom = atom(null, async (get, set, args) => {
    let data = await get_qc_checklist_custom_response(args?.trackerrowid, args?.typeName, args?.visibility, args?.block_id, args?.mapType,args?.selectedPierOrTorqueTubeId)
    set(qcChecklistAtom, produce((draft) => {
        draft.qcCustomResponse = data
    }));
})
export const fetchQcImageUrlsAtom = atom(null, async (get, set, args) => {
    let data = await get_qc_image_urls(args?.blockId,args?.visibility)
    set(qcChecklistAtom, produce((draft) => {
        draft.qcImageUrls = data
    }));
})

export const deleteQcImageUrlsAtom = atom(null, async (get, set, args) => {
    let data = await delete_images(args?.blockId,args?.visibility,args?.uploadId)
})

export const fetchQcPunchlistAtom = atom(null, async (get, set, args) => {
    // passing filter condition json
    let data = await get_qc_punchlist(args)
    set(qcChecklistAtom, produce((draft) => {
        draft.qcPunchlist = data
    }));
})

export const fetchQCPunchlistFilterConditionsAtom = atom(null, async (get, set, args) => {

    set(qcChecklistAtom, produce((draft) => {
        draft.qcPunchlistFilterConditions = args
    }));
})

export const fetchQCD3ColorAtom = atom(null, async (get, set, args) => {
    let data = await get_qc_progress_for_d3_color(args?.block_id,args?.visibility,args?.type)
    set(qcChecklistAtom, produce((draft) => {
        draft.qcD3Color = data
    }));
})

export const fetchQCBlockIdAtom = atom(null, async (get, set, args) => {

    set(qcChecklistAtom, produce((draft) => {
        draft.qcBlockId = args
    }));
})

export const fetchQCViewAtom = atom(null, async (get, set, args) => {
    set(qcChecklistAtom, produce((draft) => {
        draft.qcView= args
    }));
})




export default qcChecklistAtom






import {
  Box,
  Grid,
  Typography,
  Divider,
  InputLabel,
  TextField,
  Tab,
  Tabs,
  Modal,
  Button,
  Select,
  MenuItem,
  FormHelperText
} from "@mui/material";
import PropTypes from 'prop-types';
import React, { useState, useEffect, useRef, useCallback } from "react";
import Header from "../../../components/appBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import GroupsIcon from "@mui/icons-material/Groups";
import { useAtom } from "jotai";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../../data/selectedRowsAtom";
import dashboardAtom, { fetchBlockAtom, fetchBulkPiersTorqueTubesBlockDataAtom, fetchHighLightSelectedTrackerTypeIdsAtom, fetchPierLegendAtom, fetchPreviousSelectedTrackerTypeIdAtom, fetchSelectedTrackerTypeIdsAtom, fetchTrackerTypeNamesAtom, fetchZimmermanBulkPiersAtom } from "../../../data/dashboardAtom";
import { ToastContainer, toast } from "react-toastify";
import SegmentedControl from "../../../components/qcPunchlist/segmentedControl";
import QCChecklistTable from "../../../components/qc/qcChecklistTable";
import qcChecklistAtom, { fetchQcChecklistAtom, fetchQcCustomResponseAtom, fetchQcImageUrlsAtom } from "../../../data/qualityControlAtom";
import IndividualTrackerRow from "../../pierList/individualTrackerRow";
import lodash from "lodash";
import { get_qc_checklist_trackertypewise } from "../../../dal/qualityCheckProgress";
import { insertQcQueueAtom } from "../../../data/qcQueueAtom";
import produce from "immer";
import syncStatusAtom from "../../../data/syncStatusAtom";
import { LiaLocationArrowSolid } from "react-icons/lia";
import { GrCircleInformation } from "react-icons/gr";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../../featureFlags/featureFlags";


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const QcDataChecksheet = () => {
  const navigate = useNavigate();
  const [qcChecklistData, setQcChecklistData] = useAtom(qcChecklistAtom);
  const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
  const [, fetchQcChecklist] = useAtom(fetchQcChecklistAtom);
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [, fetchQcImageUrls] = useAtom(fetchQcImageUrlsAtom)
  const [, fetchQcCustomResponse] = useAtom(fetchQcCustomResponseAtom)
  const [dashboard] = useAtom(dashboardAtom);
  const [, fetchBlocks] = useAtom(fetchBlockAtom)
  const [piersTorqueTubesDetails, setPiersTorqueTubeDetails] = useState({})
  const [, insertQcQueue] = useAtom(insertQcQueueAtom)
  const [visibility, setVisibility] = useState(1);
  const [, fetchSelectedTrackerTypeIds] = useAtom(fetchSelectedTrackerTypeIdsAtom)
  const [, fetchPreviousSelectedTrackerTypeId] = useAtom(fetchPreviousSelectedTrackerTypeIdAtom)
  const [, fetchHighLightSelectedTrackerTypeIds] = useAtom(fetchHighLightSelectedTrackerTypeIdsAtom)
  const [, updateSelectedRows] = useAtom(updateSelectedRowsAtom)
  const [, fetchTrackerTypeNames] = useAtom(fetchTrackerTypeNamesAtom)
  const [back, setBack] = useState(false);
  const [blockDetails, setBlockDetails] = useState();
  const [selectedPierOrTorqueTubeId, setSelectedPierOrTorqueTubeId] = useState(0)
  const [type, setType] = useState(1);
  const [typeName, setTypeName] = useState("piers")
  const [selectedPierOrTorqueTubeTypeId, setSelectedPierOrToruqueTubeTypeId] = useState(0)
  const [backModal, setBackModal] = useState(false)
  const [mapType, setMapType] = useState(0)
  const [pierTorqueTubeKey, setPierTorqueTubeKey] = useState("")
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [customInputId, setCustomInputId] = useState(0)
  const [errorMessage, setErrorMessage] = useState("")
  const [syncStatusData] = useAtom(syncStatusAtom);
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [, fetchZimmermanBulkPiers] = useAtom(fetchZimmermanBulkPiersAtom)
  const params = useParams()
  const [adjustableBoxWidth, setAdjustableBoxWidth] = useState(0);
  // const [adjustableBoxHeight, setAdjustableBoxHeight] = useState(0);
  const fixedBoxWidth = 100;



  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  useEffect(() => {
    init();
  }, [syncStatusData.syncing]);

  useEffect(() => {
    let index = 1
    if (visibility === 1) {
      index = 0
    }
    localStorage.setItem("punchlist-visibility-index", index)
    init();
  }, []);

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  const init = async () => {
    const blockId = parseInt(params.id)
    try {
      await fetchBlocks()
      await fetchBulkPiersTorqueTubesBlockData(parseInt(params.id))
      await fetchQcImageUrls({ blockId, visibility })
      await fetchPierLegend()
      await fetchTrackerTypeNames()
      await fetchZimmermanBulkPiers(parseInt(params.id))
    } catch (err) {
      console.log(err)
    }
  };

  const handleCloseBackModal = () => {
    setBackModal(false)
  }

  useEffect(() => {
    if (isZimmerman === false) {
      if (isZimmerman === false && dashboard?.bulkPiersTorqueTubesBlockData && Object.keys(dashboard?.bulkPiersTorqueTubesBlockData).length !== 0) {
        let pierTTObj = {}
        let piersTorqueTubeData = dashboard?.bulkPiersTorqueTubesBlockData
        let trackerrowid = parseInt(params.trackerrowid)
        if (piersTorqueTubeData["piers"][trackerrowid] && piersTorqueTubeData["tt"][trackerrowid]) {
          pierTTObj["piers"] = lodash.sortBy(piersTorqueTubeData["piers"][trackerrowid], [
            function (o) {
              return o.pier_part_order;
            },
          ])
          pierTTObj["torque_tube"] = lodash.sortBy(piersTorqueTubeData["tt"][trackerrowid], [
            function (o) {
              return o.tt_part_order;
            },
          ])
          // pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
          // pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
          setPiersTorqueTubeDetails(pierTTObj)
          setBlockDetails(piersTorqueTubeData["piers"][trackerrowid][0])
          setType(0)
        }
      }
    }
  }, [isZimmerman, dashboard?.bulkPiersTorqueTubesBlockData])

  useEffect(() => {
    if (type === 0) {
      setTypeName("piers")
    }
    if (type === 1) {
      setTypeName("structure")
    }
    if (type === 2) {
      setTypeName("module")
    }
  }, [type])
  useEffect(() => {
    if (isZimmerman === true) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        if (blockPierData?.length !== 0) {
          setBlockDetails(blockPierData[0])
        }
      }
    }
  }, [isZimmerman, dashboard?.zimmermanBulkPiers])

  useEffect(() => {
    if (isZimmerman === false) {
      if (type === 0 && piersTorqueTubesDetails["piers"] && parseInt(mapType) === 1) {
        setSelectedPierOrTorqueTubeId(parseInt(piersTorqueTubesDetails["piers"][0]["id"]))
        setSelectedPierOrToruqueTubeTypeId(piersTorqueTubesDetails["piers"][0]["pier_type_id"])
      }
      if (type === 0 && piersTorqueTubesDetails["piers"] && parseInt(mapType) === 0) {
        let pierIdList = []
        let pierTypeIdList = []
        for (let i of piersTorqueTubesDetails["piers"]) {
          pierIdList.push(parseInt(i?.id))
          pierTypeIdList.push(parseInt(i?.pier_type_id))
        }
        setSelectedPierOrTorqueTubeId(pierIdList)
        setSelectedPierOrToruqueTubeTypeId(pierTypeIdList)
      }
      if ((type === 1 || type === 2) && piersTorqueTubesDetails["torque_tube"] && parseInt(mapType) === 1) {
        setSelectedPierOrTorqueTubeId(parseInt(piersTorqueTubesDetails["torque_tube"][0]["id"]))
        setSelectedPierOrToruqueTubeTypeId(piersTorqueTubesDetails["torque_tube"][0]["tt_type_id"])
      }
      if ((type === 1 || type === 2) && piersTorqueTubesDetails["torque_tube"] && parseInt(mapType) === 0) {
        let ttIdList = []
        let ttTypeIdList = []
        for (let i of piersTorqueTubesDetails["torque_tube"]) {
          ttIdList.push(parseInt(i?.id))
          ttTypeIdList.push(parseInt(i?.tt_type_id))
        }
        setSelectedPierOrTorqueTubeId(ttIdList)
        setSelectedPierOrToruqueTubeTypeId(ttTypeIdList)
      }
    }

  }, [type, mapType])

  useEffect(() => {
    if (type !== undefined && selectedPierOrTorqueTubeId !== 0) {
      getQCChecklistData()
    }
  }, [type, selectedPierOrTorqueTubeId, visibility, typeName])

  const getQCChecklistData = async () => {
    let block_id = blockDetails?.block_id
    let trackerrowid = parseInt(params.trackerrowid)
    let trackertypeid = parseInt(params.trackertypeid)
    try {
      let isTrackerrowLevelChecklist = await get_qc_checklist_trackertypewise(trackertypeid, typeName)
      if (isTrackerrowLevelChecklist.length === 0) {
        setMapType(1)
        await fetchQcCustomResponse({ typeName, selectedPierOrTorqueTubeId, visibility, block_id, trackerrowid, mapType })
        await fetchQcChecklist({ trackertypeid, selectedPierOrTorqueTubeTypeId, typeName, selectedPierOrTorqueTubeId, visibility, block_id, trackerrowid, mapType });
      } else {
        setMapType(0)
        await fetchQcCustomResponse({ typeName, visibility, block_id, trackerrowid, mapType })
        await fetchQcChecklist({ trackertypeid, typeName, visibility, block_id, trackerrowid, mapType });
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleChange = async (event, newValue) => {
    let isEmpty = await isCommentsEmpty()
    if (isEmpty === false) {
      setType(newValue);
    } else {
      setBackModal(true);
    }

  };


  let tracker_type = dashboard?.trackerTypeNames?.find(
    (j) => j?.id === parseInt(params.trackertypeid)
  );

  const isCommentsEmpty = async () => {
    let isEmpty = false;
    qcChecklistData?.qcChecklistProgress?.checkpoints.map((j) => {
      if (parseInt(j?.status) === 2 && (j?.comments === "" || j?.comments === undefined)) {
        isEmpty = true
      }
    });
    return isEmpty
  }
  const handleBack = async () => {
    await getQCChecklistData()
    await fetchPreviousSelectedTrackerTypeId(null)
    await fetchSelectedTrackerTypeIds([])
    await fetchHighLightSelectedTrackerTypeIds([])
    await updateSelectedRows([])
    let isEmpty = await isCommentsEmpty()
    if (isEmpty === false) {
      setBack(false)
      setBackModal(false);
      navigate(`/home/gpslocation/${params.id}/qcdata`);
    } else {
      setBackModal(true);
    }
  };


  const handleSelectPierTrqueTubeItem = (e) => {
    if (typeName === "piers") {
      setSelectedPierOrTorqueTubeId(e?.id);
    }
    else {
      setSelectedPierOrTorqueTubeId(e?.id);
    }

    setSelectedPierOrToruqueTubeTypeId(e?.type_id)
  }

  useEffect(() => {
    if (parseInt(type) === 0) {
      setPierTorqueTubeKey("pier_id")
    } else {
      setPierTorqueTubeKey("tt_id")
    }
  }, [type])

  const handleChangeCustomInputValue = async (e, data) => {

    let customResponseData = {}
    let value = e.target.value
    let updateCustomResponse = true
    if (parseInt(data?.custom_input_type) === 0 || parseInt(data?.custom_input_type) === 1) {
      if (parseInt(data?.custom_input_type) === 0) {
        if (value.includes(".") || /[^0-9]/.test(value)) {
          setShowErrorMessage(true)
          updateCustomResponse = false
          setCustomInputId(parseInt(data?.custom_input_id))
          setErrorMessage(" Please enter integer value")

        }
        else {
          setShowErrorMessage(false)
          updateCustomResponse = true
          setCustomInputId(parseInt(data?.custom_input_id))
        }
      } else {
        if (parseInt(data?.custom_input_type) === 1) {

          // if (/^-?\d*\.?\d*$/.test(value)) {
          //   if (/^-?\d*\.?\d{0,6}$/.test(value)) {
          if (/^-?\d*(\.\d*)?$/.test(value)) {
            if (/^-?\d*\.?\d{0,6}$/.test(value)) {
              setShowErrorMessage(false)
              setCustomInputId(parseInt(data?.custom_input_id))
              updateCustomResponse = true

            } else {
              updateCustomResponse = false
              setShowErrorMessage(true)
              setCustomInputId(parseInt(data?.custom_input_id))
              setErrorMessage(" Float value should not be more than 6 digits")
            }
          } else {
            updateCustomResponse = false
            setShowErrorMessage(true)
            setCustomInputId(parseInt(data?.custom_input_id))
            setErrorMessage("Please enter float value")
          }

        }
      }
    }

    customResponseData = {
      row: blockDetails?.row,
      section: blockDetails?.section,
      block_name: blockDetails?.block,
      block_id: blockDetails?.block_id,
      created_at: Date.now() / 1000,
      qc_type: "custom_response",
      custom_inputs_id: data?.custom_input_id,
      tracker_row_id: parseInt(params.trackerrowid),
      visibility: visibility,
      response: e?.target.value,
      scope: typeName,
      custom_inputs_name: data?.custom_input_name,
      custom_inputs_type: data?.custom_input_type,
      custom_inputs_values: data?.custom_input_values,
      checklist_id: data?.custom_input_checklist_id,
      custom_inputs_id: data?.custom_input_id,
      map_type: mapType,
      ...(parseInt(mapType) === 1 ? { [pierTorqueTubeKey]: selectedPierOrTorqueTubeId } : null)
    }
    let update_custom_response = []
    const existingObjectIndex = qcChecklistData?.qcCustomResponse.findIndex(obj => obj.custom_inputs_id === data?.custom_inputs_id);
    if (existingObjectIndex !== -1) {
      update_custom_response = [...qcChecklistData?.qcCustomResponse];
      update_custom_response[existingObjectIndex] = { ...update_custom_response[existingObjectIndex], response: e.target.value };
    } else {
      update_custom_response = [...qcChecklistData?.qcCustomResponse, customResponseData]
    }
    if (updateCustomResponse === true) {
      setQcChecklistData(
        produce((draft) => {
          draft.qcCustomResponse = update_custom_response
        }))

      try {
        await insertQcQueue(customResponseData)
      } catch (err) {
        console.log(err)
      }
    }
    
  }

  useEffect(() => {
    const updateBoxWidth = () => {
      const totalWidth = window.innerWidth;
      const newWidth = totalWidth - fixedBoxWidth;
      setAdjustableBoxWidth(newWidth > 0 ? newWidth : 0);
    };

    // Update box width initially
    updateBoxWidth();

    // Add event listener for window resize
    window.addEventListener('resize', updateBoxWidth);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener('resize', updateBoxWidth);
  }, []);



  // useEffect(() => {
  //   const updateBoxHeight = () => {
  //     const totalHeight = window.innerWidth;
  //     const newWidth = totalHeight - 350;
  //     setAdjustableBoxHeight(newWidth > 0 ? newWidth : 0);
  //   };

  //   // Update box width initially
  //   updateBoxHeight();

  //   // Add event listener for window resize
  //   window.addEventListener('resize', updateBoxHeight);

  //   // Cleanup event listener on component unmount
  //   return () => window.removeEventListener('resize', updateBoxHeight);
  // }, []);
  const getCustomInputValue = (data) => {
    let response = ""
    for (let i of qcChecklistData?.qcCustomResponse) {
      if (i?.custom_inputs_id === data?.custom_input_id) {
        response = i?.response
      }
    }
    return response
  }

  return (
    <Box sx={{ width: "100vw", height: "100vh", overflow: "auto" }}>
      <Header menuDisable={back} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          height: "64px",
          backgroundColor: "#262223",
          color: "white",
        }}
      >
        <ArrowBackIcon sx={{ padding: " 0px 18px" }} onClick={handleBack} />
        <Typography sx={{ fontWeight: "600", fontSize: "20px" }}>
          QC Checklist
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item
            xs={12}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <Box sx={{ padding: "10px 20px" }}>
                {isZimmerman === true ?
                  <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                    {blockDetails?.block}, Row {selectedRows[0]?.split("-")[4]}, Table
                    {selectedRows[0]?.split("-")[3]}
                  </Typography> :
                  <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                    {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                    {blockDetails?.row}
                  </Typography>}
                <Typography sx={{ color: "#696F79", fontSize: "14px" }}>
                  {tracker_type?.tracker_type}
                </Typography>
              </Box>

              <Box sx={{ margin: "10px 50px", }}>
                <SegmentedControl
                  // disabled={disableToggle}
                  name="group"
                  callback={(val) => {
                    // handleChangeVisibility(val)
                    setVisibility(val)
                  }}
                  controlRef={useRef()}
                  segments={[
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <GroupsIcon
                            sx={{ fontSize: "30px", paddingRight: "10px" }}
                          />
                          <Typography>External</Typography>
                        </Box>
                      ),
                      value: 1,
                      ref: useRef(),
                    },
                    {
                      label: (
                        <Box sx={{ display: "flex", alignItems: "center" }} >
                          <PersonIcon sx={{ paddingRight: " 10px" }} />
                          <Typography>Internal</Typography>
                        </Box>
                      ),
                      value: 0,
                      ref: useRef(),
                    },
                  ]}
                  defaultIndex={0}
                />
              </Box>
            </Box>
            <Divider />
            <Box sx={{ display: "flex", }}>
              {/* <Box sx={{ height: "70vh" }}>
                <BlockView
                  value={false}
                  fromCheckSheet={true}
                  CpRow={selectedRows[0]?.split("-")[3]}
                  CpSection={selectedRows[0]?.split("-")[4]}
                  selectedD3View={0}
                />
              </Box> */}
              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                {/* <Box > 
                    <LiaLocationArrowSolid fontSize={30}  />
                    <Typography>N</Typography>
                </Box> */}

                {isZimmerman === false ? Object.keys(piersTorqueTubesDetails).length > 0 && selectedPierOrTorqueTubeId !== 0 && dashboard?.pierLegends &&
                  <Box sx={{ margin: "10px 20px" }} >
                    <IndividualTrackerRow
                      type="qc"
                      data={piersTorqueTubesDetails}
                      pierData={piersTorqueTubesDetails?.piers}
                      torqueTubeData={piersTorqueTubesDetails?.torque_tube}
                      pierLegend={dashboard?.pierLegends}
                      selectedItem={selectedPierOrTorqueTubeId}
                      selectedTab={typeName}
                      onSelectItem={e => handleSelectPierTrqueTubeItem(e)}
                      mapType={mapType}
                      width={fixedBoxWidth}
                    />
                  </Box> :
                  <Box sx={{ margin: "10px 25px" }}>

                  </Box>
                }
              </Box>
              {/* <Box sx={{ margin: "15px 20px" }}>
                {parseInt(type) === 0 ? (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="Pier Part Numbers"
                      value={selectedPierOrTorqueTubeId}
                      name="pier part numbers"
                      onChange={handleChangePiersTorqueTubePartNumbers}
                    >
                      {piersTorqueTubesDetails?.piers?.map((i) => {
                        if (i?.pier_part_number !== null && Math.round(i?.pier_part_number) !== 0) {
                          return (
                            <FormControlLabel sx={{ margin: "5px" }} key={i?.pier_id} value={i?.pier_id} control={<Radio />} label={Math.round(i?.pier_type_id)} />
                          )
                        }
                      })}
                    </RadioGroup>
                  </FormControl>

                ) : (
                  <FormControl>
                    <RadioGroup
                      aria-labelledby="Torque Tube Part Numbers"
                      value={selectedPierOrTorqueTubeId}
                      name="torque tube part numbers"
                      onChange={handleChangePiersTorqueTubePartNumbers}
                    >
                      {piersTorqueTubesDetails?.torque_tube?.map((i) => {
                        if (i?.part_number !== null && Math.round(i?.part_number) !== 0) {
                          return (
                            <FormControlLabel sx={{ margin: "5px" }} key={i?.torque_tube_id} value={i?.torque_tube_id} control={<Radio />} label={Math.round(i?.tt_type_id)} />
                          )
                        }
                      })}
                    </RadioGroup>
                  </FormControl>
                )}
              </Box> */}
              <Box sx={{ border: "1px solid gray", margin: "10px 20px 20px 0px", width: `${adjustableBoxWidth}px`, height: "calc(100vh-500px)" }}>
                <Box >
                  {/* <Box sx={{ borderBottom: 1, borderColor: 'divider', position: 'fixed', width: "85.3vw", zIndex: 1, backgroundColor: 'white', m: 0.1 }}> */}
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <Tabs value={type} onChange={handleChange} aria-label="basic tabs example">
                      <Tab label="piers" {...a11yProps(0)} />
                      <Tab label="structures" {...a11yProps(1)} />
                      <Tab label="module" {...a11yProps(2)} />
                    </Tabs>
                    <Box sx={{
                      display: "flex",
                      alignItems: "center",
                    }}>
                      <GrCircleInformation style={{ padding: "0px 5px", color: "rgba(0, 0, 0, 0.5)" }} fontSize={16} />
                      <Typography sx={{ fontSize: "14px", paddingRight: "16px", color: "rgba(0, 0, 0, 0.5)", fontWeight: "500" }}>Changes will be autosaved</Typography>
                    </Box>
                  </Box>
                  {/* </Box> */}
                  <Box>
                    <CustomTabPanel value={type} index={0}>
                      <Box sx={{ display: "flex", flexDirection: "column", }}>
                        {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel sx={{ width: "180px" }} >Design Pier Ht</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Design Pier Ht"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",
                            }}
                            size="small"
                          />
                        </Box> */}
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Pier A
                          </Typography>
                        </Box>
                        <Box sx={{ padding: "0px 10px 5px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs && qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 ? qcChecklistData?.qcChecklistProgress?.custom_inputs.map((i) => (
                            <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                              <InputLabel sx={{ width: "180px" }}>{i?.custom_input_name}</InputLabel>
                              {parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 || parseInt(i?.custom_input_type) === 2 ?
                                <Box>
                                  <TextField
                                    type={parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 ? "number" : "text"}
                                    inputProps={{ pattern: parseInt(i?.custom_input_type) === 1 ? "[0-9]*[.]?[0-9]+" : null }}
                                    value={getCustomInputValue(i)}
                                    name={i?.custom_input_name}
                                    onChange={e => { handleChangeCustomInputValue(e, i) }}
                                    sx={{

                                      width: "200px",

                                    }}
                                    size="small"

                                  />
                                  {showErrorMessage && parseInt(i?.custom_input_id) === customInputId ? <FormHelperText error>{errorMessage}</FormHelperText> : null}
                                </Box>
                                :

                                <Select
                                  value={getCustomInputValue(i)}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  sx={{
                                    width: "200px",
                                    height: "40px"
                                  }}
                                  onChange={e => { handleChangeCustomInputValue(e, i) }}
                                >
                                  {i?.custom_input_values?.values && i?.custom_input_values?.values.length > 0 ? i?.custom_input_values?.values.map((j) => (
                                    < MenuItem value={j}>{j}</MenuItem>
                                  )) : null}
                                </Select>}

                            </Box>
                          )) : null}
                          {/* <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Pier Twist</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Pier Twist"
                            // onChange={}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Custom Label 3</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Custom Label 3"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box> */}
                        </Box>
                        {/* <Button sx={{ display: "flex", justifyContent: "start", heigth: "30px", width: "30px", borderRadius: "5px", margin: "5px 10px", textTransform: "none" }} variant="contained" onClick={handleSaveCustomResponse}>Save</Button> */}
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type}
                            typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls}
                            visibility={visibility}
                            mapType={mapType} />
                        </Box>
                      </Box>

                    </CustomTabPanel>
                    <CustomTabPanel value={type} index={1}>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Structure A
                          </Typography>
                          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel sx={{ width: "180px" }}>Design Structure Ht</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Design Structure Ht"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",
                            }}
                            size="small"

                          />

                        </Box> */}
                        </Box>
                        <Box sx={{ padding: "0px 10px 10px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs && qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 ? qcChecklistData?.qcChecklistProgress?.custom_inputs.map((i) => (
                            <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                              <InputLabel sx={{ width: "180px" }}>{i?.custom_input_name}</InputLabel>

                              {parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 || parseInt(i?.custom_input_type) === 2 ?
                                <TextField
                                  type={parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 ? "number" : "text"}
                                  inputProps={{ pattern: parseInt(i?.custom_input_type) === 1 ? "[0-9]*[.]?[0-9]+" : null }}
                                  value={getCustomInputValue(i)}
                                  name={i?.custom_input_name}
                                  onChange={e => { handleChangeCustomInputValue(e, i) }}
                                  sx={{

                                    width: "200px",

                                  }}
                                  size="small"

                                /> :
                                <Select
                                  value={getCustomInputValue(i)}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  sx={{
                                    width: "200px",
                                    height: "40px"
                                  }}
                                  onChange={e => { handleChangeCustomInputValue(e, i) }}
                                >
                                  {i?.custom_input_values?.values && i?.custom_input_values?.values.length > 0 ? i?.custom_input_values?.values.map((j) => (
                                    < MenuItem value={j}>{j}</MenuItem>
                                  )) : null}
                                </Select>}

                            </Box>
                          )) : null}
                          {/* <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Pier Twist</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Pier Twist"
                            // onChange={}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Custom Label 3</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Custom Label 3"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box> */}
                        </Box>
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type} typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls}
                            visibility={visibility}
                            mapType={mapType} />
                        </Box>
                      </Box>
                    </CustomTabPanel>
                    <CustomTabPanel value={type} index={2}>
                      <Box sx={{ display: "flex", flexDirection: "column", }}>
                        <Box sx={{ padding: "10px 10px 5px 10px" }}>
                          <Typography sx={{ fontWeight: "bold" }}>
                            Module A
                          </Typography>
                          {/* <Box sx={{ display: "flex", alignItems: "center" }}>
                          <InputLabel sx={{ width: "180px" }}>Design Module Ht</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Design Module Ht"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",
                            }}
                            size="small"

                          />

                        </Box> */}
                        </Box>
                        <Box sx={{ padding: "0px 10px 10px 10px" }}>
                          {qcChecklistData?.qcChecklistProgress?.custom_inputs && qcChecklistData?.qcChecklistProgress?.custom_inputs.length > 0 ? qcChecklistData?.qcChecklistProgress?.custom_inputs.map((i) => (
                            <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                              <InputLabel sx={{ width: "180px" }}>{i?.custom_input_name}</InputLabel>

                              {parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 || parseInt(i?.custom_input_type) === 2 ?
                                <TextField
                                  type={parseInt(i?.custom_input_type) === 0 || parseInt(i?.custom_input_type) === 1 ? "number" : "text"}
                                  inputProps={{ pattern: parseInt(i?.custom_input_type) === 1 ? "[0-9]*[.]?[0-9]+" : null }}
                                  value={getCustomInputValue(i)}
                                  name={i?.custom_input_name}
                                  onChange={e => { handleChangeCustomInputValue(e, i) }}
                                  sx={{

                                    width: "200px",

                                  }}
                                  size="small"

                                /> :
                                <Select
                                  value={getCustomInputValue(i)}
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  sx={{
                                    width: "200px",
                                    height: "40px"
                                  }}
                                  onChange={e => { handleChangeCustomInputValue(e, i) }}
                                >
                                  {i?.custom_input_values?.values && i?.custom_input_values?.values.length > 0 ? i?.custom_input_values?.values.map((j) => (
                                    < MenuItem value={j}>{j}</MenuItem>
                                  )) : null}
                                </Select>}

                            </Box>
                          )) : null}
                          {/* <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Pier Twist</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Pier Twist"
                            // onChange={}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box>
                        <Box sx={{ display: "flex", alignItems: "center", margin: "5px 0" }}>
                          <InputLabel sx={{ width: "180px" }}>Custom Label 3</InputLabel>
                          <TextField
                            // defaultValue={}
                            name="Custom Label 3"
                            // onChange={(}
                            sx={{
                              // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                              width: "150px",

                            }}
                            size="small"

                          />

                        </Box> */}
                        </Box>
                        <Box>
                          <QCChecklistTable
                            qcProgressData={qcChecklistData?.qcChecklistProgress}
                            pierTorqueTubeId={selectedPierOrTorqueTubeId}
                            type={type} typeName={typeName}
                            trackeRowId={parseInt(params.trackerrowid)}
                            trackerTypeId={parseInt(params.trackertypeid)}
                            blockDetails={blockDetails}
                            imageUrls={qcChecklistData?.qcImageUrls} visibility={visibility} mapType={mapType} />
                        </Box>
                      </Box>
                    </CustomTabPanel>
                  </Box>
                </Box>
              </Box>
            </Box>

          </Grid>
        </Grid >
      </Box >
      <Modal
        open={backModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "35%",
            backgroundColor: "#FFFFFF",
            color: "black",
            borderRadius: "20px",
            padding: "10px 20px"
          }}
        >
          {/* <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "15px 20px 0px 0px ",
              }}
            >
              <CloseIcon onClick={handleCloseBackModal} />
            </Box> */}
          <Box
            sx={{
              padding: "10px",
            }}
          >
            <Typography sx={{ fontSize: "20px" }}>
              {/* Please enter a comment and add the item to a punchlist */}
              Enter comment for the failed item
            </Typography>
          </Box>

          <Box
            sx={{
              // padding: "10px 5px",
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Button variant="contained" onClick={handleCloseBackModal} sx={{ textTransform: "none", marginTop: "5px" }}>
              Ok
            </Button>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "440px" }}
      />
    </Box >
  );
};

export default QcDataChecksheet

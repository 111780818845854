import { atom } from "jotai";
import suryadb from "../indexedDB/module";
import { get_parts_name } from "../dal/constructionProgress";

const partsAtom = atom([]);

export const fetchPartsAtom = atom(null, async (get, set, args) => {
  let data = await get_parts_name()
  set(partsAtom, data);
});

export default partsAtom;

import { atom } from "jotai";
import { get_all_activities } from "../dal/activities";

const activitiesAtom = atom({});

export const fetchActivitiesAtom = atom(null, async (get, set, args) => {
  let data = await get_all_activities()
   set(activitiesAtom, data);
});

export default activitiesAtom;

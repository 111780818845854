import axios from "axios";
import suryadb from "../indexedDB/module";

export async function get_login_details(data){

    let loginResp = await axios.post(`/auth/login`,data);
      await suryadb().get_user_details.put({
        id: 1,
        data: loginResp.data,
      });
      return loginResp
}

export async function get_user_details(){
  let data = await suryadb().get_user_details.toArray()
  return data[0]?.data
}
export async function reset_password_details(data){
  let res = await axios.post(`auth/reset-password`, data)
  return res
}
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TextField,
} from "@mui/material";
import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from "react-toastify";
import dashboardAtom, { fetchExistingDistrbutedInstalledAtom, updateDistributedAndInstalledQuantityAtom } from "../data/dashboardAtom";
import { useAtom } from "jotai";

const ConstructionInprogressTable = ({ rows, foundType, bomDetails, trackerrowId, isZimmerman, clearTableData }) => {
  const [dashboard] = useAtom(dashboardAtom)
  const [btnenable, setbtnenable] = useState(false);
  const [updateQuantity, setUpdateQuantity] = useState({});
  const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
  const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom)

  useEffect(() => {
    init()
  }, [])

  const init = async () => {
    try {
      await fetchExistingDistrbutedInstalled()
    } catch (err) {
      console.log(err)
    }
  }

  const handleQtyOnChange = (e, i, maxVal, type) => {
    if (e.target.value !== "") {
      if (e.target.value > 0) {
        setbtnenable(true);
      }
      if (parseInt(e.target.value) > maxVal) {
        toast.error("Please enter the value lesser than total qty");
      }
      let key = `${i.bom_id}_${trackerrowId}`;
      let progress_qty = { ...dashboard?.updateDistributedAndInstalledQuantity };
      if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0 && progress_qty[key]) {
        if (progress_qty[key][`${type}`]) {
          progress_qty[key] = { ...progress_qty[key], [type]: parseInt(e.target.value) };
        } else {
          let pqty_type = { ...progress_qty[key] };
          pqty_type[`${type}`] = parseInt(e.target.value);
          progress_qty[key] = pqty_type;
        }
      } else {
        let pqty_type = {};
        pqty_type[type] = parseInt(e.target.value);
        progress_qty[key] = pqty_type;
      }
      setUpdateQuantity(progress_qty);
    }
  };
  useEffect(() => {
    setUpdateQuantity({})
    updateDistributedInstalledQuantity()
  }, [clearTableData])

  useEffect(() => {
    if (Object.keys(updateQuantity).length > 0) {
      updateDistributedInstalledQuantity()
    }
  }, [updateQuantity])

  const updateDistributedInstalledQuantity = async () => {
    await updateDistributedAndInstalledQuantity(updateQuantity)
  }


  const getExistingDistributedAndInstalledQuantity = (data) => {
    let existingValue = null
    let bomId_trackerrowId_key = `${parseInt(data?.bom_id)}_${parseInt(data?.tracker_row_id)}`
    if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key]) {
      if (dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type] !== 0) {
        existingValue = dashboard?.existing_bom_per_tracker_row[bomId_trackerrowId_key][data?.found_type]
      }
    }
    return existingValue
  }

  const getCurrentDistributedAndInstalledQuantity = (data) => {
    let currentValue = null
    let key = `${data?.bom_id}_${parseInt(data?.tracker_row_id)}`
    if (Object.keys(dashboard?.updateDistributedAndInstalledQuantity).length > 0) {
      if (dashboard?.updateDistributedAndInstalledQuantity[key] && dashboard?.updateDistributedAndInstalledQuantity[`${data?.bom_id}_${parseInt(data?.tracker_row_id)}`][data?.found_type])
        currentValue = dashboard?.updateDistributedAndInstalledQuantity[key][data?.found_type]
    }
    return currentValue
  }

  return (
    <TableContainer sx={{ height: foundType === "distributed_installed" ? "245px" : null, overflow: "auto" }}>
      <Table sx={{ padding: "10px" }} size="small">
        <TableHead>
          {rows.map((j) => {
            return (
              <TableRow>
                <TableCell>{j?.part_number}</TableCell>
                <TableCell width={500}>{j?.part_description}</TableCell>
                <TableCell>{j?.total_quantity}</TableCell>
                <TableCell>{j?.type}</TableCell>
              </TableRow>
            )
          })}
        </TableHead>
        <TableBody>
          {bomDetails?.map((i) => {
            return (
              <TableRow>
                <TableCell>{i?.part_name}</TableCell>
                <TableCell width={500}>{i?.description}</TableCell>
                <TableCell>{i?.total_quantity}</TableCell>
                <TableCell>
                  <TextField
                    name="qty"
                    // defaultValue={i?.
                    //   found_type === "installed" ?
                    //   (i?.existing_installed_quantity !== 0 ? i?.existing_installed_quantity : null) : (i?.existing_distributed_quantity !== 0 ? i?.existing_distributed_quantity : null)}

                    defaultValue={dashboard?.updateDistributedAndInstalledQuantity[`${i?.bom_id}_${parseInt(i?.tracker_row_id)}`] && dashboard?.updateDistributedAndInstalledQuantity[`${i?.bom_id}_${parseInt(i?.tracker_row_id)}`][i?.found_type] ? getCurrentDistributedAndInstalledQuantity(i) : getExistingDistributedAndInstalledQuantity(i)}
                    onChange={(e) => {
                      handleQtyOnChange(
                        e,
                        i,
                        i?.total_quantity,
                        i?.found_type
                      );
                    }}
                    sx={{
                      // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      width: 150,
                    }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        max: i?.total_quantity,
                        min: 0,
                      },
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ConstructionInprogressTable

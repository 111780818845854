import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { getToken } from "../utils/auth";
import { decodeJWT } from "../utils/jwt";
import authAtom from "../data/authAtom";
import { Box, CircularProgress } from "@mui/material";
import produce from "immer";

export default function AuthProvider(props) {

    const [loading, setLoading] = useState(true);
    const [, login] = useAtom(authAtom)

    useEffect(() => {
        init();
    }, [])

    const init = async () => {
        try {
            if (navigator.onLine) {
                let token = await getToken();
                if (token) {
                    let data = decodeJWT(token);
                    // if (!localStorage.getItem("tenant-name")) {
                    //     localStorage.setItem("tenant-name", data["organizations"][0]["name"]);
                    // }
                    login(produce((draft)=>{
                        draft.data = data
                        draft.loggedIn =true
                    }));
                }
            } else {
                login(produce((draft)=>{
                    // draft.data = data
                    draft.loggedIn =true
                }));
            }
        } catch (err) {
            console.log(err)
        } finally {
            setLoading(false);
        }
    }

    if (loading) return <Box sx={{ display: "flex", width: "100vw", height: "100vh", alignItems: "center", justifyContent: "center" }}><CircularProgress /></Box>

    return (
        <div>{props.children}</div>
    )

}
import axios from 'axios';

export async function isOnline(no, yes) {
  try {
    const response = await axios.get('/project/get_projects');
    
    if (yes instanceof Function) {
      yes();  // Call the success callback
    }
  } catch (error) {
    if (no instanceof Function) {
      no();  // Call the error callback
    }
  }
}

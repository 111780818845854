import {
  Box,
  Typography,
  Modal,
  Divider,
  InputLabel,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import React, { useState, useEffect, useRef } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UndoIcon from "@mui/icons-material/Undo";
import { useNavigate, useParams } from "react-router-dom";
import { useAtom } from "jotai";
import { insertCPQueueAtom } from "../../../data/cpQueueAtom";
import dashboardAtom, {
  fetchTotalInstalledorDistributedAtom,
  fetchDistributedInstalledBomAtom,
  fetchExistingDistrbutedInstalledAtom,
  fetchPartPerTrackerAtom,
  fetchActivityIdAtom,
  updateExistingDistrbutedInstalledAtom,
  fetchConstructionActivityIdAtom,
  fetchTrackerTypeNamesAtom,
  fetchBulkPiersTorqueTubesBlockDataAtom,
  getSelectedPiersTorqueTubesDataAtom,
  updateDistributedAndInstalledQuantityAtom,
  fetchPierLegendAtom,
  fetchZimmermanBulkPiersAtom,
  fetchZimmermanTableWisePierPartBomAtom,
  updateClearSelectedTrackerRowsAtom
} from "../../../data/dashboardAtom";
import selectedRowsAtom, { updateSelectedRowsAtom } from "../../../data/selectedRowsAtom";
import activitiesAtom, { fetchActivitiesAtom } from "../../../data/activitiesAtom";
import ActivitySelect from "../../../components/selectActivity";
import constructionProgressTrackerrowWiseAtom, {
  fetchConstructionProgressTrackerrowWiseAtom,
} from "../../../data/constructionProgressAtom";
import produce from "immer";
import { GrCircleInformation } from "react-icons/gr";
import { get_distributed_and_installed_bom_for_activity, get_updated_bom_for_distributed_installed_for_selected_activity_trackerow } from "../../../components/functions/constructionProgress";
import lodash from "lodash";
import ConstructionInprogressTable from "../../../components/constructionInprogressTable";
import ConstructionProgressPierTTSelection from "../../../components/constructionProgressPierTTSelection";
import { IS_PROJECT_TYPE_ZIMMERMAN_FLAG } from "../../../featureFlags/featureFlags";
import partsAtom, { fetchPartsAtom } from "../../../data/partsAtom";


const InprogressDistributedRows = [
  {
    part_number: "Part Number",
    part_description: "Part Description",
    total_quantity: "Total Qty",
    type: "Distributed Quantities"
  }
]
const InProgressInstalledRows = [
  {
    part_number: "Part Number",
    part_description: "Part Description",
    total_quantity: "Total Qty",
    type: "Installed Quantities"
  }
]


const Msg = () => (
  <Box sx={{ display: "flex" }}>
    <Box
      sx={{
        padding: "0px",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        paddingRight: "40px",
      }}
    >
      <Typography variant="h6"> Applied Successfully</Typography>
      <Typography>To all the selected trackers</Typography>
    </Box>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: " 15px ",
        color: "#F16253",
      }}
    >
      <UndoIcon sx={{ fontSize: "25px" }} />
      <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>UNDO</Typography>
    </Box>
  </Box>
);

const ConstructionProgress = () => {
  const [, insertCPQueue] = useAtom(insertCPQueueAtom);
  const [, fetchExistingDistrbutedInstalled] = useAtom(fetchExistingDistrbutedInstalledAtom);
  const [, updateExistingDistrbutedInstalled] = useAtom(updateExistingDistrbutedInstalledAtom);
  const [, fetchTotalInstalledorDistributed] = useAtom(fetchTotalInstalledorDistributedAtom);
  const [constructionProgressTrackerrowWise, setConstructionProgressTrackerrowWise] = useAtom(constructionProgressTrackerrowWiseAtom);
  const [, fetchConstructionProgressTrackerrowWise] = useAtom(fetchConstructionProgressTrackerrowWiseAtom);
  const [, fetPartPerTracker] = useAtom(fetchPartPerTrackerAtom);
  const [activities] = useAtom(activitiesAtom);
  const [, fetchActivities] = useAtom(fetchActivitiesAtom);
  const [, fetchDistributedInstalledBom] = useAtom(fetchDistributedInstalledBomAtom);
  const [dashboard, setDashboard] = useAtom(dashboardAtom);
  const [openModal, setOpenModal] = useState(false);
  const [status, setStatus] = useState("");
  const [, constructionActivityId] = useAtom(fetchConstructionActivityIdAtom)
  const [, fetchTrackerTypeNames] = useAtom(fetchTrackerTypeNamesAtom)
  const [, fetchPierLegend] = useAtom(fetchPierLegendAtom)
  const navigate = useNavigate();
  const [listParts] = useAtom(partsAtom);
  const [, fetchParts] = useAtom(fetchPartsAtom);
  const [selectedRows] = useAtom(selectedRowsAtom);
  const [foundType, setFoundType] = useState("");
  const [bomDetails, setBomDetails] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState("All");
  const [, fetchActivityId] = useAtom(fetchActivityIdAtom);
  const [btnenable, setbtnenable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(1)
  const [openPierTorqueTubeModal, setOpenPierTorqueTubeModal] = useState(false)
  const [blockDetails, setBlockDetails] = useState({})
  const [inprogressDeployedPartCount, setInprogressDeployedPartCount] = useState(0)
  const [inprogressTotalPartCount, setInprogressTotalPartCount] = useState(0)
  const [piersTorqueTubesDetails, setPiersTorqueTubeDetails] = useState({})
  const [trackerTypeDetails, setTrackerTypeDetails] = useState()
  const [, fetchBulkPiersTorqueTubesBlockData] = useAtom(fetchBulkPiersTorqueTubesBlockDataAtom)
  const [, getSelectedPiersTorqueTubesData] = useAtom(getSelectedPiersTorqueTubesDataAtom)
  const [, updateDistributedAndInstalledQuantity] = useAtom(updateDistributedAndInstalledQuantityAtom)
  const [, fetchZimmermanBulkPiers] = useAtom(fetchZimmermanBulkPiersAtom)
  const [, fetchZimmermanTableWisePierPartBom] = useAtom(fetchZimmermanTableWisePierPartBomAtom)
  const [isZimmerman, setIsZimmerman] = useState(false)
  const [zimmermanPierData, setZimmermanPierData] = useState({})
  const [clearInprogressTableData,setClearInprogressTableData] = useState(false)
  const [,updateClearSelectedTrackerRows] = useAtom(updateClearSelectedTrackerRowsAtom)
  const params = useParams();



  const handleClose = async () => {
    setOpenModal(false)
    setInprogressDeployedPartCount(0)
    setClearInprogressTableData(!clearInprogressTableData)
    // await updateDistributedAndInstalledQuantity({})
  };
  const handleClosePierTorqueTubeModal = async () => {
    setOpenPierTorqueTubeModal(false)
    setInprogressDeployedPartCount(0)
    try {
      await getSelectedPiersTorqueTubesData({})
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    async function fetchProjectTypeFlag() {
      let is_zimmeran = await IS_PROJECT_TYPE_ZIMMERMAN_FLAG()
      setIsZimmerman(is_zimmeran)
    }
    fetchProjectTypeFlag();
  }, [])

  const handleProgressChange = async (e) => {
    let SelectedActivityId = ""
    if (e === "All" || e === "None Selected") {
      SelectedActivityId = e
    } else {
      SelectedActivityId = e[0]
    }

    localStorage.setItem("construction-act-id", SelectedActivityId)
    setSelectedActivity(localStorage.getItem("construction-act-id"));
    try {
      fetchActivityId(e)
    }
    catch (err) {
      console.log(err)
    }
  };
  useEffect(() => {
    if (parseInt(status) === 1) {
      let existingLinkedPartCount = getDeployedPartCountOfSelectedTrackerrow()
      if (existingLinkedPartCount > 0 || inprogressDeployedPartCount !== 0 ) {
        setbtnenable(true)
        if (inprogressDeployedPartCount === 0) {
          setInprogressDeployedPartCount(existingLinkedPartCount)
        }
      }
      else {
        setbtnenable(false)
      }
    }

  }, [status, openModal])

  useEffect(() => {
    if (!isNaN(inprogressDeployedPartCount) && inprogressDeployedPartCount !== 0) {
      setbtnenable(true)
    } else {
      setbtnenable(false)
    }
  }, [inprogressDeployedPartCount])

  const handleInprogressDeployedPartCount = (e) => {
    if (parseInt(e.target.value) > inprogressTotalPartCount) {
      setbtnenable(false)
      toast.error("Please enter the value lesser than Total Part Count");
    } else {
      setInprogressDeployedPartCount(parseInt(e.target.value))
    }
  }
  useEffect(() => {
    if (selectedRows.length === 1 && parseInt(status) === 1 && localStorage.getItem("construction-act-id") !== "All") {
      let activityId = JSON.parse(localStorage.getItem("construction-act-id"))
      let tracker_type_id = parseInt(selectedRows[0]?.split("-")[2])

      let partId_trackertypeId_key = `${activities?.construction[activityId]?.part_id}_${tracker_type_id}`
      let totalPartCount = 0
      if (dashboard?.part_per_tracker[partId_trackertypeId_key]) {
        totalPartCount = dashboard?.part_per_tracker[partId_trackertypeId_key]
      }
      let trackerTypesDetails = dashboard?.trackerTypeNames.find(
        (j) => j?.id === parseInt(tracker_type_id)
      );
      setTrackerTypeDetails(trackerTypesDetails)
      setInprogressTotalPartCount(parseInt(totalPartCount))
    }
  }, [status])

  useEffect(() => {
    if (selectedRows.length === 0) {
      setStatus("");
      setSelectedActivity("All");
    }
  }, [selectedRows]);

  useEffect(() => {
    init();
    setSelectedActivity(localStorage.getItem("construction-act-id"));
    localStorage.setItem("punchlist-visibility-index", 0)
  }, []);

  const init = async () => {
    try {
      await fetchActivities();
      await fetchParts()
      await fetchTotalInstalledorDistributed();
      await fetchDistributedInstalledBom();
      await fetchExistingDistrbutedInstalled();
      await fetPartPerTracker();
      await fetchConstructionProgressTrackerrowWise(parseInt(params?.id));
      await fetchActivityId();
      await constructionActivityId()
      await fetchTrackerTypeNames()
      await fetchPierLegend()
      await fetchBulkPiersTorqueTubesBlockData(parseInt(params?.id))
      await fetchZimmermanBulkPiers(parseInt(params.id))
      await fetchZimmermanTableWisePierPartBom()
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    if (isZimmerman === true && parseInt(status) === 1) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        if (blockPierData?.length !== 0) {
          setZimmermanPierData(blockPierData)
        }
      }
    }
  }, [selectedRows, status])

  useEffect(() => {
    if (isZimmerman === true) {
      if (dashboard?.zimmermanBulkPiers && dashboard?.zimmermanBulkPiers.length !== 0) {
        let blockPierData = dashboard?.zimmermanBulkPiers
        if (blockPierData?.length !== 0) {
          setBlockDetails(blockPierData[0])
        }
      }
    } else {
      if (dashboard?.bulkPiersTorqueTubesBlockData && dashboard?.bulkPiersTorqueTubesBlockData?.piers && Object.keys(blockDetails).length === 0 && selectedRows.length > 0) {
        let blockPierData = dashboard?.bulkPiersTorqueTubesBlockData?.piers
        let trackerRowId = parseInt(selectedRows[0]?.split("-")[1])
        if (blockPierData[trackerRowId] !== undefined && blockPierData[trackerRowId].length !== 0) {
          setBlockDetails(blockPierData[trackerRowId][0])
        }
      }
    }
  }, [selectedRows, status])

  useEffect(() => {
    if (parseInt(status) === 1 && dashboard?.bulkPiersTorqueTubesBlockData && selectedRows?.length === 1) {
      let pierTTObj = {}
      let trackerrowId = parseInt(selectedRows[0].split("-")[1])
      let piersTorqueTubeData = dashboard?.bulkPiersTorqueTubesBlockData
      if (piersTorqueTubeData["piers"][trackerrowId] && piersTorqueTubeData["tt"][trackerrowId]) {
        pierTTObj["piers"] = piersTorqueTubeData["piers"][trackerrowId]
        pierTTObj["torque_tube"] = piersTorqueTubeData["tt"][trackerrowId]
        setPiersTorqueTubeDetails(pierTTObj)
      }
    }

  }, [status])


  const getBomDetails = async () => {
    let bomData = await get_distributed_and_installed_bom_for_activity(selectedActivity, selectedRows[0], dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
    if (bomData) {
      if (Object.keys(bomData).length === 1) {
        for (let i in bomData) {
          setFoundType(i)
          setBomDetails(bomData)
        }
      } else {
        setFoundType("distributed_installed")
        setBomDetails(bomData)
      }
    }
  }
  useEffect(() => {
    if (
      selectedActivity !== "All" && selectedRows.length === 1 &&
      dashboard?.distributedInstalledBom
    ) {
      getBomDetails()
    }

  }, [selectedActivity, status])

  const getDeployedPartCountOfSelectedTrackerrow = () => {
    let deployedCount = 0
    if (selectedRows.length === 1 && parseInt(status) === 1) {
      let trackerrowId_activityId_key = `${selectedRows[0].split("-")[1]}_${JSON.parse(localStorage.getItem("construction-act-id"))}`
      if (constructionProgressTrackerrowWise[trackerrowId_activityId_key]) {
        deployedCount = constructionProgressTrackerrowWise[trackerrowId_activityId_key].deployed_part_count

      }
    }
    return deployedCount
  }

  // useEffect(()=>{
  //   getCpData()
  // },[selectedActivity])

  // const getCpData =()=>{
  //   if(selectedRows.length !== 0){
  //   for(let i of selectedRows){

  //      console.log(constructionProgressTrackerrowWise)
  //     let trackerrowid_act_id = `${i?.split("-")[1]}_${selectedActivity}`
  //     console.log(trackerrowid_act_id)
  //     if(constructionProgressTrackerrowWise){
  //     console.log(constructionProgressTrackerrowWise[trackerrowid_act_id])
  //     }
  //   }
  //   }
  // }

  const handleClickNext = () => {
    setOpenModal(false)
    setSelectedType(1)
    setOpenPierTorqueTubeModal(true)

  }

  const handleClick = async () => {
    try {
      setLoading(true)

      let cpdata = [];
      let checkActivityType = "";

      for (let i of selectedRows) {
        if (
          activities.construction[selectedActivity]?.parent_activity_id === null
        ) {
          checkActivityType = "parent_activity";
        } else {
          checkActivityType = "sub_activity";
        }
        if (checkActivityType === "parent_activity") {
          // let temp = await UpdateCp(selectedActivity, i)
          // cpdata.push(temp);

          for (let actId in activities?.construction) {
            if (activities.construction[actId].parent_activity_id === parseInt(selectedActivity) || parseInt(actId) === parseInt(selectedActivity)) {
              let temp = await UpdateCp(actId, i)
              cpdata.push(temp);
            }
          }
        } else {
          let parentActivityId = activities?.construction[selectedActivity]?.parent_activity_id
          let selectedActivityData = await UpdateCp(selectedActivity, i)
          cpdata.push(selectedActivityData);
          let parentActivityData = await UpdateCp(selectedActivity, i, checkActivityType, parentActivityId)
          cpdata.push(parentActivityData);
        }
      }

      let copyOfTrackerRowWise = lodash.cloneDeep(constructionProgressTrackerrowWise);
      for (let j of cpdata) {
        let trackerActivityKey = `${parseInt(j?.tracker_row_id)}_${j?.activity_id}`;

        if (constructionProgressTrackerrowWise[trackerActivityKey]) {
          copyOfTrackerRowWise[trackerActivityKey].status = j?.status;
          copyOfTrackerRowWise[trackerActivityKey].completion_percentage = j?.completion_percentage;
          copyOfTrackerRowWise[trackerActivityKey].deployed_part_count = j?.deployed_part_count;
          copyOfTrackerRowWise[trackerActivityKey].total_part_count = j?.total_part_count;
          if (parseInt(j?.status) === 1) {
            copyOfTrackerRowWise[trackerActivityKey].piers = j?.piers ? j?.piers : []
            copyOfTrackerRowWise[trackerActivityKey].torque_tubes = j?.torque_tubes ? j?.torque_tubes : []
          }

        } else {
          copyOfTrackerRowWise[trackerActivityKey] = {
            activity_id: j?.activity_id,
            completion_percentage: j?.completion_percentage,
            deployed_part_count: j?.deployed_part_count,
            status: parseInt(j?.status),
            total_part_count: j?.total_part_count,
            selectedRows: j?.tracker_row_id,
            piers: j?.piers ? j?.piers : [],
            torque_tubes: j?.torque_tubes ? j?.torque_tubes : []
          }
        }
      }
      for (let i in dashboard?.updateDistributedAndInstalledQuantity) {
        if (!dashboard?.existing_bom_per_tracker_row[i]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i] = {
                distributed: dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                  ? dashboard?.updateDistributedAndInstalledQuantity[i].Distributed
                  : null,
                installed: dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                  ? dashboard?.updateDistributedAndInstalledQuantity[i].Installed
                  : null,
              };
            })
          );
        }
        if (dashboard?.updateDistributedAndInstalledQuantity[i]["installed"]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i]["installed"] =
                dashboard?.updateDistributedAndInstalledQuantity[i]["installed"];
            })
          );
        }
        if (dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"]) {
          setDashboard(
            produce((draft) => {
              draft.existing_bom_per_tracker_row[i]["distributed"] =
                dashboard?.updateDistributedAndInstalledQuantity[i]["distributed"];
            })
          );
        }
      }
      let cpObj = {}
      let blockId = params.id
      cpObj[blockId] = cpdata
      await insertCPQueue(cpObj);
      await updateExistingDistrbutedInstalled(dashboard?.updateDistributedAndInstalledQuantity);
      setConstructionProgressTrackerrowWise(copyOfTrackerRowWise)
      setInprogressDeployedPartCount(0)
      await getSelectedPiersTorqueTubesData({})
      toast.success(<Msg />, {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
      setLoading(false)
      setbtnenable(false);
      setOpenPierTorqueTubeModal(false)
      updateClearSelectedTrackerRows(!dashboard?.clearSelectedTrackerRows)
    } catch (err) {
      console.log(err);
    }
  };
  const getLinkedPartName = () => {
    let partName = ""
    if (activities?.construction && localStorage.getItem("construction-act-id") !== "All" && activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]) {
      let activityId = JSON.parse(localStorage.getItem("construction-act-id"))
      for (let i of listParts) {
        if (i.id === activities?.construction[JSON.parse(localStorage.getItem("construction-act-id"))]?.part_id) {
          partName = i?.data?.part_name
        }

      }
    }
    return partName
  }


  const UpdateCp = async (selected_activity_id, selectedTrackerrow, activityType, parentActivityId) => {
    let updatedData = {}
    let bomData = await get_distributed_and_installed_bom_for_activity(selected_activity_id, selectedTrackerrow, dashboard?.distributedInstalledBom, dashboard?.total_installed_distributed, dashboard?.existing_bom_per_tracker_row, isZimmerman, dashboard?.zimmermanTableWisePierPartBom)
    if (isZimmerman === true) {
      updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.zimmermanBulkPiers,blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, activities?.construction, selectedTrackerrow, inprogressDeployedPartCount, dashboard?.listOfZimmermanPierIdsWithStatus, isZimmerman, activityType, parentActivityId, constructionProgressTrackerrowWise)
    } else {
      updatedData = await get_updated_bom_for_distributed_installed_for_selected_activity_trackerow(dashboard?.bulkPiersTorqueTubesBlockData,blockDetails, bomData, selected_activity_id, dashboard?.updateDistributedAndInstalledQuantity, parseInt(status), foundType, dashboard?.part_per_tracker, activities?.construction, selectedTrackerrow, inprogressDeployedPartCount, dashboard?.listOfPiersTorqueTubeIdsWithStatus, isZimmerman, activityType, parentActivityId, constructionProgressTrackerrowWise)
    }
    return updatedData

  };

  const checkSubActivity = () => {
    let temp = false;
    if (
      selectedActivity !== "All" &&
      activities.construction[selectedActivity]?.parent_activity_id === null
    ) {
      for (let i in activities?.construction) {
        if (activities.construction[i]?.parent_activity_id === parseInt(selectedActivity)) {
          temp = true;
        }
      }
    }

    return temp;
  };
  const btnDisable = () => {
    let result = true;
    if (status !== "" && selectedRows.length !== 0) {
      result = false;
    }
    if (parseInt(status) === 1 && selectedRows.length > 1) {
      result = true;
    }

    return result;
  };
  const progressDisabled = () => {
    let isDisable = true
    if (selectedRows.length !== 0 && localStorage.getItem("construction-act-id") !== "All") {

      isDisable = false
    }
    return isDisable
  }

  useEffect(() => {
    setSelectedActivity(localStorage.getItem("construction-act-id"));
  }, [selectedActivity]);

  const handleOpenChecksheet = () => {
    if (selectedRows.length === 1) {
      let trackerrowId = parseInt(selectedRows[0]?.split("-")[1])
      let trackertypeId = parseInt(selectedRows[0]?.split("-")[2])
      navigate(
        `/home/gpslocation/${params.id}/constructionprogresschecksheet/${trackertypeId}/${trackerrowId}`
      );
    }

  }

  return (
    <Box>
      <Box
        style={{
          height: "80vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ padding: "12px 20px", }}>
          <Typography sx={{ paddingBottom: "5px", fontWeight: 500, fontSize: "16px" }}>Checklist</Typography>
          <Box sx={{ marginRight: "5px" }}>
            <Button
              disabled={
                selectedRows.length === 0 || selectedRows.length > 1
                  ? true
                  : false
              }
              sx={{

                backgroundColor:
                  selectedRows.length === 0 || selectedRows.length > 1
                    ? "rgba(105, 111, 121, 0.08)"
                    : "#186DEC",
                border: "1px solid rgba(105, 111, 121, 0.48)",
                height: "40px",
                width: "100%",
                textTransform: "none",
                color:
                  selectedRows.length === 0 || selectedRows.length > 1
                    ? "#1B1819"
                    : "#FFFFFF",
                borderRadius: "5px",

              }}
              onClick={handleOpenChecksheet}
            >{
                selectedRows.length === 0 || selectedRows.length > 1 ?
                  <Box sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",

                  }}>
                    <GrCircleInformation style={{ margin: "0px 8px", }} fontSize={18} />
                    <Typography sx={{ fontSize: "14px" }}>{isZimmerman === true ? "Select a table for the checklist" : "Select a tracker for the checklist"}</Typography>
                  </Box> : (
                    <Box sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      width: "100%",
                      padding: "10px",
                    }}>
                      <Typography sx={{ fontSize: "14px" }}>Open Checklist</Typography>
                      <EastIcon />
                    </Box>
                  )

              }
            </Button>
          </Box>
          <Typography sx={{ paddingTop: "15px", fontWeight: 500, fontSize: "16px" }}>Bulk Update</Typography>
          <Box sx={{ marginY: "15px" }}>

            <InputLabel
              sx={{
                color: selectedRows.length > 0 ? "black" : "#696F79",
                padding: "7px 0px",
                // fontWeight: selectedRows.length > 0 ? "500" : "",
              }}
            >
              {localStorage.getItem("construction-act-id") === "All" ? "Activity" : (
                <>
                  {activities?.construction && activities?.construction[
                    activities?.construction[localStorage.getItem("construction-act-id")]?.parent_activity_id
                  ]
                    ? activities?.construction[
                      activities?.construction[localStorage.getItem("construction-act-id")]
                        ?.parent_activity_id
                    ]?.name
                    : "Activity"}
                </>)
              }
            </InputLabel>

            <ActivitySelect
              placeholder="Select an Activity"
              activities={activities?.construction}
              activities_disabled={false}
              sub_activities_disabled={false}
              multiselect={false}
              onChange={handleProgressChange}
              width="27vw"
              // disabled={selectedRows.length > 0 ? false : true}
              textbold={false}
              empty={status === "" ? true : false}
              act={
                localStorage.getItem("construction-act-id") ? localStorage.getItem("construction-act-id") : null
              }
              border="1px solid grey"
              fontSize="14px"
            />
          </Box>
          <Box>
            <InputLabel
              sx={{
                color: progressDisabled() === false ? "black" : "#696F79",
                padding: " 7px 0px ",
                fontWeight: progressDisabled() === false ? "500" : "normal",
              }}
            >
              Status
            </InputLabel>
            <Box sx={{ display: "flex", padding: " 7px 0px " }}>
              <InputLabel
                sx={{
                  height: "40px",
                  width: "40%",
                  color: parseInt(status) === 0 ? "white" : "black",
                  backgroundColor:
                    parseInt(status) === 0 && selectedRows.length > 0
                      ? "#03AB5A"
                      : "white",
                  border:
                    parseInt(status) === 0 && selectedRows.length > 0
                      ? "1px solid #03AB5A"
                      : "1px solid #696F79",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "5px",
                  padding: "0 7px",
                  fontSize: "14px",
                }}
                value="Not Started"
                onClick={() => {
                  if (selectedRows.length > 0) {
                    setStatus(0);
                  }
                }}
                disabled={progressDisabled() === false ? false : true}
              >
                Not Started
              </InputLabel>
              {process.env.REACT_APP_INPROGRESS === "true" ?
                <InputLabel
                  value="In Progress"
                  onClick={() => {
                    if (
                      selectedRows.length === 1 &&
                      checkSubActivity() === false
                    ) {
                      setStatus(1);
                    }
                  }}
                  disabled={
                    progressDisabled() === false && checkSubActivity() === false && selectedRows.length === 1
                      ? false
                      : true
                  }
                  sx={{
                    height: "40px",
                    width: "40%",
                    color: parseInt(status) === 1 ? "white" : "black",
                    backgroundColor:
                      parseInt(status) === 1 &&
                        selectedRows.length === 1 &&
                        checkSubActivity() === false
                        ? "#03AB5A"
                        : "white",
                    border:
                      parseInt(status) === 1 &&
                        selectedRows.length === 1 &&
                        checkSubActivity() === false
                        ? "1px solid #03AB5A"
                        : "1px solid #696F79",
                    borderRadius: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "5px",
                    padding: "0 7px",
                    fontSize: "14px",
                  }}
                >
                  In Progress
                </InputLabel> : null}
              <InputLabel
                sx={{
                  height: "40px",
                  width: "40%",
                  color: parseInt(status) === 2 ? "White" : "black",
                  backgroundColor:
                    parseInt(status) === 2 && selectedRows.length > 0
                      ? "#03AB5A"
                      : "white",
                  border:
                    parseInt(status) === 2 && selectedRows.length > 0
                      ? "1px solid #03AB5A"
                      : "1px solid #696F79",
                  borderRadius: "30px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "5px",
                  padding: "0 7px",
                  fontSize: "14px",
                }}
                disabled={progressDisabled() === false ? false : true}
                onClick={() => {
                  if (selectedRows.length > 0) {
                    setStatus(2);
                  }
                }}
                value="Completed"
              >
                Completed
              </InputLabel>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            variant="contained"
            sx={{
              width: "85%",
              height: "64px",
              borderRadius: "6px",
              marginBottom: "8vh",
              textTransform: "none",
              fontSize: "16px"
            }}
            onClick={
              parseInt(status) === 1
                ? () => setOpenModal(true)
                : () => handleClick()}
            disabled={btnDisable()}
          >

            {loading ? <CircularProgress size={22} style={{ color: "white", marginRight: "12px", }} /> : null}
            Apply
          </Button>
        </Box>
      </Box>
      {/* modal */}
      {/* <Box sx={{ height: "80vh" }}>
          <BlockView
            value={false}
            fromCheckSheet={true}
            CpRow={selectedRows[0]?.split("-")[3]}
            CpSection={selectedRows[0]?.split("-")[4]}
            selectedD3View={0}
          />
        </Box>  */}

      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "97vh",
            width: "97vw",
            backgroundColor: "#FFFFFF",
            color: "black",
            overflow:"auto"
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
              margin: "5px"
            }}
          >

            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {isZimmerman === true ?
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {blockDetails?.block}, Row {selectedRows[0]?.split("-")[4]}, Table
                  {selectedRows[0]?.split("-")[3]}
                </Typography> :
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                  {blockDetails?.row}
                </Typography>}
              <Typography sx={{ color: "#696F79", fontSize: "16px", paddingY: "3px" }}>
                {trackerTypeDetails?.tracker_type}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CloseIcon
                sx={{ padding: "10px 0  10px 15px" }}
                onClick={handleClose}
              />
            </Box>
          </Box>
          <Divider />

          <Box sx={{ display: "flex", flexDirection: "column", marginX: "5px" }}>
            {Object.keys(bomDetails).length > 0 ?
              <Box>
                <Typography sx={{ fontWeight: 600, fontSize: "18px", paddingX: "10px", paddingY: "15px" }}>Enter distributed and installed counts below for BOM tracking</Typography>
                {foundType === "distributed" ?
                  < ConstructionInprogressTable rows={InprogressDistributedRows} foundType={foundType} bomDetails={bomDetails["distributed"]} trackerrowId={selectedRows[0]?.split("-")[1]} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} /> : null}
                {foundType === "installed" ?
                  < ConstructionInprogressTable rows={InProgressInstalledRows} foundType={foundType} bomDetails={bomDetails["installed"]} trackerrowId={selectedRows[0]?.split("-")[1]} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} /> : null}

                {foundType === "distributed_installed" ?
                  <Box >
                    <Box sx={{ marginY: "10px" }}>
                      < ConstructionInprogressTable rows={InprogressDistributedRows} foundType={foundType} bomDetails={bomDetails["distributed"]} trackerrowId={selectedRows[0]?.split("-")[1]} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData}/>
                    </Box>
                    <Box sx={{ marginY: "10px" }}>
                      < ConstructionInprogressTable rows={InProgressInstalledRows} foundType={foundType} bomDetails={bomDetails["installed"]} trackerrowId={selectedRows[0]?.split("-")[1]} isZimmerman={isZimmerman} clearTableData={clearInprogressTableData} />
                    </Box>
                  </Box>
                  : null}
              </Box>
              : null}

            <Box sx={{ display: "flex", flexDirection: "column", marginX: "5px" }}>
              <Typography sx={{ fontWeight: 600, fontSize: "18px", paddingX: "5px", paddingY: "15px" }}>Enter linked part counts for progress calculation</Typography>
              <Box sx={{ margin: "5px", display: "flex", alignItems: "center", justifyContent: "start", }}>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "start", }}>
                  <Typography sx={{ fontSize: "14px", paddingRight: "5px" }}>Linked Part {getLinkedPartName()}</Typography>
                  <TextField
                    defaultValue={inprogressDeployedPartCount !== 0 ? inprogressDeployedPartCount : getDeployedPartCountOfSelectedTrackerrow()}
                    name="linked part quantity"
                    onChange={(e) => handleInprogressDeployedPartCount(e)}
                    sx={{
                      // ".MuiOutlinedInput-notchedOutline": { border: 0 },
                      width: "150px",
                      paddingLeft: "10px"
                    }}
                    InputProps={{
                      inputProps: {
                        type: "number",
                        max: inprogressTotalPartCount,
                        min: 0,
                      },
                    }}
                  />
                </Box>
                <Typography sx={{
                  fontSize: "55px",
                  fontWeight: 100,
                  marginX: "10px",
                }}>/</Typography>
                <Typography sx={{ fontSize: "18px" }}>{inprogressTotalPartCount}</Typography>
              </Box>
            </Box>
            <Box sx={{ position: "absolute", bottom: 10, right: 5 }}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "end",
                  margin: "15px",
                }}
              >
                <Button
                  variant="contained"
                  size="medium"
                  sx={{ borderRadius: "6px", padding: "20px 56px" }}
                  onClick={() => {
                    handleClickNext();
                  }}
                  disabled={btnenable ? false : true}
                >
                  Next
                </Button>
              </Box>
            </Box>

          </Box>

        </Box>
      </Modal >

      <Modal
        open={openPierTorqueTubeModal}
        onClose={() => setOpenPierTorqueTubeModal(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "90vh",
            width: "60vw",
            backgroundColor: "#FFFFFF",
            color: "black",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingLeft: "10px",
              margin: "5px"
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                {blockDetails?.block}, Section {blockDetails?.section}, Row{" "}
                {blockDetails?.row}
              </Typography>
              <Typography sx={{ color: "#696F79", fontSize: "16px", paddingY: "3px" }}>
                {trackerTypeDetails?.tracker_type}
              </Typography>
            </Box>
            {/* <Box sx={{ marginLeft: "25px" }}>
              <SegmentedControl
                name="group"
                callback={(val) => {
                  setSelectedType(val);
                }}
                controlRef={useRef()}
                segments={[
                  {
                    label: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Piers</Typography>
                      </Box>
                    ),
                    value: 1,
                    ref: useRef(),
                  },
                  {
                    label: (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography>Torque Tubes</Typography>
                      </Box>
                    ),
                    value: 0,
                    ref: useRef(),
                  },
                ]}
              />

            </Box> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 20px",
              }}
            >
              <CloseIcon
                sx={{ padding: "10px 0  10px 15px" }}
                onClick={handleClosePierTorqueTubeModal}
              />
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: "10px 15px" }}>
            <Typography>Choose piers and bays that are completed</Typography>
          </Box>

          <Box sx={{ display: "flex", justifyContent: "center", }}>
            {isZimmerman === true ?
              <ConstructionProgressPierTTSelection toggleValue={selectedType} pierTorqueTubeData={zimmermanPierData} selectedTrackerrow={selectedRows} activityId={localStorage.getItem("construction-act-id")} constructionProgressTrackerrowWise={constructionProgressTrackerrowWise} clearState={openPierTorqueTubeModal} />
              :
              <ConstructionProgressPierTTSelection toggleValue={selectedType} pierTorqueTubeData={piersTorqueTubesDetails} selectedTrackerrow={selectedRows} activityId={localStorage.getItem("construction-act-id")} constructionProgressTrackerrowWise={constructionProgressTrackerrowWise} clearState={openPierTorqueTubeModal} />
            }

            <Box sx={{
              position: "absolute",
              bottom: 10,
              left: 5,
              margin: "10px",
            }}>
              <Button
                variant="contained"
                size="medium"
                sx={{ borderRadius: "6px", padding: "10px 40px" }}
                onClick={() => {
                  setOpenModal(true)
                  setOpenPierTorqueTubeModal(false)
                }}
              // disabled={btnenable ? false : true}
              >
                Back
              </Button>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 10,
                right: 5,
                margin: "10px",
              }}
            >

              <Button
                variant="contained"
                size="medium"
                sx={{ borderRadius: "6px", padding: "10px 40px" }}
                onClick={() => {
                  handleClick();
                }}
              // disabled={btnenable ? false : true}
              >
                Confirm
              </Button>
            </Box>
          </Box>

        </Box>

      </Modal >

      <ToastContainer
        position="bottom-right"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
        style={{ width: "440px" }}
      />
    </Box >
  );
};

export default ConstructionProgress;
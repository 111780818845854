import { atom } from "jotai";
import produce from "immer";
import { get_project_details, insert_project_details } from "../dal/project";

const projectAtom = atom({
  projectDetails: [],
  projectDetailsDb: []
});

export const fetchProjectAtom = atom(null, async (get, set, args) => {
  let data = await insert_project_details()
  set(projectAtom, produce((draft) => {
    draft.projectDetails = data
  }
  ));

});

export const fetchProjectDetailsAtom = atom(null, async (get, set, args) => {
  let data = await get_project_details()
  set(projectAtom, produce((draft) => {
    draft.projectDetailsDb = data
  }))
});

export default projectAtom;

import axios from "axios";
import surya_commondb from "../indexedDB/imageModule";
import { convertBase64ToFile } from "../utils/decoder";
import { v4 as uuid } from "uuid";
import { toBase64 } from "../utils/serializer";
import { getToken } from "../utils/auth";
import produce from "immer";

const PROJECT_ID = localStorage.getItem("project_id")
// Returns selected punchlist value
const getSelectedVisibilityImages = async (blockId, visibility) => {
    let data = await surya_commondb().images.toArray()
    let imagesData = {}

    let punchlist = "external"
    if (parseInt(visibility) === 0) {
        punchlist = "internal"
    }
    let key = `${PROJECT_ID}_${blockId}_${punchlist}`
    for (let i of data) {
        if (i?.id === key) {
            imagesData = i
        }
    }
    return imagesData
}

// To check userGeneratedId
const checkUserGeneratedID = (imgID) => {
    if (imgID.startsWith("ug")) {
        return true
    }
    return false
}

// Add images to indexedDB while the user is in offline mode
export async function offline_add_images(blockId, visibility, imageObj) {
    let punchlist = "external"
    if (parseInt(visibility) === 0) {
        punchlist = "internal"
    }
    let key = `${PROJECT_ID}_${blockId}_${punchlist}`
    let imageData = await getSelectedVisibilityImages(blockId, visibility)
    if (Object.keys(imageData).length !== 0) {
        for (let i in imageObj) {
            imageData["data"][i] = imageObj[i]
        }
        await surya_commondb().images.put({
            id: imageData?.id,
            data: imageData["data"]
        })
    } else {
        await surya_commondb().images.put({
            id: key,
            data: imageObj
        })
    }
}
// delete images from indexedDB while the user is in offline mode
export async function delete_images(blockId, visibility, imageId) {
    let imageData = await getSelectedVisibilityImages(blockId, visibility)
    let newImagesData = {}
    for (let i in imageData?.data) {
        if (parseInt(i) !== imageId) {
            newImagesData[i] = imageData["data"][i]
        }
    }
    await surya_commondb().images.put({
        id: imageData?.id,
        data: newImagesData
    })
}
// Upload images from indexedDB to server through api while the user is in online mode
export async function upload_add_images(blockId, visibility, images) {
    let stored_images = await getSelectedVisibilityImages(blockId, visibility);
    let uploaded_img_ids = [];
    for (let imgId in images) {
        let existing_img_id = null;
        if (images[imgId] !== undefined) {
            // check if the image already exists
            if (Object.keys(stored_images["data"]).length > 0) {
                existing_img_id = Object.keys(stored_images["data"]).find(k => k === imgId)
            }
            if (existing_img_id && !checkUserGeneratedID(existing_img_id)) {
                uploaded_img_ids.push({ upload_id: parseInt(existing_img_id) });
            } else {
                try {

                    let fileFormData = new FormData();
                    let converted = convertBase64ToFile(images[imgId]);
                    let tFile = new File(
                        [converted.blob],
                        `${uuid()}.${converted.type.split("/")[1]}`
                    );
                    fileFormData.append("file", tFile);
                    let uploadId = await axios.post(
                        `/file_upload/upload/document`,
                        fileFormData,
                        { headers: { "Content-Type": "multipart/form-data" } }
                    );
                    uploaded_img_ids.push({ upload_id: parseInt(uploadId.data) });
                    stored_images["data"][uploadId.data] = stored_images["data"][imgId]
                    delete stored_images["data"][imgId]

                } catch (err) {
                    console.log(err)
                }
            }
        }

    }
    await surya_commondb().images.put({
        id: stored_images?.id,
        data: stored_images["data"]
    })
    return uploaded_img_ids
}

// Get list of  images from indexedDB 
export async function get_list_of_images(blockId, visibility, imagesId) {
    let imageData = await getSelectedVisibilityImages(blockId, visibility)
    let images = {}
    if (imagesId && imagesId.length > 0) {
        for (let i of imagesId) {
            if (imageData["data"][i]) {
                images[i] = imageData?.data[i]
            }
            if (i?.upload_id && imageData?.data[i?.upload_id]) {
                images[i?.upload_id] = imageData?.data[i?.upload_id]
            }
        }
    }
    return images
}
// Get list of  images from indexedDB 
// export async function get_all_images(block_id,visibility) {
//     let imageData = await getSelectedVisibilityImages(visibility)
//     return imageData["data"]
// }

const get_difference_between_image_ids_array = async (imageIdsFromServer, imagesIdsInIndexDB) => {

    // Convert arrays to sets
    const setOfImagesFromServer = new Set(imageIdsFromServer);
    const setOfImagesFromIndexedDB = new Set(imagesIdsInIndexDB);
    // Find the difference
    const idsFromServerNotInIndexedDb = [...setOfImagesFromServer].filter(item => !setOfImagesFromIndexedDB.has(item));
    const idsFromIndexedNotInServer = [...setOfImagesFromIndexedDB].filter(item => !setOfImagesFromServer.has(item));
    return idsFromServerNotInIndexedDb
}

// export async function internal_image_ids_to_be_downloaded(imageIdsFromServer, visibility) {

//     let imageData = await getSelectedVisibilityImages(visibility)
//     let key = `internal_${PROJECT_ID}`
//     let prevousImageIds = await surya_commondb().imageids_queue.toArray()
//     console.log(prevousImageIds)
//     if (prevousImageIds !== undefined && prevousImageIds.length !== 0) {
//         for (let i of prevousImageIds) {
//             console.log(i?.id === key)
//             if (i?.id === key) {
//                 prevousImageIds = i?.data
//             }
//         }
//     }
//     let imagesIdsInIndexDB = []
//     let imagesIds = []
//     let imageIdsCopy = []
//     try {
//         if (Object.keys(imageData).length !== 0) {
//             imagesIdsInIndexDB = Object.keys(imageData["data"]).map(key => parseInt(key))
//         }
//         // console.log("server internal", imageIdsFromServer)
//         // console.log("idb internal", imagesIdsInIndexDB)
//         imagesIds = await get_difference_between_image_ids_array(imageIdsFromServer.sort(), imagesIdsInIndexDB.sort())
//         if (prevousImageIds !== undefined && prevousImageIds.length !== 0) {
//             imageIdsCopy = [...prevousImageIds, ...imagesIds]
//         } else {
//             imageIdsCopy = imagesIds
//         }

//         console.log("diff internal", imageIdsCopy)
//         if (imageIdsCopy.length > 0) {
//             await surya_commondb().imageids_queue.put({
//                 id: `internal_${PROJECT_ID}`,
//                 data: imageIdsCopy
//             })
//         }

//     } catch (err) {
//         console.log(err)
//     }

//     // return imagesIds

// }
// export async function public_image_ids_to_be_downloaded(imageIdsFromServer, visibility) {
//     let imageData = await getSelectedVisibilityImages(visibility)
//     let key = `external_${PROJECT_ID}`
//     let prevousImageIds = await surya_commondb().imageids_queue.toArray()
//     console.log(prevousImageIds)
//     if (prevousImageIds !== undefined && prevousImageIds.length !== 0) {
//         for (let i of prevousImageIds) {
//         console.log(i?.id === key)
//             if (i?.id === key) {
//                 prevousImageIds = i?.data
//             }
//         }
//     }
//     let imagesIdsInIndexDB = []
//     let imagesIds = []
//     let imageIdsCopy = []
//     try {
//         if (Object.keys(imageData).length !== 0) {
//             imagesIdsInIndexDB = Object.keys(imageData["data"]).map(key => parseInt(key))
//         }
//         // console.log("server public", imageIdsFromServer)
//         // console.log("idb public", imagesIdsInIndexDB)
//         imagesIds = await get_difference_between_image_ids_array(imageIdsFromServer.sort(), imagesIdsInIndexDB.sort())
//         if (prevousImageIds !== undefined && prevousImageIds.length !== 0) {
//             imageIdsCopy = [...prevousImageIds, ...imagesIds]

//         } else {
//             imageIdsCopy = imagesIds
//         }
//         console.log("diff public", imageIdsCopy)
//         if (imageIdsCopy.length > 0) {
//             await surya_commondb().imageids_queue.put({
//                 id: `external_${PROJECT_ID}`,
//                 data: imageIdsCopy
//             })
//         }
//     } catch (err) {
//         console.log(err)
//     }
//     // return imagesIds
// }

export async function internal_image_ids_to_be_downloaded(blockId, imageIdsFromServer, visibility) {
    let imageData = await getSelectedVisibilityImages(blockId, visibility);
    let key = `${PROJECT_ID}_${blockId}_internal`;
    let imageIdsData = await surya_commondb().imageids_queue.toArray();
    // console.log("Initial previousImageIds:", imageIdsData);
    let existingData = [];
    if (imageIdsData.length > 0) {
        for (let i of imageIdsData) {
            if (i?.id === key) {
                existingData = i?.data || [];
                break;
            }
        }
    }
    // console.log("Existing data for key:", existingData);
    let imagesIdsInIndexDB = [];
    if (Object.keys(imageData).length !== 0) {
        imagesIdsInIndexDB = Object.keys(imageData["data"]).map(key => parseInt(key));
    }

    // console.log("Image IDs in IndexDB:", imagesIdsInIndexDB);

    let imagesIds = await get_difference_between_image_ids_array(imageIdsFromServer.sort(), imagesIdsInIndexDB.sort());

    // console.log("Difference in image IDs:", imagesIds);

    let imageIdsCopy = [...new Set([...existingData, ...imagesIds])];

    // console.log("Merged image IDs (diff internal):", imageIdsCopy);

    if (imageIdsCopy.length > 0) {
        await surya_commondb().imageids_queue.put({
            id: key,
            data: imageIdsCopy
        });
    }
}

export async function public_image_ids_to_be_downloaded(blockId, imageIdsFromServer, visibility) {
    let imageData = await getSelectedVisibilityImages(blockId, visibility);
    let key = `${PROJECT_ID}_${blockId}_external`;
    let imageIdsData = await surya_commondb().imageids_queue.toArray();

    let existingData = [];
    if (imageIdsData.length > 0) {
        for (let i of imageIdsData) {
            if (i?.id === key) {
                existingData = i?.data || [];
                break;
            }
        }
    }

    let imagesIdsInIndexDB = [];
    if (Object.keys(imageData).length !== 0) {
        imagesIdsInIndexDB = Object.keys(imageData["data"]).map(key => parseInt(key));
    }

    let imagesIds = await get_difference_between_image_ids_array(imageIdsFromServer.sort(), imagesIdsInIndexDB.sort());

    let imageIdsCopy = [...new Set([...existingData, ...imagesIds])];

    // console.log("diff public", imageIdsCopy);
    if (imageIdsCopy.length > 0) {
        await surya_commondb().imageids_queue.put({
            id: key,
            data: imageIdsCopy
        });
    }
}



export async function get_user_generated_id_for_uploaded_images(imageUrls) {
    let imagesWithUserGeneratedId = {}
    for (let i of imageUrls) {
        let id = `ug${Math.round(Math.random() * 1000)}`
        imagesWithUserGeneratedId[id] = await toBase64(i)
    }
    return imagesWithUserGeneratedId

}

export async function fetchImages(updateSyncStatus) {
    let imageIdsData = await surya_commondb().imageids_queue.toArray()
    let totalImages = 0
    let count = 0
    for (let j of imageIdsData) {
        totalImages += j?.data.length
    }
    if (imageIdsData !== undefined || imageIdsData.length !== 0) {
        for (let j of imageIdsData) {
            let punchlist = j?.id.split("_")[2]
            let images_data = await surya_commondb().images.toArray()
            let existing_internal_images_list = {}
            let existing_public_images_list = {}
            let internalImageUrls = []
            let publicImageUrls = []
            
            for (let obj of images_data) {
                if (obj?.id === j?.id) {
                    existing_internal_images_list = obj["data"]
                }
                if (obj?.id === j?.id) {

                    existing_public_images_list = obj["data"]

                }
            }
            if (j?.data && punchlist === "internal") {
                for (let i of j?.data) {
                    try {
                        internalImageUrls.push({ url: `/file_upload/download?id=${i}`, uploadId: i });
                    } catch (err) {
                        console.log(err);
                    }
                }
                let internalPunchlistPhotos = await Promise.all(internalImageUrls.map((endpoint) => axios.get(endpoint.url)))

                for (let i in internalPunchlistPhotos) {
                    count += 1
                    updateSyncStatus(produce((draft) => { draft.totalImages = totalImages; draft.downloadedImages = count }));
                    let res = await fetch(internalPunchlistPhotos[i].data)
                    let blob = await res.blob();
                    let base64url = await toBase64(blob);
                    let id = internalImageUrls[i].uploadId
                    if (existing_internal_images_list !== undefined && Object.keys(existing_internal_images_list).length > 0) {
                        Object.assign(existing_internal_images_list, { [id]: base64url });
                    } else {
                        existing_internal_images_list[id] = base64url
                    }
                }
                await surya_commondb().images.put({
                    id: j?.id,
                    data: existing_internal_images_list
                })
            } else {
                await surya_commondb().images.put({
                    id: j?.id,
                    data: existing_internal_images_list
                })
            }
            if (j?.data && punchlist === "external") {
                for (let i of j?.data) {
                    try {
                        publicImageUrls.push({ url: `/file_upload/download?id=${i}`, uploadId: i });
                    } catch (err) {
                        console.log(err);
                    }
                }
                let publicPunchlistPhotos = await Promise.all(publicImageUrls.map((endpoint) =>
                    axios.get(endpoint.url)))
                for (let i in publicPunchlistPhotos) {
                    count += 1
                    updateSyncStatus(produce((draft) => { draft.totalImages = totalImages; draft.downloadedImages = count }));
                    let res = await fetch(publicPunchlistPhotos[i].data)
                    let blob = await res.blob();
                    let base64url = await toBase64(blob);
                    let id = publicImageUrls[i].uploadId
                    if (existing_public_images_list !== undefined && Object.keys(existing_public_images_list).length > 0) {
                        Object.assign(existing_public_images_list, { [id]: base64url });
                    } else {
                        existing_public_images_list[id] = base64url
                    }
                }
                await surya_commondb().images.put({
                    id: j?.id,
                    data: existing_public_images_list
                })
            } else {
                await surya_commondb().images.put({
                    id: j?.id,
                    data: existing_public_images_list
                })
            }
            await surya_commondb().imageids_queue.clear()
        }
    }
}

const getHeaders = async () => {
    const token = await getToken();
    return {
        'Accept': "application/json, text/plain, */*",
        'X-Device': 'tablet',
        'Authorization': `Bearer ${token}`,
        'project_id': PROJECT_ID,
        'X-From-Axios': 'true',

    };
};


export async function backgroundFetchImages() {
    let internalImageIds = [];
    let publicImageIds = [];
    let imageIdsData = await surya_commondb().imageids_queue.toArray();

    for (let obj of imageIdsData) {
        if (obj["id"] === `internal_${PROJECT_ID}`) {
            internalImageIds = obj["data"];
        }
        if (obj["id"] === `external_${PROJECT_ID}`) {
            publicImageIds = obj["data"];
        }
    }

    if (internalImageIds.length >= 0 || publicImageIds.lengt >= 0) {
        let images_data = await surya_commondb().images.toArray();
        let existing_internal_images_list = {};
        let existing_public_images_list = {};

        for (let obj of images_data) {
            if (obj["id"] === `internal_${PROJECT_ID}`) {
                existing_internal_images_list = obj["data"];
            }
            if (obj["id"] === `external_${PROJECT_ID}`) {
                existing_public_images_list = obj["data"];
            }
        }

        let count = 0;

        if ('serviceWorker' in navigator && navigator.serviceWorker.controller) {
            const commonHeaders = await getHeaders();
            const fetchImagesWithServiceWorker = async (urls) => {
                const fetchPromises = urls.map(url =>
                    fetch(url, {
                        headers: commonHeaders
                    }).then(response => {
                        return response.json();
                    })
                );
                return Promise.all(fetchPromises);
            };

            if (internalImageIds.length > 0) {
                localStorage.setItem("Total images to be downloaded", internalImageIds.length + publicImageIds.length);
                localStorage.setItem("Images downloaded count", count);

                const internalImageUrls = internalImageIds.map(id => ({
                    url: `https://main-api.suryaweb.app/api/file_upload/download?id=${id}`,
                    uploadId: id
                }));

                const internalPunchlistPhotos = await fetchImagesWithServiceWorker(internalImageUrls.map(endpoint => endpoint.url));

                for (let i = 0; i < internalPunchlistPhotos.length; i++) {
                    const photo = internalPunchlistPhotos[i];
                    const res = await fetch(photo);
                    const blob = await res.blob();
                    const base64url = await toBase64(blob);
                    const id = internalImageUrls[i].uploadId;
                    if (existing_internal_images_list !== undefined && Object.keys(existing_internal_images_list).length > 0) {
                        Object.assign(existing_internal_images_list, { [id]: base64url });
                    } else {
                        existing_internal_images_list[id] = base64url;
                    }
                }

                await surya_commondb().images.put({
                    id: `internal_${PROJECT_ID}`,
                    data: existing_internal_images_list
                });
            }

            if (publicImageIds.length > 0) {
                count += 1;
                localStorage.setItem("Images downloaded count", count);

                const publicImageUrls = publicImageIds.map(id => ({
                    url: `https://main-api.suryaweb.app/api/file_upload/download?id=${id}`,
                    uploadId: id
                }));

                const publicPunchlistPhotos = await fetchImagesWithServiceWorker(publicImageUrls.map(endpoint => endpoint.url));

                for (let i = 0; i < publicPunchlistPhotos.length; i++) {
                    const photo = publicPunchlistPhotos[i];
                    const res = await fetch(photo);
                    const blob = await res.blob();
                    const base64url = await toBase64(blob);
                    const id = publicImageUrls[i].uploadId;
                    if (existing_public_images_list !== undefined && Object.keys(existing_public_images_list).length > 0) {
                        Object.assign(existing_public_images_list, { [id]: base64url });
                    } else {
                        existing_public_images_list[id] = base64url;
                    }
                }

                await surya_commondb().images.put({
                    id: `external_${PROJECT_ID}`,
                    data: existing_public_images_list
                });
            }
        } else {
            console.error('Service Worker is not available or not controlled');
        }
    }
}



import { atom } from "jotai";
import { update_construction_progress_indexedDB } from "../dal/constructionProgress";

const cpQueueAtom = atom();

export const insertCPQueueAtom = atom(
  null,
  async (get, set, args) => {
    let data = update_construction_progress_indexedDB(args)
  })

export default cpQueueAtom;

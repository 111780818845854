import { atom } from "jotai";

const syncStatusAtom = atom({
    syncing: true,
    status: "Syncing",
    value: 0,
    block_name: "All",
    sync_mode: "Both",
    block_id: 0,
    is_background: false,
    sync_text: "Syncing",
    syncInitiated:false,
    totalImages:0,
    downloadedImages:0
});

export default syncStatusAtom;

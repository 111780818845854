import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/home";
import Login from "./pages/login/login";
import GpsLocation from "./pages/pierList/pierList";
import Dashboard from "./pages/home/dashboard";
import ConstructionProgress from "./pages/home/constructionProgress/constructionProgress";
import QCPunchlist from "./pages/home/qcPunchlist";
import QcData from "./pages/home/qualityControl/qualityControl";
import ConstructionProgressChecksheet from "./pages/home/constructionProgress/constructionProgressChecksheet";
import QcDataChecksheet from "./pages/home/qualityControl/qcChecksheet";
import authAtom from "./data/authAtom";
import { useAtom } from "jotai";
import Root from "./pages/misc/root";
import NotFound from "./pages/misc/notFound";
import Install from "./pages/install/install";
import ProfileSettings from "./pages/profileSettings/profileSettings";
import ProfileDetails from "./pages/profileSettings/profileDetails";
import { Box, Typography, Button, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ResetPassword from "./pages/login/resetPassword";
import QCMapView from "./pages/home/qualityControl/qcMapView";
import ErrorFallBack from "./pages/error/errorFallBack";
import * as Sentry from "@sentry/react";

function App() {
  const [auth] = useAtom(authAtom);
  const [potrait, setPotrait] = useState(false);
  const [openModal, setOpenModal] = useState(false);


  // Disabled AutoSync

  // async function handleConnection() {
  //   // handle online status
  //   if (navigator.onLine) {
  //     updateSync({
  //       syncing: true,
  //       status: "Syncing",
  //     });
  //   }
  // }

  // useEffect(() => {
  //   window.addEventListener("online", handleConnection);

  //   return () => {
  //     window.removeEventListener("online", handleConnection);
  //   };
  // }, []);


  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const togglePotrait = () => {
    if (window.innerWidth < 1000) {
      setPotrait(true);
    } else {
      setPotrait(false);
    }
  };

  const handlePotraitChange = () => {
    if (window.innerWidth < 1000) {
      handleOpenModal()
    } else {
      setPotrait(false);
    }
  }

  useEffect(() => {
    togglePotrait();
    window.addEventListener("resize", (event) => {
      togglePotrait();
    });
  }, []);

  const handleClickModal = () => {
    setOpenModal(false);
  }


  if (potrait) {
    return (
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100vw",
            height: "100vh",

          }}
        >
          <Box>
            <Typography sx={{ paddingY: "5px", color: "black", fontSize: "24px", fontWeight: "600", textAlign: "center" }}>
              Please Hold the Tablet in Landscape<br />
              SURYA is not developed for
              use in Potrait
            </Typography>
            <Box
              sx={{
                paddingY: "5px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src="/rotate-phone.gif"
                alt="deviceimage"
                height="200px"
                width="200px"
              />
            </Box>
            <Box sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}>
              <Button variant="contained" sx={{ color: "white", textTransform: "none", padding: "5px 15px", fontSize: "16px" }} onClick={handlePotraitChange}>Ok</Button>
            </Box>

          </Box>
        </Box>
        <Modal
          open={openModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "40%",
              backgroundColor: "#FFFFFF",
              color: "black",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                padding: "15px 20px 0px 0px ",
              }}
            >
              <CloseIcon onClick={handleCloseModal} />
            </Box>
            <Box
              sx={{
                padding: "5px 20px",
              }}
            >
              <Typography sx={{ fontSize: "18px" }}>
                Please Hold the Tablet in Landscape, the App is not developed for
                use in Portrait
              </Typography>
              <Box
                sx={{
                  marginY: "20px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <img
                  src="/rotate-device.svg"
                  alt="deviceimage"
                  height="150"
                  width="300"
                />
              </Box>
            </Box>

            <Box
              sx={{
                padding: "15px",
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Button variant="contained" onClick={handleClickModal}>
                Ok
              </Button>
            </Box>
          </Box>
        </Modal>

      </Box>

    );
  } else {
    if (auth.loggedIn) {
      return (
        <BrowserRouter>
          <Sentry.ErrorBoundary fallback={<ErrorFallBack />}>
            <Routes>
              <Route element={<Root />} path="/" />
              <Route path="/resetpassword" element={<ResetPassword />} />
              <Route element={<Install />} path="/install" />
              <Route path="/home" element={<Home />}>
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="constructionprogress"
                  element={<ConstructionProgress />}
                />
                <Route path="qcdata" element={<QcData />} />
              </Route>
              <Route path="/home/gpslocation/:id" element={<GpsLocation />}>
                <Route index element={<Dashboard />} />
                <Route path="dashboard" element={<Dashboard />} />
                <Route
                  path="constructionprogress"
                  element={<ConstructionProgress />}
                />
                <Route path="qcdata" element={<QcData />} />
              </Route>
              <Route
                path="/home/gpslocation/:id/constructionprogresschecksheet/:trackertypeid/:trackerrowid"
                element={<ConstructionProgressChecksheet />}
              />
              <Route
                path="/home/gpslocation/:id/qcdatachecksheet/:trackertypeid/:trackerrowid"
                element={<QcDataChecksheet />}
              />
              <Route
                path="/home/gpslocation/:id/qcpunchlist"
                element={<QCPunchlist />}
              />
              {/* <Route path="/home/gpslocation/qcpunchlist/mapview"
              element={<QCMapView />} /> */}

              <Route path="/home/qcpunchlist" element={<QCPunchlist />} />
              <Route path="/home/profile" element={<ProfileSettings />}>
                <Route index element={<ProfileDetails />} />
                <Route path="profiledetails" element={<ProfileDetails />} />
              </Route>
              <Route path="*" element={<NotFound />}></Route>
            </Routes>
          </Sentry.ErrorBoundary>
        </BrowserRouter>

      );
    } else {
      return (
        <BrowserRouter>
          <Routes>
            <Route element={<Root />} path="/" />
            <Route path="/resetpassword" element={<ResetPassword />} />
            <Route element={<Install />} path="/install" />
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<NotFound />}></Route>
          </Routes>
        </BrowserRouter>

      );
    }
  }
}

export default App;

import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

const ErrorFallBack = () => {
    const navigate = useNavigate()

    const handleRefresh = () => {
        navigate("/home/dashboard")
        window.location.reload()
    }

    return (
        <Box style={{ height: "100vh", width: "100vw", display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
            <Typography variant="h6">Oops something went wrong, please bear with us.</Typography>
            <Box style={{ margin: "20px" }}>
                <Button onClick={handleRefresh} variant="contained" style={{ textTransform: "none" }}>Go Home</Button>
            </Box>
        </Box>
    )
}

export default ErrorFallBack


